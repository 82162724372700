import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../Styles/startpage.css';
import { isMobile, isTablet } from 'react-device-detect';

function StartPage() {
  const navigate = useNavigate();

  useEffect(() => {
    if (isMobile && !isTablet) {
      alert('모바일 기기에서는 영상 업로드 기능만 제공합니다');
      navigate('/login');
    }
  }, [navigate]);

  return (
    <div className="startPageContainer">
        <p className="start-text">
          <Link to="/login">
            <button type="button">Start Experience</button>
          </Link>
        </p>
        <video autoPlay loop muted>
          <source src="/homevideo.mp4" type="video/mp4"/>
        </video>
    </div>
  );
}

export default StartPage;
