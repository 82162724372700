import React, { useState, useEffect, useRef } from 'react';
//import io from 'socket.io-client';
import { Html5Qrcode } from 'html5-qrcode';
import '../Styles/launchpad.css';
import { useSocket } from '../Components/SocketContext';

const Launchpad = () => {
    const {socket, updateRoomId} = useSocket();
    const [roomId, setRoomId] = useState(null);
    const [showScanner, setShowScanner] = useState(false);
    const qrRef = useRef(null);
    
    // useEffect(() => {
    //     if (roomId !== null) {
    //         const userId = sessionStorage.getItem('loginId');
    //         const password = sessionStorage.getItem('password');
    //         if(socket){
    //             console.log("launchPad 1")
    //             socket.emit("KM:login", { id: userId, pw: password });
    //             socket.on('connect', () => {
    //             console.log('연결됨');
    //             });
    //         }

    //         if(sessionStorage.getItem('logoutflag') === 1){
    //             socket.close();
    //         }
    //         //return () => newSocket.close();
    //     }
    // }, [roomId, socket]);

    useEffect(() => {
        let isMounted = true;

        if (showScanner) {
            const html5QrCode = new Html5Qrcode("qr-reader");
            const config = { fps: 10, qrbox: { width: 300, height: 200 } };
            html5QrCode.start(
                { facingMode: "environment" },
                config,
                (decodedText, decodedResult) => {
                    if (isMounted) {
                        console.log(`Code scanned = ${decodedText}`, decodedResult);
                        setRoomId(decodedText);
                        updateRoomId(decodedText);
                        sessionStorage.setItem('roomId', decodedText);
                        setShowScanner(false);
                    }
                },
                (errorMessage) => {
                    if (isMounted) {
                        console.log(`QR Code no longer in front of camera.`);
                    }
                }
            ).catch((err) => {
                if (isMounted) {
                    console.error('Unable to start QR scanner', err);
                }
            });

            return () => {
                isMounted = false;
                html5QrCode.stop().then(() => {
                    console.log('스캔 완료');
                }).catch((err) => {
                    console.error('Unable to stop QR scanning', err);
                });
            };
        }
    }, [showScanner]);

    useEffect(() => {
        // 소켓 연결이 설정되었는지 확인
        if (socket) {
            const handleConnect = () => {
                console.log('Connected');
            };

            // 연결 이벤트 리스너 등록
            socket.on('connect', handleConnect);

            // 컴포넌트가 언마운트될 때 이벤트 리스너 제거
            return () => {
                socket.off('connect', handleConnect);
            };
        }
    }, [socket]);

    const handleLogin = () => {
        setShowScanner(true);
    };

    const handleRequestUpload = (e, request) => {
        e.preventDefault();
        if (socket) {
            console.log("launchPad 2")
            socket.emit("KM:requestUploadVideo", { request });
        }
    };

    return (
        <div className='container'>
            {showScanner && <div id="qr-reader" style={{ width: '400px', height: '400px' }} ref={qrRef}></div>}
            <button className='button' type="button" onClick={handleLogin}>연동하기</button>
            <button className='button' onClick={(e) => handleRequestUpload(e, true)}>업로드 하기</button>
            <button className='button' onClick={(e) => handleRequestUpload(e, false)}>업로드 안하기</button>
        </div>
    );
};

export default Launchpad;