import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy';
import Modal from 'react-modal';
import { useToken } from '../Components/TokenContext';
import '../Styles/coachregiseration.css'
//import { showAlert } from '../Components/alert';

const CoachRegistration = () => {
    const navigate = useNavigate();
    let i;
    const [coaches, setCoaches] = useState([]); // 전체 코치 리스트
    const [mycoaches, setMyCoaches] = useState([]); // 내 코치(승인된 코치만)
    const [waitingcoach, setWaitingcoach] = useState([]); // 대기 중인 코치
    const [selectedCoachId, setSelectedCoachId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    Modal.setAppElement('#root')
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredCoaches, setFilteredCoaches] = useState([]);
    const { loginId, coachId, setCoachlist, setMycoachlist, setWaitingcoachlist } = useToken();
    const token2 = 'Bearer ' + sessionStorage.getItem('token')
    const take = 50;
    const studentId = sessionStorage.getItem('studentId');

    // 코치 데이터(영상) 받아오는 변수들
    const [thumbnailPath, setThumbnailPath] = useState([]); // 회원영상 썸네일 전체의 경로 저장
    const [generaldata, setGeneraldata] = useState({}); // 여기서 코치영상 모든 데이터 추출 가능 - api짜져야 함
    const [metadata, setMetadata] = useState({}); // 메타데이터 id별로 파싱한 데이터 저장
    const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
    const [thumbnails, setThumbnailUrls] = useState([]); // 마이페이지에 일반영상 썸네일 띄우기 위한 src path
    const [index, setIndex] = useState(null); // 회원영상 인덱스
    const [showPopup, setShowPopup] = useState(false); // 팝업
    const [selectedId, setSelectedId] = useState(null); // 썸네일 클릭 시 해당 썸네일의 studentSwings[i].id
    const [videosize, setVideoSize] = useState({width: '0px', height:'0px'});

    useEffect(() => {
        const handleResize = () => {
          if (window.matchMedia("(min-width: 900px) and (max-width: 1280px) and (orientation: landscape)").matches) {
            setVideoSize({ width: '100px', height: '200px' });
          } else {
            setVideoSize({ width: '200px', height: '300px' });
          }
        };
      
        handleResize();
      
        window.addEventListener('resize', handleResize);
  
        window.addEventListener('orientationchange', handleResize);
      
        return () => {
          window.removeEventListener('resize', handleResize);
          window.removeEventListener('orientationchange', handleResize);
        };
    }, []);

    const [isCoachListVisible, setIsCoachListVisible] = useState(false);
    const toggleCoachList = () => {
        setIsCoachListVisible(!isCoachListVisible);
    };
    const transformSwingsData = (generaldata) => {
        const transformedData = {};
        generaldata.data.studentSwings.forEach((swing) => {
          
          transformedData[swing.id] = {
            clubType: swing.clubType,
            createdAt: swing.createdAt,
            isOpen: swing.isOpen,
            note: swing.note,
          };
        });
        return transformedData;
      };

      const showAlert = (message) => {
        const overlay = document.createElement('div');
        overlay.className = 'custom-alert-overlay';
    
        const alertBox = document.createElement('div');
        alertBox.className = 'custom-alert-box';
    
        const messageParagraph = document.createElement('p');
        messageParagraph.textContent = message;
    
        const closeButton = document.createElement('button');
        closeButton.textContent = '닫기';
        closeButton.onclick = () => {overlay.remove(); reload();};
    
        alertBox.appendChild(messageParagraph);
        alertBox.appendChild(closeButton);
        overlay.appendChild(alertBox);
    
        document.body.appendChild(overlay);
    };


  useEffect(() => {
    const fetchCoaches = async() => { // 전체 코치 리스트 받아오기
        try {
        const response = await fetch(`http://117.16.243.158:9000/instructor/info?take=${take}`, { // 코치 리스트 받아오는 api
        method: 'GET',
        headers: {
            'Authorization': token2,
            'Content-Type': 'application/json',
        },
        });
        if(response.status === 401)
        {
        showAlert('로그인 후 이용해 주세요');
        navigate('/login');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('nickname')
        }
        
        const data = await response.json();

        if (data.data && data.data.instructors) {

        const coachData = data.data.instructors.map((instructor) => ({
            id: instructor.id,
            name: instructor.user.name,
            certificate: instructor.certificate,
            introduce: instructor.introduce
        }));
        sessionStorage.setItem('coachlist', coachData);
        setCoachlist(coachData);
        setCoaches(coachData);
    }
        }catch(error){
        console.log('Error', error);
        }
    };
    fetchCoaches();
    }, []);

useEffect(() => {
    const fetchMyCoaches = async() => { // 등록된 코치 리스트 받아오기 Approve로 파싱
        try {
        const response = await fetch(`http://117.16.243.158:9000/lesson/student/${studentId}`, {
        method: 'GET',
        headers: {
            'Authorization': token2,
            'Content-Type': 'application/json',
        },
        });
        if(response.status === 401)
        {
        //showAlert('로그인 후 이용해 주세요');
        //navigate('/login');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('nickname')
        }
        
        const data = await response.json();

        if (data.data && data.data.lessons) {

            const coachData = data.data.lessons.map((lesson) => ({
                lessonId: lesson.id,
                id: lesson.instructor.id,
                name: lesson.instructor.user.name,
                certificate: lesson.instructor.certificate,
                introduce: lesson.instructor.introduce,
                isApprove: lesson.isApprove
            }));
            sessionStorage.setItem('mycoachlist', coachData);
            setMycoachlist(coachData);
            setMyCoaches(coachData);
            }
        } catch (error) {
            console.log('Error', error);
        }
        };
    fetchMyCoaches();
}, [studentId]);

useEffect(() => {
  const fetchWaitingCoaches = async() => { // 대기 중인 코치목록
      try {
      const response = await fetch(`http://117.16.243.158:9000/lesson/student/${studentId}/pending`, { // 코치 리스트 받아오는 api
      method: 'GET',
      headers: {
          'Authorization': token2,
          'Content-Type': 'application/json',
      },
      });
      if(response.status === 401)
      {
      //showAlert('로그인 후 이용해 주세요');
      //navigate('/login');
      sessionStorage.removeItem('token');
      sessionStorage.removeItem('nickname')
      }
      
      const data = await response.json();

      if (data.data && data.data.lessons) {

          const coachData = data.data.lessons.map((lesson) => ({
              lessonId: lesson.id,
              id: lesson.instructor.id,
              name: lesson.instructor.user.name,
              certificate: lesson.instructor.certificate,
              introduce: lesson.instructor.introduce,
              isApprove: lesson.isApprove,
              requestedBy: lesson.requestedBy
          }));
          sessionStorage.setItem('waitingcoachlist', coachData);
          setWaitingcoachlist(coachData);
          setWaitingcoach(coachData);
          }
      } catch (error) {
          console.log('Error', error);
      }
      };
      fetchWaitingCoaches();
}, [studentId]);

useEffect(() => {
  // 검색 텍스트에 따라 코치를 필터링
  const results = coaches.filter(coach =>
      coach.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  setFilteredCoaches(results);
}, [searchTerm, coaches]);

const reload = () => {
  window.location.reload();
}


    const handleApply = async (id) => { // 강사에게 승인요청 보내기
        try {
            const response = await fetch(`http://117.16.243.158:9000/lesson/student/${studentId}`, {
                method: 'POST',
                headers: {
                    'Authorization': token2,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ 
                    instructorId: id
                }),
            });
            if (response.ok) {
                showAlert('신청이 완료되었습니다.');
            } else {
                showAlert('신청에 실패하였습니다.');
            }
        } catch (error) {
            console.error('Error during coach registration:', error);
        }
    };
    const handleCoachClick = (coachid) => {
        setSelectedCoachId(coachid);
      };

  const approveCoach = async (lessonId) => { // 강사의 요청 승인하기
    try {
        const response = await fetch(`http://117.16.243.158:9000/lesson/${lessonId}/student/${studentId}/approve`, {
            method: 'PUT',
            headers: {
                'Authorization': token2,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                isApprove: true
            }),
        });

        if (response.ok) {
            showAlert('승인되었습니다.');
        } else {
            showAlert('승인에 실패하였습니다.');
        }
    } catch (error) {
        console.error('Error during the API call:', error);
        showAlert('Error approving student');
    }
};

  const rejectCoach = async (lessonId) => { // 강사의 승인요청 거절하기
    try {
        const response = await fetch(`http://117.16.243.158:9000/lesson/${lessonId}/student/${studentId}/approve`, {
            method: 'PUT',
            headers: {
                'Authorization': token2,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                isApprove: false
            }),
        });

        if (response.ok) {
            showAlert('승인이 거절되었습니다.');
        } else {
            showAlert('거절에 실패하였습니다.');
        }
    } catch (error) {
        console.error('Error during the API call:', error);
        showAlert('Error approving student');
    }
};

  const deleteCoach = async (lessonId) => { // 코치 삭제하기
    try {
        const response = await fetch(`http://117.16.243.158:9000/lesson/${lessonId}/student/${studentId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': token2,
                'Content-Type': 'application/json',
            },
        });

        if (response.ok) {
            showAlert('삭제가 완료되었습니다.');
        } else {
            showAlert('삭제에 실패하였습니다.');
        }
    } catch (error) {
        console.error('Error during the API call:', error);
        showAlert('Error approving student');
    }
};

    const handleClose = () => {
        setShowPopup(false);
    };

    // const thumbnailname = (path) => {
    //     const filename = path.substring(path.lastIndexOf('/') + 1);
    //     const dateTimePart = filename.split('.')[0];
    //     return dateTimePart;
    // };

    // const handleThumbnailClick = (index) => {
    //     setIndex(index);
    //     const videoUrl = generaldata.data.studentSwings[index].videoPath;
    //     setSelectedVideoUrl(videoUrl);
    //     setSelectedId(generaldata.data.studentSwings[index].id);
    //     setShowPopup(true);
    // };

    // const renderPopup = () => {

    //     if (!showPopup) return null;
    //     return (
    //       <div className="popup">
    //         <h3>Swing Info</h3>
    //         {selectedVideoUrl && (
    //           <div style={{ float: 'right', marginRight: '10px',marginTop:'-80px' }}>
    //             <ReactPlayer url={selectedVideoUrl} loop={true} playing controls width={videosize.width} height={videosize.height} />
    //           </div>
    //         )}
    //         {metadata && (
    //           <div>
    //             <p><strong>클럽 종류: </strong> {metadata[selectedId].clubType}</p>
    //             <p><strong>업로드 시간: </strong> {metadata[selectedId].createdAt}</p>
    //             <p><strong>hand: </strong> {'우타'}</p>
    //             <p><strong>코멘트: </strong> {metadata[selectedId].note}</p>

    //           </div>
    //         )}
    //         {/* <button onClick={() => handlePersonalAnalysis(index)}>개인 분석</button>
    //         <button onClick={() => handleComparativeAnalysis(index)}>비교 분석</button> */}
    //         <button onClick={handleClose}>닫기</button>
    //       </div>
    //     );
    //   };

    return (
    <>
        <div>
            <div className='title'>
                <p>내 코치</p>
            </div>
            <ul className="coach-list">
                {mycoaches.length > 0 ? (
                    mycoaches.filter(coach => coach.isApprove === true).map((filteredCoach) => (
                        <li key={filteredCoach.id} className="coach-item" onClick={() => handleCoachClick(filteredCoach.id)}>
                            <h3>{filteredCoach.name}</h3>
                            <p>Certificate: {filteredCoach.certificate}</p>
                            <p>Introduce: {filteredCoach.introduce}</p>
                            <button onClick={() => deleteCoach(filteredCoach.lessonId)}>삭제</button>
                        </li>
                    ))
                ) : (
                    <li className="member-item no-members">등록된 코치가 없습니다.</li>
                )}
            </ul>
            <>
        {/* <div className="Mylabel">
            {
              mycoaches.length > 0 && selectedCoachId &&
              <label>
                {mycoaches.find(s => s.id === selectedCoachId).name} 코치님의 영상은 {thumbnailPath.length}개 입니다.
              </label>
            }
              <div className="Mythumbnail-container">
                {thumbnailPath && thumbnailPath.map((item, index) => (
                  <div className="Mythumbnail-item" key={index}>
                    <img onClick={() => handleThumbnailClick(index, generaldata.data.studentSwings[index].id)} src={thumbnails[index]} alt={''} style={{ width: '200px', height: '400px' }}/>
                    <div className="Mythumbnail-title">
                      {thumbnailname(thumbnailPath[index].videoname)}
                    </div>
                  </div>
                ))}
              </div>
            {renderPopup()}
        </div> */}
      </> 
            <div className='title'>
              <p>신청 중인 코치</p>
            </div>
            <ul className="coach-list">
              {
                waitingcoach.some(s => s.isApprove === null && s.requestedBy === true) ? (
                  waitingcoach.filter(s => s.isApprove === null && s.requestedBy === true).map((filteredCoach) => (
                    <li key={filteredCoach.id} className="coach-item" onClick={() => handleCoachClick(filteredCoach.id)}>
                      <p sy>{filteredCoach.name}</p>
                    </li>
                  ))
                ) : (
                  <li className="member-item no-members">신청 중인 코치가 없습니다.</li>
                )
              }
            </ul>
            
            <div className='title'>
              <p>대기 중인 코치</p>
            </div>
            <ul className="coach-list">
              {
                waitingcoach.some(s => s.isApprove === null && s.requestedBy === false) ? (
                  waitingcoach.filter(s => s.isApprove === null && s.requestedBy === false).map((filteredCoach) => (
                    <li key={filteredCoach.id} className="coach-item" onClick={() => handleCoachClick(filteredCoach.id)}>
                      <p>{filteredCoach.name}</p>
                      <button onClick={() => approveCoach(filteredCoach.lessonId)}>승인</button>
                      <button onClick={() => rejectCoach(filteredCoach.lessonId)}>거절</button>
                    </li>
                  ))
                ) : (
                  <li className="member-item no-members">승인대기 중인 코치가 없습니다.</li>
                )
              }
            </ul>
            <div className='title'>
            <button onClick={() => setIsModalOpen(true)}>코치 검색</button><br></br>
            {/* <button onClick={toggleCoachList} >{isCoachListVisible ? '닫기' : '코치 리스트 보기'}</button> <br></br> */}
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="코치 리스트"
                style={{
                  overlay: {
                    zIndex: 10, // Set a high value for zIndex here
                  },
                    content: {
                        top: '45%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        height: '80%',
                    }
                }}
            >
                <h2>코치 리스트</h2>
                <button onClick={() => setIsModalOpen(false)}>닫기</button>
                <input
                  type="text"
                  placeholder="코치 이름 검색"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="search-coach-input"
                />
                <ul className="coach-list2">
                  {searchTerm && filteredCoaches.map((coach) => (
                      <li key={coach.id} className="coach-item">
                          <h3>{coach.name}</h3>
                          <p>Certificate: {coach.certificate}</p>
                          <p>Introduce: {coach.introduce}</p>
                          <button onClick={() => handleApply(coach.id)}>신청</button>
                      </li>
                  ))}
              </ul>
            </Modal>
            {/* {isCoachListVisible && (
                <ul className="coach-list">
                    {coaches.map((coach) => (
                        <li key={coach.id} className="coach-item">
                            <h3>{coach.name}</h3>
                            <p>Certificate: {coach.certificate}</p>
                            <p>Introduce: {coach.introduce}</p>
                            <button onClick = {() => handleApply(coach.id)}>신청</button>
                        </li>
                    ))}
                </ul>
            )} */}
            
        </div>
    </>
    );
};

export default CoachRegistration;