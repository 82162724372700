import React, { useState, useEffect } from 'react';
import { useToken } from '../Components/TokenContext';
import ReactPlayer from 'react-player/lazy';
import Modal from 'react-modal';
import AWS from 'aws-sdk';
import { isMobile, isTablet } from 'react-device-detect';

const Public = () => {

  let i;

  const [thumbnailPath, setThumbnailPath] = useState([]); // 공개 영상 썸네일 전체의 경로 저장
  const [index, setIndex] = useState(null); // 공개 영상 인덱스
  const [showPopup, setShowPopup] = useState(false); // 팝업
  const [editednote, setEditedNote] = useState(''); // 공개 영상 썸네일 클릭 후 수정하여 저장한 코멘트
  const [isOpened, setIsOpened] = useState(true); // 공개 여부
  const [editedisOpened, setEditedIsOpened] = useState(isOpened); // 저장 전 공개 여부 저장해야 바뀜
  const [thumbnails, setThumbnailUrls] = useState([]); // 마이페이지에 공개 영상 썸네일 띄우기 위한 src path
  const [publicdata, setPublicdata] = useState({}); // 여기서 공개 영상 모든 데이터 추출 가능
  const [metadata, setMetadata] = useState({}); // 메타데이터 id별로 파싱한 데이터 저장
  const [selectedId, setSelectedId] = useState(null); // 썸네일 클릭 시 해당 썸네일의 myswings[i].id
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [videosize, setVideoSize] = useState({width: '0px', height:'0px'});
  const [flag, setFlag] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  Modal.setAppElement('#root')
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredThumbnails, setFilteredThumbnails] = useState([]);
  const [isBookmarked, setIsBookmarked] = useState(false); // 즐찾 여부
  const [editedIsBookmarked, setEditedIsBookmarked] = useState(isBookmarked);
  const [cursor, setCursor] = useState([0]);
  const [total, setTotal] = useState(-1);

  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 번호 (1부터 시작)
  var itemsPerPage = 6;
  if(window.matchMedia("(min-width: 1180px) and (max-width: 1400px) and (orientation: landscape)").matches){
    itemsPerPage = 4;
  }

  const token2 = 'Bearer ' + sessionStorage.getItem('token')
  const take = isTablet ? 4 : 6;
  const apiId = sessionStorage.getItem('apiId');
  const apitype = sessionStorage.getItem('apitype');

  const transformSwingsData = (publicdata) => { // 전체
    var name;
    const transformedData = {};
  
    publicdata.data.myswings.forEach((swing) => {
      // Map each swing's data to its id
      if(swing.instructor){
        name = swing.instructor.user.name
      }
      else if(swing.student){
        name = swing.student.user.name
      }
      transformedData[swing.id] = {
        clubType: swing.clubType,
        createdAt: swing.createdAt,
        isOpen: swing.isOpen,
        note: swing.note,
        name: name
      };
    });
    return transformedData;
  };

  const currentThumbnails = thumbnailPath;

  const reload = () => {
    setFlag(flag+1);
    window.location.reload();
  }

  const handleSave = () => {
    reload();
    setIsBookmarked(editedIsBookmarked);
    setEditedIsBookmarked(false);
    if(editedIsBookmarked === true){
        setBookmark();
        setShowPopup(false);
    }
    else{
        setIsBookmarked(false);
        setEditedIsBookmarked(false);
        setShowPopup(false);
    }
  };


useEffect(() => { // 공개 영상 데이터 받기
  const fetchData = async() => {
    try {
    const response = await fetch(`http://117.16.243.158:9000/myswing/open/${sessionStorage.getItem('apitype')}/${sessionStorage.getItem('apiId')}?take=${take}&cursor=${cursor[currentPage-1]}`,{
      method: 'GET',
      headers: {
        'Authorization': token2,
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();
    var thumbnailpath = [];
    sessionStorage.setItem('publicdata', JSON.stringify(json));

    for(i = 0; i< json.data.myswings.length; i++)
    {
      var thumbnail = {path : json.data.myswings[i].thumbnail, videoname: json.data.myswings[i].videoPath}
      thumbnailpath.push(thumbnail)
    }
    setThumbnailPath(thumbnailpath)
    setPublicdata(json)
    const transformedData = transformSwingsData(json);
    setMetadata(transformedData);
    cursor[currentPage] = json.data.cursor
    setCursor([...cursor]);
    if(total === -1){
      setTotal(json.data.total);
    }
    //setModaldata(transformedData.reverse());

    }catch(error){
      console.log('Error', error);
    }
  };
  fetchData();

},[index, apitype, apiId, flag, currentPage]);

useEffect(() => { // 공개 영상 썸네일받기

  AWS.config.update({
    accessKeyId: 'AKIAVVKFCTNJJFZR6ABQ',
    secretAccessKey: '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',
    region: 'ap-northeast-2'
  });

  const s3 = new AWS.S3();
  const bucketName = 'dmz-metadata';
  const objectKey = selectedVideoUrl;
  
  const params = {
    Bucket: bucketName,
    Key: objectKey
  };

s3.getObject(params, (error, data) => {
    if (error) {
      console.error("Error downloading video from S3:", error);
    } else {
      // Assuming the data is a Blob
      const blob = new Blob([data.Body], { type: 'video/mp4' });
      const videoUrl = URL.createObjectURL(blob);
      setSelectedVideoUrl(videoUrl);
    }
  });

  const fetchThumbnails = async () => {
    try {
      const thumbnailPromises = thumbnailPath.map((item) => {
        const params = {
          Bucket: bucketName,
          Key: item.path,
        };
        return s3.getObject(params).promise().then(data => {
          return URL.createObjectURL(new Blob([data.Body], { type: 'image/jpeg' }));
        });
      });

      const thumbnails = await Promise.all(thumbnailPromises);
      setThumbnailUrls(thumbnails);
    } catch (error) {
      console.error('Error fetching thumbnails:', error);
    }
  };

  if (thumbnailPath.length > 0) {
    fetchThumbnails();
  }
}, [thumbnailPath, selectedVideoUrl, cursor]);

  const handlePrev = () => {
    if(currentPage > 1){ setCurrentPage(currentPage - 1); // 0 미만으로 가지 않도록
  }
    //fetchData();
  };

  const handleNext = () => {
    console.log('dd')
    if(currentPage <= total/6){
      setCurrentPage(currentPage + 1); // 최대 페이지를 초과하지 않도록
    }
    //fetchData();
  };

// useEffect(() => {
//   // publicdata, metadata가 존재하는지 확인합니다.
//   if (publicdata && publicdata.data && publicdata.data.myswings && metadata) {
//     // 검색된 텍스트와 일치하는 공개 영상 데이터를 필터링합니다.
//     const filteredData = publicdata.data.myswings.filter(swing =>
//       metadata[swing.id] && metadata[swing.id].name.toLowerCase().includes(searchTerm.toLowerCase())
//     );

//     // 필터링된 데이터를 바탕으로 썸네일 배열을 생성합니다.
//     const newFilteredThumbnails = filteredData.map(swing => {
//       // 필터링된 데이터의 썸네일 경로와 일치하는 thumbnailPath 인덱스를 찾습니다.
//       const index = thumbnailPath.findIndex(thumb => thumb.path === swing.thumbnail);
//       return index !== -1 ? {
//         ...swing, // swing의 모든 데이터를 포함합니다.
//         thumbnail: thumbnails[index] // 해당 인덱스의 썸네일 URL을 추가합니다.
//       } : null;
//     }).filter(thumb => thumb !== null); // null 값을 제거합니다.

//     // 필터링된 썸네일 목록을 상태에 설정합니다.
//     setFilteredThumbnails(newFilteredThumbnails);
//   }
// }, [searchTerm, metadata, publicdata, thumbnails, thumbnailPath]);

useEffect(() => {
  if (publicdata && publicdata.data && publicdata.data.myswings && metadata) {
    const filteredData = publicdata.data.myswings.filter(swing => {
      const swingName = metadata[swing.id] ? metadata[swing.id].name.toLowerCase() : '';
      const swingDate = swing.createdAt ? swing.createdAt.split('T')[0] : ''; // 'T'를 기준으로 문자열을 나누고 날짜 부분만 가져옵니다.
      
      return swingName.includes(searchTerm.toLowerCase()) || swingDate.includes(searchTerm);
    });

    const newFilteredThumbnails = filteredData.map(swing => {
      const index = thumbnailPath.findIndex(thumb => thumb.path === swing.thumbnail);
      return index !== -1 ? {
        ...swing,
        thumbnail: thumbnails[index]
      } : null;
    }).filter(thumb => thumb !== null);

    setFilteredThumbnails(newFilteredThumbnails);
  }
}, [searchTerm, metadata, publicdata, thumbnails, thumbnailPath]);


  const thumbnailname = (path) => {
    const parts = path.split('/');
    const filename = parts[parts.length - 1]; // (예: "2023-09-20_11h57m47s.jpg")
    const dateTimePart = filename.split('.')[0]; // (예: "2023-09-20_11h57m47s")
    return dateTimePart; // "2023-09-20_11h57m47s"
  };
    const handleThumbnailClick = (index) => {
    setIndex(index);
    const videoUrl = publicdata.data.myswings[index].videoPath;
    setSelectedVideoUrl(videoUrl);
    setSelectedId(publicdata.data.myswings[index].id);
    setShowPopup(true);
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(min-width: 900px) and (max-width: 1280px) and (orientation: landscape)").matches) {
        setVideoSize({ width: '100px', height: '200px' });
      } else {
        setVideoSize({ width: '200px', height: '300px' });
      }
    };
  
    // Call once to set initial size
    handleResize();
  
    // Set up event listeners
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
  
    // Clean up
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);
  const renderPopup = () => {
      if (!showPopup) return null;
      return (
        <div className="popup">
          <h3>Swing Info</h3>
          {selectedVideoUrl && (
              <div style={{ float: 'right', marginRight: '10px',marginTop:'-80px' }}>
                <ReactPlayer url={selectedVideoUrl} loop={true} playing controls width={videosize.width} height={videosize.height} />
              </div>
            )}
          {metadata && (
            <div>
              <p><strong>클럽 종류: </strong> {metadata[selectedId].clubType}</p>
              <p><strong>업로드 시간: </strong> {metadata[selectedId].createdAt}</p>
              <p><strong>hand: </strong> {metadata.hand}</p>
              <p><strong>이름: </strong> {metadata[selectedId].name}</p>
              <p><strong>코멘트: </strong> {metadata[selectedId].note} </p>
              {/* <input type="text" value={editednote} onChange={(e) => setEditedNote(e.target.value)}/> */}
                <div className="bookmark-container">
                  <p className="bookmark-label">
                      <strong>즐겨찾기:</strong><input type="checkbox" checked={editedIsBookmarked} onChange={(e) => setEditedIsBookmarked(e.target.checked)}/>
                  </p>
                  <button onClick={handleSave}>적용</button>
                </div>
            </div>
          )}
          <button onClick={handleClose}>닫기</button>
        </div>
      );
    };

    const handleClose = () => {
      setShowPopup(false);
    };

    const setBookmark = async() =>{
      try { // 북마크 보내기
        const response = await fetch(`http://117.16.243.158:9000/bookmark/${apitype}/${apiId}`, {
          method: 'POST',
          headers: {
            'Authorization': token2,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            myswingId : selectedId
          })
        });
    
        if (!response.ok) {
          console.log(response)
          throw new Error('Network response was not ok');
        }
        // 요청 성공 처리
        console.log('Metadata updated successfully');
      } catch (error) {
        console.error('Error while posting metadata:', error);
      }
    }

  return (
    <div className="bookmark-label">
      <label>공개된 영상 [{total}개]</label>
        <div className="bookmark-container">
          {currentThumbnails && currentThumbnails.map((item, index) => (
            <div className="bookmarkthumbnail-item" key={index} >
              <img onClick={() => handleThumbnailClick(index)} src={thumbnails[index]} alt={''} style={{ width: '200px', height: '400px' }}/>
              <div className="Mythumbnail-title">
                {thumbnailname(thumbnailPath[index].videoname)}
              </div>
            </div>
          ))}
        </div>
        <div className="pagination-controls1">
          <br></br>
          {/* <span onClick={handlePrev} disabled={currentPage === 0}>&lt; 이전</span>
          <span> {currentPage + 1} / {Math.ceil(thumbnailPath.length / itemsPerPage)}</span>
          <span onClick={handleNext} disabled={currentPage >= Math.ceil(thumbnailPath.length / itemsPerPage) - 1}>다음 &gt;</span> */}
          <button onClick={() => setIsModalOpen(true)}>더 보기</button>
        <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                style={{
                  overlay: {
                    zIndex: 10, // Set a high value for zIndex here
                  },
                    content: {
                        top: '45%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        height: '80%',
                    }
                }}
            >
              <button onClick={() => setIsModalOpen(false)}>닫기</button>
                <input
                  type="text"
                  placeholder="검색 (이름) or (YYYY-MM-DD)"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="search-input"
                /><br></br>
               <label> 공개 영상 </label>
       
            <div className="thumbnail-container">
              {filteredThumbnails && filteredThumbnails.map((item, index) => (
                <div className="thumbnail-item" key={index}>
                  <img 
                    src={filteredThumbnails[index].thumbnail} // 썸네일 URL을 filteredThumbnails에서 가져옵니다.
                    alt={''} 
                    style={{ width: '200px', height: '400px' }}
                    onClick={() => handleThumbnailClick(publicdata.data.myswings.findIndex(swing => swing.id === item.id))}
                  />
                  <div className="thumbnail-title">
                    {metadata[filteredThumbnails[index].id].name}/{item.createdAt.split('T')[0]}
                  </div>
                </div>
              ))} 
            </div>
        </Modal>
      </div>
        {renderPopup()}
    </div>
  );
  };
  
  export default Public;