import React, { useEffect, useRef } from "react";

const Canvas2 = React.memo(({ coordinates1, coordinates2, frameRate1, frameRate2, onCurrentFrameChange1, onCurrentFrameChange2, selectedGraph, pathChecked }) => {

  const canvasRef1 = useRef(null);
  const playerRef1 = useRef(null);

  const canvasRef2 = useRef(null);
  const playerRef2 = useRef(null);

  useEffect(() => {
    const canvas1 = canvasRef1.current;
    //const context1 = canvas1.getContext("2d");

    const canvas2 = canvasRef2.current;
    //const context2 = canvas2.getContext("2d");

    const updateCanvas1 = (currentTime1) => {
      let currentFrame1 = Math.floor(currentTime1 * frameRate1);
      onCurrentFrameChange1(currentFrame1); // 새로운 currentFrame을 부모 컴포넌트로 전달

      // if (pathChecked) {
      //   drawPath1(context1, currentTime1);
      // } else {
      //   drawRedDots1(context1, currentTime1);
      // }
    };

    const updateCanvas2 = (currentTime2) => {
      let currentFrame2 = Math.floor(currentTime2 * frameRate2);
      onCurrentFrameChange2(currentFrame2); // 새로운 currentFrame을 부모 컴포넌트로 전달

    // if (pathChecked) {
    //     drawPath2(context2, currentTime2);
    //  } else {
    //     drawRedDots2(context2, currentTime2);
    //  }
    };

    const drawPath1 = (ctx1, currentTime1) => {
        const player1 = playerRef1.current;
        if (!player1 || isNaN(currentTime1) || !isFinite(currentTime1)) {
          return;
        }
      
        const {
          x: videoX1,
          y: videoY1,
          width: videoWidth1,
          height: videoHeight1,
        } = player1.getBoundingClientRect();
      
        const { width: canvasWidth1, height: canvasHeight1 } =
          canvas1.getBoundingClientRect();
        const scaleX1 = canvasWidth1 / videoWidth1;
        const scaleY1 = canvasHeight1 / videoHeight1;
      
        ctx1.clearRect(0, 0, canvas1.width, canvas1.height);
        let paths1 = {};
      
        for (let i = 0; i <= currentTime1 * frameRate1; i++) {
          const selectedCoordinates1 = coordinates1.find((point) => point.frame === i);
          if (selectedCoordinates1) {
            selectedGraph.forEach((graphName) => {
              const xy1 = graphName.split(".")[0];
              const dx1 = selectedCoordinates1.data[xy1].x;
              const dy1 = selectedCoordinates1.data[xy1].y;
  
              const canvasX1 = dx1 * 540 * scaleX1 + videoX1;
              const canvasY1 = dy1 * 720 * scaleY1 + videoY1;
  
              if (!paths1[graphName]) {
                paths1[graphName] = []; 
              }
              paths1[graphName].push({ x: canvasX1, y: canvasY1 });
            });
          }
        }
  
        selectedGraph.forEach((graphName) => {
          ctx1.beginPath();
          const pathCoordinates1 = paths1[graphName];
          if (pathCoordinates1 && pathCoordinates1.length > 0) {
            
            ctx1.moveTo(pathCoordinates1[0].x, pathCoordinates1[0].y);
            for (let i = 1; i < pathCoordinates1.length; i++) {
              ctx1.lineTo(pathCoordinates1[i].x, pathCoordinates1[i].y);
            }
            if (graphName.includes('left')) {
              ctx1.strokeStyle = "red";
            } else if (graphName.includes('right')) {
              ctx1.strokeStyle = "yellow";
            }
            ctx1.lineWidth = 3;
            ctx1.stroke();
          }
        });
        ctx1.closePath();
      };
// ------------------------------------------------------------------------------------
      const drawPath2 = (ctx2, currentTime2) => {
        const player2 = playerRef2.current;
        if (!player2 || isNaN(currentTime2) || !isFinite(currentTime2)) {
          return;
        }
      
        const {
          x: videoX2,
          y: videoY2,
          width: videoWidth2,
          height: videoHeight2,
        } = player2.getBoundingClientRect();
      
        const { width: canvasWidth2, height: canvasHeight2 } =
          canvas2.getBoundingClientRect();
        const scaleX2 = canvasWidth2 / videoWidth2;
        const scaleY2 = canvasHeight2 / videoHeight2;
      
        ctx2.clearRect(0, 0, canvas2.width, canvas2.height);
        let paths2 = {};
      
        for (let i = 0; i <= currentTime2 * frameRate2; i++) {
          const selectedCoordinates2 = coordinates2.find((point) => point.frame === i);
  
          if (selectedCoordinates2) {
            selectedGraph.forEach((graphName) => {
              const xy2 = graphName.split(".")[0];
              const dx2 = selectedCoordinates2.data[xy2].x;
              const dy2 = selectedCoordinates2.data[xy2].y;
  
              const canvasX2 = dx2 * 540 * scaleX2 + videoX2;
              const canvasY2 = dy2 * 720 * scaleY2 + videoY2;
  
              if (!paths2[graphName]) {
                paths2[graphName] = []; 
              }
              paths2[graphName].push({ x: canvasX2, y: canvasY2 });
            });
          }
        }
  
        selectedGraph.forEach((graphName) => {
          ctx2.beginPath();
          const pathCoordinates2 = paths2[graphName];
          if (pathCoordinates2 && pathCoordinates2.length > 0) {
            
            ctx2.moveTo(pathCoordinates2[0].x, pathCoordinates2[0].y);
            for (let i = 1; i < pathCoordinates2.length; i++) {
              ctx2.lineTo(pathCoordinates2[i].x, pathCoordinates2[i].y);
            }
            if (graphName.includes('left')) {
              ctx2.strokeStyle = "red";
            } else if (graphName.includes('right')) {
              ctx2.strokeStyle = "yellow";
            }
            ctx2.lineWidth = 3;
            ctx2.stroke();
          }
        });
        ctx2.closePath();
      };

    
      const drawRedDots1 = (ctx1, currentTime1) => {
        const player1 = playerRef1.current;
        if (!player1 || isNaN(currentTime1) || !isFinite(currentTime1)) {
          return;
        }
      
        let currentFrame1 = Math.floor(currentTime1 * frameRate1);
          
          ctx1.clearRect(0, 0, canvas1.width, canvas1.height);
  
          const {
            x: videoX1,
            y: videoY1,
            width: videoWidth1,
            height: videoHeight1,
          } = player1.getBoundingClientRect();
  
          const { width: canvasWidth1, height: canvasHeight1 } =
            canvas1.getBoundingClientRect();
          const scaleX1 = canvasWidth1 / videoWidth1;
          const scaleY1 = canvasHeight1 / videoHeight1;
  
  
          coordinates1.forEach((point) => {
            const { frame, data } = point;
            
            selectedGraph.forEach((graphName)=>{
              var xy1 = graphName.split('.')[0]
              var dx1 = data[xy1].x
              var dy1 = data[xy1].y
              if (currentFrame1 === frame) {
                  if(graphName.includes('left')){
                  const radius = 5;
                  const canvasX1 = dx1 * 540 * scaleX1 + videoX1;
                  const canvasY1 = dy1 * 720 * scaleY1 + videoY1;
                  ctx1.beginPath();
                  ctx1.arc(canvasX1, canvasY1, radius, 0, 2 * Math.PI);
                  ctx1.fillStyle = "red";
                  ctx1.fill();
                  }else if(graphName.includes('right')){
                  const radius = 5;
                  const canvasX1 = dx1 * 540 * scaleX1 + videoX1;
                  const canvasY1 = dy1 * 720 * scaleY1 + videoY1;
                  ctx1.beginPath();
                  ctx1.arc(canvasX1, canvasY1, radius, 0, 2 * Math.PI);
                  ctx1.fillStyle = "yellow";
                  ctx1.fill();
                  }
              }
            });
          });
      };
//--------------------------------------------------------------------------------------
      const drawRedDots2 = (ctx2, currentTime2) => {
        const player2 = playerRef2.current;
        if (!player2 || isNaN(currentTime2) || !isFinite(currentTime2)) {
          return;
        }
      
        let currentFrame2 = Math.floor(currentTime2 * frameRate2);
          
          ctx2.clearRect(0, 0, canvas2.width, canvas2.height);
  
          const {
            x: videoX2,
            y: videoY2,
            width: videoWidth2,
            height: videoHeight2,
          } = player2.getBoundingClientRect();
  
          const { width: canvasWidth2, height: canvasHeight2 } =
            canvas2.getBoundingClientRect();
          const scaleX2 = canvasWidth2 / videoWidth2;
          const scaleY2 = canvasHeight2 / videoHeight2;
  
          coordinates2.forEach((point) => {
            const { frame, data } = point;
            
            selectedGraph.forEach((graphName)=>{
              var xy2 = graphName.split('.')[0]
              var dx2 = data[xy2].x
              var dy2 = data[xy2].y
              if (currentFrame2 === frame) {
                  if(graphName.includes('left')){
                  const radius = 5;
                  const canvasX2 = dx2 * 540 * scaleX2 + videoX2;
                  const canvasY2 = dy2 * 720 * scaleY2 + videoY2;
                  ctx2.beginPath();
                  ctx2.arc(canvasX2, canvasY2, radius, 0, 2 * Math.PI);
                  ctx2.fillStyle = "red";
                  ctx2.fill();
                  }else if(graphName.includes('right')){
                  const radius = 5;
                  const canvasX2 = dx2 * 540 * scaleX2 + videoX2;
                  const canvasY2 = dy2 * 720 * scaleY2 + videoY2;
                  ctx2.beginPath();
                  ctx2.arc(canvasX2, canvasY2, radius, 0, 2 * Math.PI);
                  ctx2.fillStyle = "yellow";
                  ctx2.fill();
                  }
              }
            });
          });
      };


    const player1 = document.querySelector(".react-player1 video");
    playerRef1.current = player1;

    const player2 = document.querySelector(".react-player2 video");
    playerRef2.current = player2;

    if (player1) {
      player1.addEventListener("timeupdate", (event) => {
        var currentTime1 = event.target.currentTime;
        if (isNaN(currentTime1) || !isFinite(currentTime1)) {
          return;
        }
        updateCanvas1(currentTime1);
      });
    }
    
    if (player2) {
        player2.addEventListener("timeupdate", (event) => {
          var currentTime2 = event.target.currentTime;
          if (isNaN(currentTime2) || !isFinite(currentTime2)) {
            return;
          }
          updateCanvas2(currentTime2);
        });
      }


    const animateCanvas1 = (timestamp1) => {
      if (timestamp1 - previousFrameTime1 >= 1000 / frameRate1) {
        const player1 = playerRef1.current;
        if (player1 && player1.currentTime) {
          const currentTime1 = player1.currentTime;
          updateCanvas1(currentTime1);
        }
        previousFrameTime1 = timestamp1;
      }
      requestId1 = requestAnimationFrame(animateCanvas1);
    };

    const animateCanvas2 = (timestamp2) => {
        if (timestamp2 - previousFrameTime2 >= 1000 / frameRate2) {
          const player2 = playerRef2.current;
          if (player2 && player2.currentTime) {
            const currentTime2 = player2.currentTime;
            updateCanvas2(currentTime2);
          }
          previousFrameTime2 = timestamp2;
        }
        requestId2 = requestAnimationFrame(animateCanvas2);
      };

    let requestId1;
    let previousFrameTime1 = 0;

    let requestId2;
    let previousFrameTime2 = 0;

    requestId1 = requestAnimationFrame(animateCanvas1);
    requestId2 = requestAnimationFrame(animateCanvas2);
   
    return () => {
      if (player1) {
        player1.removeEventListener("timeupdate", updateCanvas1);
      }
      cancelAnimationFrame(requestId1);

      if (player2) {
        player2.removeEventListener("timeupdate", updateCanvas2);
      }
      cancelAnimationFrame(requestId2);
    };
  }, [coordinates1, coordinates2, frameRate1, frameRate2, onCurrentFrameChange1, onCurrentFrameChange2, selectedGraph, pathChecked]);
 
  return (
    <div style={{ position: "fixed", top: 0, left: 0, pointerEvents: "none" }}>
      <canvas
        ref={canvasRef1}
        width="540"
        height="720"
        style={{ display: "block", position: "absolute" }}
      />

      <canvas
        ref={canvasRef2}
        width="540"
        height="720"
        style={{ display: "block", position: "absolute" }}
      />
    </div>
  );
});

export default React.memo(Canvas2);