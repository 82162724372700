import React from 'react';
import '../Styles/service-terms.css'; // Import the CSS file for styling

const ServiceTerms = () => {
  return (
    <div className="service-terms-container">
      <h1>서비스 이용약관</h1>
      
      <h2>제 1 조 (목적)</h2>
      <p>이 약관은 템포디올 주식회사(이하 “회사”)가 운영하는 KEEPMOTION 서비스를 이용하고자 하는 자와 회사 간의 권리·의무, 서비스 이용절차 및 기타 제반사항을 정함에 그 목적이 있습니다.</p>
      
      <h2>제 2 조 (용어의 정의)</h2>
      <p>이 약관에서 사용하는 용어의 정의는 아래와 같습니다.</p>
      <ul>
        <li>“KEEPMOTION 서비스(이하 “본 서비스”라 한다)”라 함은 회사가 정한 절차에 따라 회원으로 가입한 대상에게 회사가 제공하는 각종 서비스 및 관련 부가서비스 일체를 말합니다.</li>
        <li>“일반회원”이라 함은 레슨 및 스윙 분석을 목적으로 본 서비스를 이용하고자 이 약관에 동의하고 회원으로 가입한 자를 말합니다.</li>
        <li>“코치회원”이라 함은 레슨자를 관리하는 목적으로 본 서비스를 이용하고자 이 약관에 동의하고 회원으로 가입한 자를 말합니다.</li>
        <li>“회원”이라 함은 일반회원과 코치회원 모두 통칭한 대상을 말합니다.</li>
        <li>“홈페이지”라 함은 본 서비스를 제공하는 사이트(http://km.tempodiall.com/)를 말합니다.</li>
        <li>전항에서 정의되지 아니한 용어의 정의는 관련 법령 및 상관례에 따릅니다.</li>
      </ul>
      
      <h2>제 3 조 (약관의 효력 및 변경)</h2>
      <p>회사는 이 약관의 내용을 회원이 쉽게 알 수 있도록 홈페이지 초기화면에 게시합니다.</p>
      <p>사는 약관의규제에관한법률, 정보통신망이용촉진및정보보호등에관한법률 등 관련법을 위배하지 아니하는 범위에서 이 약관을 개정할 수 있습니다.</p>
      <p>회사가 이 약관을 개정하는 경우에는 개정사유 및 적용일자를 명시하여 그 적용일자로부터 7일 이전부터 초기화면 또는 초기화면과의 연결화면을 통해 공지합니다. 다만 소비자에게 불리하게 계약내용을 변경하는 경우에는 30일의 유예기간을 두고 공지합니다.</p>
      <p>회사가 전항에 따라 회원에게 공지하면서 적용일 이내 회원에게 의사표시를 하지 않으면 승인한 것으로 본다는 뜻을 명확하게 고지하였음에도 의사표시가 없는 경우에는 변경된 약관을 승인한 것으로 봅니다. 회원이 개정약관에 동의하지 않을 경우 회원은 이용계약을 해지할 수 있습니다.</p>
      <p>회사는 각 서비스에 따른 개별약관 및 운영정책을 정할 수 있으며 회원은 이를 준수하여야 합니다.</p>
      <p>이 약관에서 정하지 아니한 사항에 대해서는 관계법령 및 상관례에 따라 적용합니다.</p>
      
      <h2>제 4 조 (회원가입 등)</h2>
      <p>회원 가입을 희망하는 자는 회사가 정한 가입양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로써 회원가입을 신청합니다.</p>
      <p>회사는 제1항과 같이 회원으로 가입할 것을 신청한 자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다. 만약 회사가 회원가입신청의 승인을 거부하거나 유보하는 경우 회사는 이를 제6조에 따라 회원에게 통지합니다.</p>
      <ul>
        <li>가입신청자가 이 약관에 의하여 이전에 회원자격을 상실한 적이 있는 경우</li>
        <li>회원의 요청에 의하여 탈퇴한 때로부터 1개월이 지나지 아니한 경우</li>
        <li>가입신청자가 기입한 회원정보에 명백한 허위 기재, 누락 또는 오기가 있는 경우</li>
        <li>회사가 본인확인 절차를 실시할 경우에 본인 확인이 되지 않거나 본인이 아님이 확인된 경우</li>
        <li>기타 가입신청자를 회원으로 등록하는 것이 기술상 불가능하거나 서비스 운영 또는 유지·관리에 현저한 지장을 준다고 판단되는 경우</li>
      </ul>
      <p>회원가입은 회원의 회원가입 신청을 회사가 승낙함으로써 성립합니다.</p>
      <p>회원은 회원정보에 변경이 있는 경우 회원정보 수정 등의 방법으로 그 변경사항을 반영하여야 합니다. 회원정보 수정을 하지 않음으로써 발생하는 회원의 손해에 대하여 회사는 아무런 책임을 지지 않습니다.</p>
      <p>회사는 서비스의 안전한 제공을 위하여 결제 관련 서비스를 제공하는 자를 통해 본인확인이 완료된 회원에 대해서만 회원가입을 승인하거나 본인확인이 이루어지지 않은 회원에 대해서는 서비스 이용을 제한할 수 있습니다.</p>
      
      <h2>제 5 조 (회원 탈퇴 및 자격 상실 등)</h2>
      <p>회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 회원의 회원탈퇴 요청 즉시 회원탈퇴를 처리합니다. 회원탈퇴 시 회원자격이 상실되며 회사가 제공하는 각종 할인쿠폰, 이벤트 혜택 등이 소멸됩니다.</p>
      <p>회원이 다음 각 호의 사유에 해당하는 경우 회사는 회원의 자격을 제한 또는 상실시킬 수 있습니다.</p>
      <ul>
        <li>회원정보에 허위 내용을 입력하거나 타인의 정보를 도용한 경우</li>
        <li>회원이 본 서비스의 이용 목적이 아닌 기타 다른 목적으로 서비스를 이용 중인 경우</li>
        <li>다른 사람의 서비스 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우</li>
        <li>회원이 등록한 정보 올바르지 않아 회사가 상당한 노력을 기울였음에도 불구하고 회원에게 통지 또는 연락을 할 수 없게 된 경우</li>
        <li>회원의 아이디 및 비밀번호를 타인에게 제공하거나 제3자가 이용하도록 한 경우</li>
        <li>이 약관의 규정 또는 회사의 이용정책 등을 위반하거나 법령 기타 공서양속에 반하는 행위를 하는 경우</li>
      </ul>
      
      <h2>제 6 조 (회원에 대한 통지)</h2>
      <p>회사가 회원에 대한 통지를 하는 경우 회원이 회사와 미리 약정하여 지정한 방법에 따라 전자우편, SMS, 전화 등으로 할 수 있습니다.</p>
      <p>회사는 불특정다수 회원에 대한 통지의 경우 7일 이상 홈페이지의 공지사항에 게시함으로써 개별 통지에 갈음할 수 있습니다.</p>
      
      <h2>제 7 조 (서비스 이용)</h2>
      <p>회원은 회사가 제공하는 서비스 신청 절차에 따라 본 서비스의 이용을 신청할 수 있습니다.</p>
      <p>회사는 회사가 정한 결제수단을 통해 서비스 이용료가 지급된 경우 회원에게 본 서비스를 제공합니다.</p>
      <p>본 서비스 이용에 따른 이용조건은 아래와 같습니다.</p>
      <table>
        <thead>
          <tr>
            <th>구 분</th>
            <th>월 서비스이용료</th>
            <th>비 고</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>일반회원</td>
            <td>20,000</td>
            <td>코치등록, 개인분석, 비교분석, 영상업로드, 기타 기능</td>
          </tr>
          <tr>
            <td>코치회원(프리미엄)</td>
            <td>50,000</td>
            <td>회원 통합관리, 개인분석, 비교분석, 영상업로드, 통계보기, 기타 기능</td>
          </tr>
        </tbody>
      </table>
      <p>본 서비스는 1인 1서비스로 본인의 영상에 한하여 업로드, 분석 등이 가능함. 본인 외 타인의 영상 등 업로드, 분석하는 경우 서비스 이용이 제한될 수 있음.</p>
      <p>제3항의 서비스는 회원의 서비스 환경에 따라 일부 서비스가 제공되지 아니할 수 있습니다.</p>
      <p>회사는 고객이 업로드한 영상 등을 서비스 만료일로부터 1년까지(이하 “보관기간”이라 한다) 보관됩니다. 보관기간 내에 서비스이용료 결제 시 기존 서비스 그대로 서비스를 이용할 수 있습니다. 다만 영상의 다운로드 또는 백업은 제공하지 아니하며 보관기간 이후에는 전부 삭제됩니다.</p>
      <p>회사는 서비스를 신설, 변경하는 경우 관련 내용을 제6조에 따라 사전 통지함으로써 서비스를 신설, 변경할 수 있습니다.</p>
      
      <h2>제 8 조 (정기결제)</h2>
      <p>회원은 본 서비스의 이용료 결제를 위해 결제정보를 등록하여야 합니다. 회원이 결제수단을 등록할 경우 해당 결제수단으로 이용료를 계속 청구하는 것에 동의하는 것이며 회원이 다수의 결제수단을 등록한 경우 회사는 회사가 정한 세부정책에 따라 결제수단을 변경하여 결제할 수 있습니다.</p>
      <p>회원의 잔액 부족, 한도 초과, 결제 오류 등으로 서비스이용료의 결제가 이루어지지 않을 경우 회원은 회사가 고지한 재결제기한 내에 결제 정보를 재등록, 수정하는 등 정상적으로 결제가 이루어질 수 있도록 필요한 조치를 취하여야 합니다.</p>
      <p>회사는 서비스 재개를 위해 회원이 등록한 결제수단을 이용하여 주기적으로 이용료의 결제를 시도할 수 있습니다.</p>
      <p>제2항의 사유로 정상적인 결제가 이루어지지 아니한 경우에는 서비스이용이 제한될 수 있습니다.</p>
      
      <h2>제 9 조 (환불)</h2>
      <p>서비스이용 중단에 따른 환불조건은 아래와 같습니다.</p>
      <ul>
        <li>서비스 개시일로부터 7일 이내인 경우 : 100% 환불</li>
        <li>서비스 개시일로부터 7일 초과한 경우 : 90% 환불</li>
      </ul>
      <p>상기 조건은 영상업로드 또는 스윙분석 등 서비스 이용을 하지 아니한 경우에만 적용함.</p>
      <p>서비스기간이 만료된 이전 월 서비스이용료는 사용 여부와 무관하게 환불되지 아니함.</p>
      <p>전항에 따른 환불 처리는 5영업일 이내 처리됨을 원칙으로 합니다. 다만 절차 상 연장이 필요한 경우에는 제6조에 따라 통지합니다.</p>
      
      <h2>제 10 조 (회사의 권리 및 의무)</h2>
      <p>회사는 회원의 안정적인 서비스 이용을 위해 최선을 다 합니다.</p>
      <p>회사는 회원이 안전하게 서비스를 이용할 수 있도록 회원의 개인정보 보호를 위한 개인정보처리방침을 공시하고 이를 준수합니다.</p>
      <p>회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주 등으로 정상적인 서비스 제공이 불가할 경우에는 전부 또는 일부 서비스이용이 제한되거나 중단될 수 있습니다. 이 경우 회사는 사전 또는 사후 공지합니다.</p>
      <p>회사는 회원의 정당한 의견제시 또는 불만을 접수할 수 있습니다. 회사는 가능한 빠른 처리를 위해 노력합니다. 다만 빠른 처리가 곤란한 경우 그 사유와 처리 일정을 전달합니다.</p>
      
      <h2>제 11 조 (회원의 권리 및 의무)</h2>
      <p>회원은 이 약관의 규정, 회사의 이용정책, 이용안내 사항 및 관련 법령 등을 준수합니다.</p>
      <p>회원은 다음 각 호의 행위를 하지 아니합니다.</p>
      <ul>
        <li>1인 1서비스 이용 조건을 지키지 아니하는 행위</li>
        <li>골프 스윙 분석 이외의 다른 용도(재판매 등)로 사용하는 행위</li>
        <li>서비스 이용을 매개로 한 관행에 부합하지 않는 부정 거래 행위</li>
        <li>회사가 제공하는 이용방법에 의하지 아니하고 비정상적인 방법으로 서비스를 이용하거나 회사의 정보처리시스템에 접근하는 행위</li>
        <li>타인의 권리를 침해하거나 명예를 훼손하는 행위</li>
        <li>회원의 정보를 회사의 동의 없이 타인에게 제공하여 타인이 회사의 서비스를 이용하게 하는 행위 및 서비스의 이용권한, 기타 이용 계약상 지위를 타인에게 양도, 증여하는 행위</li>
        <li>회사가 제공하는 서비스를 벗어나는 비합리적인 요청, 공서양속에 반하는 행위 등으로 회사의 업무를 방해하는 행위</li>
        <li>회원은 자신의 결정에 의하여 회사의 서비스를 사용하여 특정 프로그램이나 정보 등을 다운받거나 접근함으로써 입게 되는 컴퓨터 시스템상의 손해나 데이터, 정보의 상실에 대한 책임을 집니다.</li>
      </ul>
      
      <h2>제 12 조 [권리의 귀속 및 저작물의 이용]</h2>
      <p>회원은 업로드하는 영상 등에 대해 모두 적법한 소유권 및 사용권을 보유하고 있어야 하며 이를 위반하여 권한 없는 영상물 등을 업로드하지 아니하여야 합니다. 이와 관련하여 분쟁이 발생한 경우 회사를 면책하고 신속하게 본인의 책임 하에 해결하여야 합니다. 만일 회사가 이를 대응하게 되는 경우에는 변호사 상담료, 선임비 등을 포함하여 회사에게 발생한 손해를 배상하여야 합니다.</p>
      <p>회원이 등록한 영상 등이 관련 법령에 위반되거나 사생활 침해, 명예훼손 등 타인의 권리를 침해한다고 인정되는 경우 회사는 해당 영상 등을 삭제하거나 회원의 접근을 임시적으로 차단할 수 있습니다.</p>
      
      <h2>제 13 조 [손해배상]</h2>
      <p>회사는 서비스의 결함에 의하여 회사가 제공하는 서비스가 손상, 훼손, 삭제되어 서비스 이용에 실제 발생한 손해만을 배상합니다.</p>
      <p>회원이 이 약관을 위반함으로 인하여 회사에 손해가 발생하게 되는 경우 회원은 회사에 대하여 그 손해에 대하여 배상할 책임이 있습니다.</p>
      <p>회원의 과실로 이 약관의 이용 제한 관련 각 규정에 근거 서비스의 이용이 제한되거나 이용계약이 해지된 경우 회사는 서비스이용료의 반환의무를 부담하지 아니합니다.</p>
      
      <h2>제 14 조 [책임 제한]</h2>
      <p>회사는 이 약관, 서비스 이용 방법 및 이용 기준을 준수하지 않는 등 회원의 귀책사유로 인한 서비스 이용의 장애에 대한 책임을 지지 않습니다.</p>
      <p>회사는 회원간(특히 코치회원 일반회원) 또는 회원과 제3자 상호간에 서비스를 매개로 하여 거래 등을 한 경우에는 이에 개입하지 아니하며 책임이 면제됩니다.</p>
      <p>회사는 무료로 제공되는 서비스의 일부 또는 전부를 회사의 정책, 운영상의 긴요한 사유로 수정, 중단, 변경할 수 있으며 이에 대하여 관련 법령에 별도 규정이 있지 아니하는 한 별도의 보상을 하지 아니합니다.</p>
      <p>회사는 다음 각 호의 사유로 인하여 회원 또는 제3자에게 발생한 손해에 대하여는 그 책임을 지지 아니합니다.</p>
      <ul>
        <li>천재지변, 전쟁, 기간통신사업자의 서비스 중지 등 해결이 곤란한 기술적 결함, 기타 불가항력으로 인해 서비스를 제공할 수 없는 경우</li>
        <li>회원이 자신의 아이디 또는 비밀번호 등의 관리를 소홀히 한 경우</li>
        <li>회사의 관리영역이 아닌 공중통신선로의 장애로 서비스 이용이 불가능한 경우</li>
        <li>기타 회사의 귀책사유가 없는 통신서비스 등의 장애로 인한 경우</li>
      </ul>
      <p>회사는 회원 또는 제3자가 서비스 내 게시 또는 전송한 정보, 자료, 사실의 신뢰도, 정확성 등의 내용에 대해 보증하지 아니합니다.</p>
      <p>회사는 회원이 서비스를 이용하여 기대하는 효용을 얻지 못한 것에 대하여 책임을 지지 아니하며 서비스에 대한 취사, 선택 또는 이용으로 발생하는 손해 등에 대해서도 책임이 면제됩니다.</p>
      
      <h2>제 15 조 (준거법 및 분쟁의 해결)</h2>
      <p>이 약관으로부터 발생한 모든 분쟁에 대해서는 대한민국 법이 적용되며 이에 의하여 해석됩니다.</p>
      <p>이 약관과 관련된 분쟁이 발생한 경우 양 당사자는 상호 합의를 통하여 이를 원만히 해결합니다. 그럼에도 불구하고 해결되지 아니한 경우에는 민사소송법 상의 관할법원에 제소합니다.</p>
      
      <h2>부칙</h2>
      <p>본 약관은 2024년 07월 01일부터 시행합니다.</p>
    </div>
  );
};

export default ServiceTerms;
