import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useToken } from '../Components/TokenContext';
import ReactPlayer from 'react-player/lazy';
import AWS from 'aws-sdk';
import { isMobile, isTablet } from 'react-device-detect';

const Bookmark = () => {

  let i;
  const navigate = useNavigate();
  const location = useLocation();

  const [thumbnailPath, setThumbnailPath] = useState([]); // 북마크 썸네일 전체의 경로 저장
  const [index, setIndex] = useState(null); // 북마크 인덱스
  const [showPopup, setShowPopup] = useState(false); // 팝업
  const [editednote, setEditedNote] = useState(''); // 북마크 썸네일 클릭 후 수정하여 저장한 코멘트
  const [isBookmarked, setIsBookmarked] = useState(false); // 즐찾 여부
  const [editedIsBookmarked, setEditedIsBookmarked] = useState(true); // 저장 전 즐찾 여부 저장해야 바뀜
  const [isSteamed, setIsSteamed] = useState(false); // 찜 여부
  const [editedisSteamed, setEditedisSteamed] = useState(isSteamed); // 저장 전 찜 여부 저장해야 바뀜
  const [thumbnails, setThumbnailUrls] = useState([]); // 마이페이지에 북마크 썸네일 띄우기 위한 src path
  const [bookmarkdata, setBookmarkdata] = useState({}); // 여기서 북마크 모든 데이터 추출 가능
  const [metadata, setMetadata] = useState({}); // 메타데이터 id별로 파싱한 데이터 저장
  const [selectedId, setSelectedId] = useState(null); // 썸네일 클릭 시 해당 썸네일의 myswings[i].id
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [videosize, setVideoSize] = useState({width: '0px', height:'0px'});
  const [flag, setFlag] = useState(0);


  const [cursor, setCursor] = useState([0]);
  const [total, setTotal] = useState(-1);
  const [jsonarr, setJsonarr] = useState([]); // 일반영상 id 파싱을 위한 take만큼의 json 배열
  const [videoId, setVideoId] = useState(null); // 영상의 고유 id : dataJSON 가져올 때 url에 사용

  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 번호 (1부터 시작)

  var itemsPerPage = 6;
  if(window.matchMedia("(min-width: 1180px) and (max-width: 1400px) and (orientation: landscape)").matches){
    itemsPerPage = 4;
  }

  const token2 = 'Bearer ' + sessionStorage.getItem('token')
  const take = isTablet ? 4 : 6;
  const apiId = sessionStorage.getItem('apiId');
  const apitype = sessionStorage.getItem('apitype');

  const transformSwingsData = (bookmarkdata) => { // 전체 
    var name;
    const transformedData = {};
  
    bookmarkdata.data.bookmarks.forEach((bookmark) => {
      if(bookmark.myswing.instructor){
        name = bookmark.myswing.instructor.user.name
      }
      else if(bookmark.myswing.student){
        name = bookmark.myswing.student.user.name
      }
      // Map each swing's data to its id
      transformedData[bookmark.myswing.id] = {
        clubType: bookmark.myswing.clubType,
        createdAt: bookmark.myswing.createdAt,
        isOpen: bookmark.myswing.isOpen,
        note: bookmark.myswing.note,
        bookmarkId: bookmark.id,
        order: bookmark.order,
        name: name
      };
    });
    
    return transformedData;
  };

  const currentThumbnails = thumbnailPath;

  const reload = () => {
    setFlag(flag+1);
    window.location.reload();
  }

  const handleSave = () => {
    reload();
    setIsSteamed(editedisSteamed);
    setEditedIsBookmarked(true);
    setEditedisSteamed(false);
      if(!editedIsBookmarked) {
          deleteBookmark();
      }

      if(editedisSteamed){
        setPriority();
      }
      else{
        setIsSteamed(false);
        setEditedisSteamed(false);
      }
      postMetaData();
      setEditedNote('');
      setShowPopup(false);
    };


useEffect(() => { // 북마크영상 데이터 받기
  const fetchData = async() => {
    try {
    const response = await fetch(`http://117.16.243.158:9000/bookmark/${sessionStorage.getItem('apitype')}/${sessionStorage.getItem('apiId')}?take=${take}&cursor=${cursor[currentPage-1]}`,{
      method: 'GET',
      headers: {
        'Authorization': token2,
        'Content-Type': 'application/json',
      },
    });

    const json = await response.json();
    setJsonarr(json)
    console.log(json)
    var thumbnailpath = [];

    for(i = 0; i< json.data.bookmarks.length; i++)
    {
      var thumbnail = {path : json.data.bookmarks[i].myswing.thumbnail, videoname: json.data.bookmarks[i].myswing.videoPath}
      thumbnailpath.push(thumbnail)
    }

    setThumbnailPath(thumbnailpath)
    setBookmarkdata(json)
    const transformedData = transformSwingsData(json);
    setMetadata(transformedData);
    
    cursor[currentPage] = json.data.cursor
    setCursor([...cursor]);
    if(total === -1){
      setTotal(json.data.total);
    }
    }catch(error){
      console.log('Error', error);
    }
  };
  fetchData();

},[index, apitype, apiId, flag, currentPage]);

sessionStorage.setItem('bookmarkcursor', JSON.stringify(cursor));

useEffect(() => { // 북마크영상 썸네일받기

  AWS.config.update({
    accessKeyId: 'AKIAVVKFCTNJJFZR6ABQ',
    secretAccessKey: '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',
    region: 'ap-northeast-2'
  });

  const s3 = new AWS.S3();
  const bucketName = 'dmz-metadata';
  const objectKey = selectedVideoUrl;
  
  const params = {
    Bucket: bucketName,
    Key: objectKey
  };

s3.getObject(params, (error, data) => {
    if (error) {
      console.error("Error downloading video from S3:", error);
    } else {
      // Assuming the data is a Blob
      const blob = new Blob([data.Body], { type: 'video/mp4' });
      const videoUrl = URL.createObjectURL(blob);
      setSelectedVideoUrl(videoUrl);
    }
  });

  const fetchThumbnails = async () => {
    try {
      const thumbnailPromises = thumbnailPath.map((item) => {
        const params = {
          Bucket: bucketName,
          Key: item.path,
        };
        return s3.getObject(params).promise().then(data => {
          return URL.createObjectURL(new Blob([data.Body], { type: 'image/jpeg' }));
        });
      });

      const thumbnails = await Promise.all(thumbnailPromises);
      setThumbnailUrls(thumbnails);
    } catch (error) {
      console.error('Error fetching thumbnails:', error);
    }
  };

  if (thumbnailPath.length > 0) {
    fetchThumbnails();
  }
}, [thumbnailPath, selectedVideoUrl, showPopup, cursor]);

  const handlePrev = () => {
    if(currentPage > 1){ setCurrentPage(currentPage - 1); // 0 미만으로 가지 않도록
  }
  };

  const handleNext = () => {
    if(currentPage <= total/take){
      setCurrentPage(currentPage + 1); // 최대 페이지를 초과하지 않도록
    }
  };

  sessionStorage.setItem('bookmarkpage', currentPage);

  const thumbnailname = (path) => {
    const parts = path.split('/');
    const filename = parts[parts.length - 1]; // (예: "2023-09-20_11h57m47s.jpg")
    const dateTimePart = filename.split('.')[0]; // (예: "2023-09-20_11h57m47s")
    return dateTimePart; // "2023-09-20_11h57m47s"
  };

  const handleThumbnailClick = (index) => {
    setIndex(index);
    const videoUrl = bookmarkdata.data.bookmarks[index].myswing.videoPath;
    setSelectedVideoUrl(videoUrl);
    setSelectedId(bookmarkdata.data.bookmarks[index].myswing.id);
    setShowPopup(true);
    setVideoId(jsonarr.data.bookmarks[index].myswing.id)
  };
  sessionStorage.setItem('bookmarkId', videoId);
  
  const handleClose = () => {
    if(!isBookmarked) setEditedIsBookmarked(true);
    if(!isSteamed) setEditedisSteamed(false);
    setEditedNote(''); // Reset to empty if no metadata selected
    setShowPopup(false);
  };
  
  const handlePersonalAnalysis = (index) => { // 개인분석에 videopath전달
    const selectedVideo = bookmarkdata.data.bookmarks[index].myswing;
    if(sessionStorage.getItem('apitype') === 'student'){
    navigate('/solo', { state: { bookmarkindex: index, videopath: selectedVideo.videoPath } });
    }
    else if(sessionStorage.getItem('apitype') === 'instructor'){
      navigate('/coachsolo', { state: { bookmarkindex: index, videopath: selectedVideo.videoPath } });
    }
  };

  // 비교 분석 페이지로 이동하는 함수
  const handleComparativeAnalysis = (index) => { // 비교분석에 videopath전달
    const selectedVideo = bookmarkdata.data.bookmarks[index].myswing;
    if(sessionStorage.getItem('apitype') === 'student'){
      navigate("/compare", { state: { bookmarkindex: index, videopath: selectedVideo.videoPath } });
    }
    else if(sessionStorage.getItem('apitype') === 'instructor'){
      navigate("/coachcompare", { state: { bookmarkindex: index, videopath: selectedVideo.videoPath } });
    }
  };

      const deleteBookmark = async() =>{
        try {
            const response = await fetch(`http://117.16.243.158:9000/bookmark/${apitype}/${apiId}/${metadata[selectedId].bookmarkId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': token2,
                'Content-Type': 'application/json',
            },
            });
        
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            // 요청 성공 처리
            console.log('Metadata updated successfully');
        } catch (error) {
            console.error('Error while posting metadata:', error);
        }
 }

    const postMetaData = async () => {
    
      try { // 노트랑 cursor보내기
        const response = await fetch(`http://117.16.243.158:9000/myswing/${apitype}/${apiId}/note`, {
          method: 'POST',
          headers: {
            'Authorization': token2,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            note : editednote,  
            myswingId : selectedId   
          })
        });
    
        if (!response.ok) {
          console.log(response);
          throw new Error('Network response was not ok');
        }
        // 요청 성공 처리
        console.log('Metadata updated successfully');
      } catch (error) {
        console.error('Error while posting metadata:', error);
      }

  };
  const setPriority = async() =>{
    try { // 찜 보내기
      const response = await fetch(`http://117.16.243.158:9000/bookmark/${apitype}/${apiId}/priority/${metadata[selectedId].bookmarkId}`, {
        method: 'PUT',
        headers: {
          'Authorization': token2,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // 요청 성공 처리
    } catch (error) {
      console.error('Error while posting metadata:', error);
    }
  }

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(min-width: 900px) and (max-width: 1280px) and (orientation: landscape)").matches) {
        setVideoSize({ width: '100px', height: '200px' });
      } else {
        setVideoSize({ width: '200px', height: '300px' });
      }
    };
  
    handleResize();
  
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

    const renderPopup = () => {
        if (!showPopup) return null;
        return (
        <div className="popup">
            <h3>Swing Info</h3>
            {selectedVideoUrl && (
              <div style={{ float: 'right', marginRight: '10px',marginTop:'-80px' }}>
                <ReactPlayer url={selectedVideoUrl} loop={true} playing controls width={videosize.width} height={videosize.height} />
              </div>
            )}
            {metadata && (
            <div>
                <p><strong>클럽 종류: </strong> {metadata[selectedId].clubType}</p>
                <p><strong>업로드 시간: </strong> {metadata[selectedId].createdAt}</p>
                <p><strong>hand: </strong> {'우타'}</p>
                <p><strong>이름: </strong> {metadata[selectedId].name}</p>
                <p><strong>코멘트: </strong> {metadata[selectedId].note} 
                <input type="text" value={editednote} onChange={(e) => setEditedNote(e.target.value)}/></p>
                <div className="bookmark-container">
                  <p className="bookmark-label">
                      <strong>즐겨찾기:</strong><input type="checkbox" checked={editedIsBookmarked} onChange={(e) => setEditedIsBookmarked(e.target.checked)}/>
                  </p>
                  <p className="bookmark-label">
                    <strong>찜:</strong><input type="checkbox" checked={editedisSteamed} onChange={(e) => setEditedisSteamed(e.target.checked)}/>
                  </p>
                  <button onClick={handleSave}>적용</button>
                </div>
            </div>
            )}
            <button onClick={() => handlePersonalAnalysis(index)}>개인 분석</button>
            <button onClick={() => handleComparativeAnalysis(index)}>비교 분석</button>
            <button onClick={handleClose}>닫기</button>
        </div>
        );
    };

return (
  <div className="bookmark-label">
    <label>북마크 영상 [{total}개]</label>
    <div className="bookmark-container">
      {currentThumbnails && currentThumbnails.map((item, index) => {
        const bookmarkId = bookmarkdata.data.bookmarks[index].myswing.id;
        const isPriority = metadata[bookmarkId]?.order === 0;

        return (
          <div className="bookmarkthumbnail-item" key={index}>
            <img 
              onClick={() => handleThumbnailClick(index)} 
              src={thumbnails[index]} 
              alt={''}
              style={{ 
                width: '200px', 
                height: '400px', 
                border: isPriority ? '2px solid yellow' : 'none'
              }}
            />
            <div className="Mythumbnail-title">
              {thumbnailname(thumbnailPath[index].videoname)}
            </div>
          </div>
        );
      })}
    </div>
      <div className="pagination-controls">
        <br></br>
        <span onClick={handlePrev} disabled={currentPage === 1}>&lt; 이전</span>
        <span> {currentPage} / {Math.ceil(total / itemsPerPage)}</span>
        <span onClick={handleNext} disabled={currentPage >= Math.ceil(total / itemsPerPage) - 1}>다음 &gt;</span>
      </div>
    {renderPopup()}
  </div>
);
  };
  
  export default Bookmark;