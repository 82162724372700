import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import '../Styles/loginpage.css';
import { useToken } from '../Components/TokenContext';
import { showAlert } from '../Components/alert';
import { useSocket } from '../Components/SocketContext';
import { isMobile, isTablet } from 'react-device-detect';

function LoginPage() {
  const { setToken, setStudentId, setUsername, setUserId, setCoachId, setPassword, setNickname, setLoginId, setApitype, setApiId, logout } = useToken();
  const navigate = useNavigate();
  const { updateLoginId } = useSocket();

  const [loginData, setLoginData] = useState({
    id: "",
    password: "",
  });
  setLoginId(loginData.id);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleLogin = async () => {
    try {
      const response = await fetch('http://117.16.243.158:9000/signin', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          loginId: loginData.id,
          password: loginData.password
        }),
      });

      const responseData = await response.json();

      if (responseData.status === "success") {
        if (isMobile && !isTablet) {
          alert("로그인 되었습니다.");
        } else {
          showAlert("로그인 되었습니다.");
        }
        console.log(responseData);
        
        updateLoginId(loginData.id);
        sessionStorage.setItem('logoutflag', 0);
        sessionStorage.setItem('token', responseData.data.token);
        sessionStorage.setItem('loginId', loginData.id);
        sessionStorage.setItem('nickname', responseData.data.user.nickname);
        sessionStorage.setItem('password', loginData.password);

        if (responseData.data.user) {
          if (responseData.data.user.student && !responseData.data.user.instructor) {
            sessionStorage.setItem('studentId', responseData.data.user.student.id);
            sessionStorage.setItem('apitype', 'student');
            sessionStorage.setItem('apiId', responseData.data.user.student.id);
            setApiId(responseData.data.user.student.id);
            setApitype('student');
          } else if (responseData.data.user.instructor && !responseData.data.user.student) {
            sessionStorage.setItem('coachId', responseData.data.user.instructor.id);
            sessionStorage.setItem('apitype', 'instructor');
            sessionStorage.setItem('apiId', responseData.data.user.instructor.id);
            sessionStorage.setItem('introduce', responseData.data.user.instructor.introduce);
            sessionStorage.setItem('certificate', responseData.data.user.instructor.certificate);
            setApiId(responseData.data.user.instructor.id);
            setApitype('instructor');
          }
        }
        sessionStorage.setItem('username', responseData.data.user.name);
        sessionStorage.setItem('userId', responseData.data.user.id);

        setToken(responseData.data.token);
        if (responseData.data.user) {
          if (responseData.data.user.student && !responseData.data.user.instructor) {
            setStudentId(responseData.data.user.student.id);
            setNickname(responseData.data.user.nickname);
            setCoachId(null);
          } else if (responseData.data.user.instructor && !responseData.data.user.student) {
            setCoachId(responseData.data.user.instructor.id);
            setNickname(responseData.data.user.nickname);
            setStudentId(null);
          }
        }
        setUsername(responseData.data.user.name);
        setUserId(responseData.data.user.id);
        setPassword(loginData.password);
        
        if (isMobile && !isTablet) {
          if(sessionStorage.getItem('apitype') === 'instructor'){
            navigate('/coachupload');
          }
          else{
            navigate('/upload');
          }
        } else {
          navigate('/mypage');  // 데스크탑 및 태블릿 환경에서는 마이페이지로 이동
        }
      } else if (responseData.status === "Error") {
        // 로그인 실패 처리
        showAlert("일치하는 정보가 없습니다.");
      }
    } catch (error) {
      console.error('Error occurred during login:', error);
    }
  };

  return (
    <div className="container">
      {(isMobile && !isTablet) && (
        <h2 style={{fontSize : '2rem'}}>KEEP MOTION</h2>
      )}
      <h2>Login</h2>
      <form>
        <input
          type="text"
          name="id"
          placeholder="아이디"
          value={loginData.id}
          onChange={handleInputChange}
        />
        <input
          type="password"
          name="password"
          placeholder="비밀번호"
          value={loginData.password}
          onChange={handleInputChange}
        />
        <button type="button" onClick={handleLogin}>로그인</button>
      </form>
      <p className='login-text'><Link to="/beforesignup">SIGNUP</Link></p>
    </div>
  );
}

export default LoginPage;
