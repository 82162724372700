import React, { useState, useEffect } from 'react';
import "../Styles/my-information.css"
import { useToken } from '../Components/TokenContext';
import { showAlert } from '../Components/alert';

const MyInfo = () => {

  const { userId } = useToken();
  const token2 = 'Bearer ' + sessionStorage.getItem('token')
  const apiId = sessionStorage.getItem('apiId');
  const apitype = sessionStorage.getItem('apitype');
  const [institutions, setInstitutions] = useState([]);

  const [userInfo, setUserInfo] = useState({
    password: "",
    confirmPassword: "",
    name: sessionStorage.getItem('username'),
    nickname: sessionStorage.getItem('nickname'),
    introduce: sessionStorage.getItem('introduce'),
    certificate: sessionStorage.getItem('certificate')
  })

  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return regex.test(password);

  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "password" || name === "confirmPassword") {
      if (name === "confirmPassword" && value !== userInfo.password) {
        setPasswordMismatch(true);
        setIsPasswordValid(validatePassword(value));
      } else {
        setPasswordMismatch(false);
      }
    }
    setUserInfo((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    const fetchInstitutions = async() => { // 기관 리스트 받아오기
      try {
        const response = await fetch(`http://117.16.243.158:9000/institutions`, {
        method: 'GET',
        headers: {
            'Authorization': token2,
            'Content-Type': 'application/json',
      },
      });
      const data = await response.json();
      setInstitutions(data.data.institutions);
      console.log(data)
      console.log(data.data)

      }catch(error){
      console.log('Error', error);
      }
    };
    fetchInstitutions();
    }, []);
console.log(institutions)
    
  const handleSubmit = async () => {

    if(passwordMismatch){
        showAlert('비밀번호가 일치하지 않습니다.');
        return;
    }
    let bodyData;
    if(apitype === 'student'){
      bodyData = {
        userId: userInfo.userId,
        password: userInfo.password,
        name: userInfo.name,
        nickname: userInfo.nickname
      };
    }
    else{
      bodyData = {
        userId: userInfo.userId,
        password: userInfo.password,
        name: userInfo.name,
        nickname: userInfo.nickname,
        introduce: userInfo.introduce,
        certificate: userInfo.certificate
      };
    }
    try {
      const response = await fetch(`http://117.16.243.158:9000/${apitype}/${apiId}`, {
        method: 'PUT',
        headers: {
          'Authorization': token2,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyData),
      });

      if (response.ok) {
        const data = await response.json();
        // Handle success
        console.log('User info updated:', data);
        showAlert('정보가 성공적으로 업데이트되었습니다.');

        // Update session storage
        sessionStorage.setItem('username', userInfo.name);
        sessionStorage.setItem('nickname', userInfo.nickname);
        sessionStorage.setItem('introduce', userInfo.introduce);
        sessionStorage.setItem('certificate', userInfo.certificate);
        sessionStorage.setItem('institution', userInfo.institution);

        // Update state
        setUserInfo((prevInfo) => ({
          ...prevInfo,
          name: userInfo.name,
          nickname: userInfo.nickname,
          introduce: userInfo.introduce,
          certificate: userInfo.certificate,
          institution: userInfo.institution
        }));
      } else {
        // Handle errors
        console.error('Failed to update user info.');
        showAlert('정보 업데이트를 실패했습니다.');
      }
    } catch (error) {
      console.error('Error during update:', error);
      showAlert('업데이트 중 오류가 발생했습니다.');
    }
  };

  return (
    <div className="container">
      <h3>정보 수정</h3>
      <form>
        <input
          type="password"
          name="password"
          placeholder="비밀번호"
          value={userInfo.password}
          onChange={handleInputChange}
        />
        <p className={isPasswordValid ? "success-text" : "error-text"} style={{color:'white'}}>
          문자, 숫자, 특수문자의 조합으로 8자 이상으로 입력하세요.
        </p>
        <input
          type="password"
          name="confirmPassword"
          placeholder="비밀번호 확인"
          value={userInfo.confirmPassword}
          onChange={handleInputChange}
        />

        {userInfo.password === userInfo.confirmPassword &&
          userInfo.password !== "" && (
            <p className="success-text">비밀번호가 일치합니다.</p>
          )}

        {userInfo.password !== userInfo.confirmPassword && (
          <p className="error-text">비밀번호가 다릅니다.</p>
        )}

        <input
          type="text"
          name="name"
          placeholder="이름"
          value={userInfo.name}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="nickname"
          placeholder="닉네임"
          value={userInfo.nickname}
          onChange={handleInputChange}
        />
        {apitype === 'instructor' && (
          <>
            <input
              type="text"
              name="introduce"
              placeholder="소개"
              value={userInfo.introduce}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="certificate"
              placeholder="자격"
              value={userInfo.certificate}
              onChange={handleInputChange}
            />
            <input
              type="text"
              name="institution"
              placeholder="기관"
              value={userInfo.institution}
              onChange={handleInputChange}
            />
          </>
        )}

        <button type="button" onClick={handleSubmit}>
          수정하기
        </button>
      </form>
    </div>
  );
};

export default MyInfo;
