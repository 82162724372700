import React, { useState, useEffect } from 'react';
import AWS from 'aws-sdk';
//import { useNavigate } from 'react-router-dom';
import { useToken } from '../Components/TokenContext';
import '../Styles/upload.css';
import { showAlert } from '../Components/alert';
import { isMobile, isTablet } from 'react-device-detect';

const CoachUploadPage = () => {
    //const navigate = useNavigate();
    const { studentId, userId, coachId, setVideoname, videoname, loginId } = useToken();
    const [video, setVideo] = useState(null);
    const [videoUrl, setVideoUrl] = useState(null);
    const [clubType, setClubType] = useState('');
    const [comment, setComment] = useState('');
    const [handType, setHandType] = useState('right');
    const [isHigh, setIsHigh] = useState(false);
    const [student, setStudent] = useState([]);
    const [selectedStudentId, setSelectedStudentId] = useState('');
    const [index, setIndex] = useState('');
    const [isUploading, setIsUploading] = useState(false);

    const token2 = 'Bearer ' + sessionStorage.getItem('token')
    const apiId = sessionStorage.getItem('apiId');
    const apitype = sessionStorage.getItem('apitype');

    const isSelf = selectedStudentId === '' || selectedStudentId === '본인';
    const uploadUrl = isSelf
        ? `http://117.16.243.158:9000/myswing/upload/${apitype}/${apiId}`
        : `http://117.16.243.158:9000/myswing/upload/instructor/${coachId}/student`;

    const uploadLoginId = isSelf ? loginId : student[index].loginId;
    const uploaduserId = isSelf ? userId : student[index].userId;     

    useEffect(() => {
        const fetchStudents = async() => { // 학생정보 가져오기
          try {
          const response = await fetch(`http://117.16.243.158:9000/lesson/instructor/${coachId}`, {
            method: 'GET',
            headers: {
              'Authorization': token2,
              'Content-Type': 'application/json',
            },
          });
          if(response.status === 401)
          {
            if(isMobile){
                alert('로그인 후 이용해 주세요');
            }
            else{
                showAlert('로그인 후 이용해 주세요');
            }
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('nickname')
          }
          
          const data = await response.json();
          if (data.data && data.data.lessons) {
  
            const studentData = data.data.lessons.map((user) => ({
                lessonId: user.id, // lessonId
                userId: user.student.user.id, // userId
                studentid: user.student.id, // student Id
                name: user.student.user.name,
                loginId: user.student.user.loginId, // loginId = string
                isApprove: user.isApprove
            }));
            setStudent(studentData);
        }
          }catch(error){
            console.log('Error', error);
          }
        };
        fetchStudents();
      
      }, [coachId]);


    const handleVideoChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
            setVideo(selectedFile);
            const fileUrl = URL.createObjectURL(selectedFile);
            setVideoUrl(fileUrl);
            const currentDate = new Date();
            const year = currentDate.getFullYear();
            const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const day = currentDate.getDate().toString().padStart(2, '0');
            const hours = currentDate.getHours().toString().padStart(2, '0');
            const minutes = currentDate.getMinutes().toString().padStart(2, '0');
            const seconds = currentDate.getSeconds().toString().padStart(2, '0');

            const dateStr = `${year}-${month}-${day}`;
            const timeStr = `${hours}h${minutes}m${seconds}s`;

            setVideoname(`${dateStr}_${timeStr}`);
        }
    };

    const downloadS3 = async () => {

        AWS.config.update({
          accessKeyId: 'AKIAVVKFCTNJJFZR6ABQ',
          secretAccessKey: 'BumgaSdIZebLXk41OflvXz8LXzir6dBuo9UD6QZO',
          region: 'ap-northeast-2' // e.g., 'us-west-2'
        });
        const s3 = new AWS.S3();
        const bucketName = 'dmz-metadata';
        const objectKey = `${loginId}/${videoname}.mp4`;
      
        const params = {
          Bucket: bucketName,
          Key: objectKey
        };
      
      s3.getObject(params, (error, data) => {
          if (error) {
            console.error("Error downloading video from S3:", error);
          } else {
            // Assuming the data is a Blob
            const blob = new Blob([data.Body], { type: 'video/mp4' });
            const videoUrl = URL.createObjectURL(blob);
            setVideoUrl(videoUrl);
          }
        });
      };
      
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!video) {
            if(isMobile){
                alert('업로드할 동영상을 선택해주세요.');
            }
            else{
                showAlert('업로드할 동영상을 선택해주세요.');
            }
            return;
        }
        setIsUploading(true); // 업로드 시작
        await uploadToS3();
        await postMetadata();
        setIsUploading(false); // 업로드 완료
        downloadS3();
};
    const uploadToS3 = async () => {
        AWS.config.update({
            accessKeyId: 'AKIAVVKFCTNJJFZR6ABQ',
            secretAccessKey: 'BumgaSdIZebLXk41OflvXz8LXzir6dBuo9UD6QZO',
            region: 'ap-northeast-2' // e.g., 'us-west-2'
          });
          const s3 = new AWS.S3();
          const bucketName = 'dmz-metadata';

          const params = {
            Bucket: bucketName,
            Key: `${uploadLoginId}/${videoname}.mp4`,
            Body: video
        };
        try {
            const s3Response = await s3.upload(params).promise();
            console.log('File uploaded:', s3Response);
        } catch (error) {
            console.error('Error uploading to S3:', error);
            if(isMobile){
                alert('S3 업로드 중 오류가 발생했습니다.');
            }
            else{
                showAlert('S3 업로드 중 오류가 발생했습니다.');
            }
        }
    };

    const postMetadata = async () => { // 업로드 하기
        try {
            let requestBody;
            if (isSelf) {
                requestBody = {
                    videoPath: `${uploadLoginId}/${videoname}.mp4`,
                    userId: uploaduserId,
                    clubType: clubType,
                    comment: comment,
                    handType: handType,
                    isHigh : isHigh
                };
            } else {
                requestBody = {
                    studentId: student[index].studentid, // Assuming this holds the ID of the selected student
                    videoPath: `${uploadLoginId}/${videoname}.mp4`,
                    clubType: clubType,
                    comment: comment,
                    handType: handType,
                    isHigh : isHigh
                };
            }
            const response = await fetch(uploadUrl, {
                method: 'POST',
                headers: {
                    'Authorization': token2,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestBody)
            });
            const data = await response.json();
            if (data.success) {
                if(isMobile){
                    alert('성공적으로 업로드되었습니다!');
                }
                else{
                    showAlert('성공적으로 업로드되었습니다!');
                }
            }
            else {
                if(isMobile){
                    alert(data.message);
                }
                else{
                    showAlert(data.message);
                }
                
            }
        } catch (error) {
            console.error('업로드 중 오류:', error);
            if(isMobile){
                alert('업로드 중 오류가 발생했습니다.');
            }
            else{
                showAlert('업로드 중 오류가 발생했습니다.');
            }
            
        }
    };
    const handleStudentSelection = (e) => {
        const index = e.target.selectedIndex - 1; // -1 because the first option is "본인"
        setIndex(index)
        setSelectedStudentId(e.target.value);
      };

    function LoadingSpinner() {
    return (
        <>
            <div className="spinner"></div>
            <>
                <p>분석 중 입니다.</p>
            </>
        </>
    );
    }

    return (
        <div className="upload-container">
             {isMobile && (
                <h2 style={{fontSize : '2rem', color:'white', marginLeft: '11rem'}}>KEEP MOTION</h2>
            )}
           {!isMobile && videoUrl && (
                <video src={videoUrl} controls className="upload-video">
                    <source src={videoUrl} type="video/mp4" />
                    브라우저가 지원하지 않는 비디오 태그입니다.
                </video>
            )}
            <form onSubmit={handleSubmit} className="upload-metadata">
                <input type="file" accept="video/*" onChange={handleVideoChange} />
                <div>
                    <label>제목:</label><br/>
                    <input 
                        type="text" 
                        value={videoname} 
                        onChange={(e) => setVideoname(e.target.value)} // 사용자 입력으로 제목 변경 가능
                        className="upload-input" 
                    />
                </div>
                <div>
                    <label>클럽 종류:</label><br/>
                    <select value={clubType} onChange={(e) => setClubType(e.target.value)} className="dropdown-input">
                        <option value="Iron">Iron</option>
                        <option value="Driver">Driver</option>
                        <option value="Wedge">Wedge</option>
                    </select>
                </div>
                <div>
                    <label>코멘트: </label><br/>
                    <textarea value={comment} onChange={(e) => setComment(e.target.value)} className="upload-textarea"/>
                </div>
                <div>
                    <label>회원선택: </label><br/>
                    <select value={selectedStudentId} onChange={handleStudentSelection}>
                        <option>본인</option>
                        {student.map((student) => (
                        <option key={student.id} value={student.id}>{student.name}</option>
                        ))}
                    </select><br/>
                    <label>좌타우타:</label><br/>
                    <select value={handType} onChange={(e) => setHandType(e.target.value)}>
                        <option value="right">우타</option>
                        <option value="left">좌타</option>
                    </select>
                </div>
                <div className="toggle-switch">
                <label>초고속 카메라 여부   </label>
                    <label className="switch">
                        <input type="checkbox" checked={isHigh} onChange={() => {setIsHigh(!isHigh);}} />
                        <span className="slider round"></span>
                    </label>
                </div>
                {isUploading ? <LoadingSpinner /> : <button type="submit">영상 & 메타데이터 업로드</button>}
            </form>
        </div>
    );
};

export default CoachUploadPage;
