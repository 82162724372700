import '../Styles/alert.css';

export const showAlert = (message) => {
    const overlay = document.createElement('div');
    overlay.className = 'custom-alert-overlay';

    const alertBox = document.createElement('div');
    alertBox.className = 'custom-alert-box';

    const messageParagraph = document.createElement('p');
    messageParagraph.textContent = message;

    const closeButton = document.createElement('button');
    closeButton.textContent = '닫기';
    closeButton.onclick = () => overlay.remove();

    alertBox.appendChild(messageParagraph);
    alertBox.appendChild(closeButton);
    overlay.appendChild(alertBox);

    document.body.appendChild(overlay);
};