import React, { useState, useEffect } from 'react';
import "../Styles/mypage.css"
//import { useToken } from '../Components/TokenContext';
import General from '../Components/general';
import Bookmark from '../Components/bookmark';
import Public from '../Components/public';

const MyPage = () => {

  return (
    <>
      <General />
      <Bookmark />
      <Public />
    </>
  );
};

export default MyPage;