import React from 'react';
import "../Styles/mypage.css"
import Public from '../Components/public';

const Publicvideo = () => {

  return (
    <>
      <Public />
    </>
  );
};

export default Publicvideo;