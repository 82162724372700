import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../Styles/beforesignup.css'

const BeforeSignup = () => {
    const navigate = useNavigate();

  const navigateToProSignup = () => {
    navigate('/prosignup');
  };

  const navigateToSignup = () => {
    navigate('/signup');
  };

  return (
    <div className='before-container'>
      <button onClick={navigateToProSignup}>코치로 가입</button>
      <button onClick={navigateToSignup}>회원으로 가입</button>
    </div>
  );
};

export default BeforeSignup;
