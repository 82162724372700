import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToken } from '../Components/TokenContext';
import ReactPlayer from 'react-player/lazy';
import AWS from 'aws-sdk';
import { showAlert } from '../Components/alert';
import { useSocket } from "./SocketContext"
import { isMobile, isTablet } from 'react-device-detect';

const General = ({}) => {
  const {socket} = useSocket();
  const navigate = useNavigate();
  let i;
  const { username, studentId, coachId } = useToken();
  const [thumbnailPath, setThumbnailPath] = useState([]); // 일반영상 썸네일 전체의 경로 저장
  const [index, setIndex] = useState(null); // 일반영상 인덱스
  const [showPopup, setShowPopup] = useState(false); // 팝업
  const [editednote, setEditedNote] = useState(''); // 일반영상 썸네일 클릭 후 수정하여 저장한 코멘트
  const [isBookmarked, setIsBookmarked] = useState(false); // 즐찾 여부
  const [editedIsBookmarked, setEditedIsBookmarked] = useState(isBookmarked); // 저장 전 즐찾 여부 저장해야 바뀜
  const [isOpened, setIsOpened] = useState(false); // 공개 여부
  const [editedisOpened, setEditedIsOpened] = useState(isOpened); // 저장 전 공개 여부 저장해야 바뀜
  const [thumbnails, setThumbnailUrls] = useState([]); // 마이페이지에 일반영상 썸네일 띄우기 위한 src path
  const [generaldata, setGeneraldata] = useState({}); // 여기서 일반영상 모든 데이터 추출 가능
  const [metadata, setMetadata] = useState({}); // 메타데이터 id별로 파싱한 데이터 저장
  const [selectedId, setSelectedId] = useState(null); // 썸네일 클릭 시 해당 썸네일의 myswings[i].id
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [videosize, setVideoSize] = useState({width: '0px', height:'0px'});
  const [flag, setFlag] = useState(0);
  const [mystudent, setMystudent] = useState([]); // 내 회원(대기 중 포함)
  const [selectedStudentId, setSelectedStudentId] = useState('없음');

  const [cursor, setCursor] = useState([0]);
  const [total, setTotal] = useState(-1);
  const [jsonarr, setJsonarr] = useState([]); // 일반영상 id 파싱을 위한 take만큼의 json 배열
  const [videoId, setVideoId] = useState(null); // 영상의 고유 id : dataJSON 가져올 때 url에 사용

  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 번호 (1부터 시작)

  var itemsPerPage = 6;
  if(window.matchMedia("(min-width: 1180px) and (max-width: 1400px) and (orientation: landscape)").matches){
    itemsPerPage = 4;
  }
   // 페이지당 아이템 수

  const token2 = 'Bearer ' + sessionStorage.getItem('token')
  const take = isTablet ? 4 : 6;
  const apiId = sessionStorage.getItem('apiId');
  const apitype = sessionStorage.getItem('apitype');

  const transformSwingsData = (generaldata) => {
    const transformedData = {};
    generaldata.data.myswings.forEach((swing) => {
      
      transformedData[swing.id] = {
        clubType: swing.clubType,
        createdAt: swing.createdAt,
        isOpen: swing.isOpen,
        note: swing.note,
      };
    });
    return transformedData;
  };

  const currentThumbnails = thumbnailPath;

  const reload = () => {
    setFlag(flag+1);
    window.location.reload();
  }

  if(socket){
    socket.on("KM:upload", data => {
      setFlag(flag+1);
      //// navigate('/mypage')
    })
  }

  const handleSave = () => {
    reload();
    setIsBookmarked(editedIsBookmarked);
    setIsOpened(editedisOpened);
    setEditedIsBookmarked(false);
    if(editedIsBookmarked === true){
        setBookmark();
        setShowPopup(false);
    }
    else{
        setIsBookmarked(false);
        setEditedIsBookmarked(false);
        setShowPopup(false);
    }
    if(editedisOpened === true){
      setOpen();
      setShowPopup(false);
    }else{
      setNotOpen();
      setIsOpened(false);
      setEditedIsOpened(false);
      setShowPopup(false);
    }
    postMetaData();
    setEditedNote(''); // 팝업의 코멘트 창
    //setShowPopup(false);
  };

  useEffect(() => { // 일반영상 데이터 받기

    const fetchData = async() => {
      try {
      const response = await fetch( `http://117.16.243.158:9000/myswing/${sessionStorage.getItem('apitype')}/${sessionStorage.getItem('apiId')}?take=${take}&cursor=${cursor[currentPage-1]}`,{
        method: 'GET',
        headers: {
          'Authorization': token2,
          'Content-Type': 'application/json',
        },
      });
        if(response.status === 401)
        {
          showAlert('로그인 후 이용해 주세요');
          navigate('/login');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('nickname');
        }

      const json = await response.json();
      setJsonarr(json)
      console.log(json)
      var thumbnailpath = [];

      for(i = 0; i< json.data.myswings.length; i++)
      {
        var thumbnail = {path : json.data.myswings[i].thumbnail, isOpen: json.data.myswings[i].isOpen, videoname: json.data.myswings[i].videoPath}
        thumbnailpath.push(thumbnail)
      }

      setThumbnailPath(thumbnailpath)
      setGeneraldata(json)
      const transformedData = transformSwingsData(json);
      setMetadata(transformedData);
      
      cursor[currentPage] = json.data.cursor
      setCursor([...cursor]);
      if(total === -1){
        setTotal(json.data.total);
      }
      }
      catch(error){
        console.log('Error', error);
      }
      
    };
    fetchData();

  },[index, apitype, apiId, flag, currentPage]);

  sessionStorage.setItem('generalcursor', JSON.stringify(cursor));

  useEffect(() => { // 일반영상 썸네일받기

    AWS.config.update({
      accessKeyId: 'AKIAVVKFCTNJJFZR6ABQ',
      secretAccessKey: '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',
      region: 'ap-northeast-2'
    });

    const s3 = new AWS.S3();
    const bucketName = 'dmz-metadata'; 
    const objectKey = selectedVideoUrl;

    const params = {
      Bucket: bucketName,
      Key: objectKey
    };

  s3.getObject(params, (error, data) => {
      if (error) {
        console.error("Error downloading video from S3:", error);
      } else {

        const blob = new Blob([data.Body], { type: 'video/mp4' });
        const videoUrl = URL.createObjectURL(blob);
        setSelectedVideoUrl(videoUrl);
      }
    });

    const fetchThumbnails = async () => {
      try {
        const thumbnailPromises = thumbnailPath.map((item) => {
          const params = {
            Bucket: bucketName,
            Key: item.path,
          };
          return s3.getObject(params).promise().then(data => {
            return URL.createObjectURL(new Blob([data.Body], { type: 'image/jpeg' }));
          });
        });

        const thumbnails = await Promise.all(thumbnailPromises);
        setThumbnailUrls(thumbnails);
      } catch (error) {
        console.error('Error fetching thumbnails:', error);
      }
    };

    if (thumbnailPath.length > 0) {
      fetchThumbnails();
    }
  }, [thumbnailPath, selectedVideoUrl, cursor]);


  const handlePrev = () => {
    if(currentPage > 1){ setCurrentPage(currentPage - 1); // 0 미만으로 가지 않도록
  }
  };
  
  const handleNext = () => {
    if(currentPage < total/take){
      setCurrentPage(currentPage + 1); // 최대 페이지를 초과하지 않도록
    }
  };

  sessionStorage.setItem('generalpage', currentPage);

  const thumbnailname = (path) => {
    const filename = path.substring(path.lastIndexOf('/') + 1);
    const dateTimePart = filename.split('.')[0];
    return dateTimePart;
  };

  const handleThumbnailClick = (index) => {
    setIndex(index);
    const videoUrl = generaldata.data.myswings[index].videoPath;
    setSelectedVideoUrl(videoUrl);
    setSelectedId(generaldata.data.myswings[index].id);
    setShowPopup(true);
    setIsOpened(generaldata.data.myswings[index].isOpen);
    setEditedIsOpened(generaldata.data.myswings[index].isOpen);
    setVideoId(jsonarr.data.myswings[index].id)
  };
  sessionStorage.setItem('generalId', videoId);

  const handleClose = () => {
      if(!isBookmarked) setEditedIsBookmarked(false);
      if(!isOpened) setEditedIsOpened(false);
    setEditedNote('');
    setShowPopup(false);
  };

  const handlePersonalAnalysis = (index) => { // 개인분석에 videopath전달
    const selectedVideo = generaldata.data.myswings[index];
    if(sessionStorage.getItem('apitype') === 'student'){
      navigate('/solo', { state: { generalindex: index, videopath: selectedVideo.videoPath } });
    }
    else if(sessionStorage.getItem('apitype') === 'instructor'){
      navigate('/coachsolo', { state: { generalindex: index, videopath: selectedVideo.videoPath } });
    }
  };

  // 비교 분석 페이지로 이동하는 함수
  const handleComparativeAnalysis = (index) => { // 비교분석에 videopath전달
    const selectedVideo = generaldata.data.myswings[index];
    if(sessionStorage.getItem('apitype') === 'student'){
      navigate("/compare", { state: { generalindex: index, videopath: selectedVideo.videoPath } });
    }
    else if(sessionStorage.getItem('apitype') === 'instructor'){
      navigate('/coachcompare', { state: { generalindex: index, videopath: selectedVideo.videoPath } });
    }
  };

  const handleDelete = async (swingId) => { // swingId = selectedId = myswingId
    if (!window.confirm('정말 이 영상을 삭제하시겠습니까? 삭제하면 복구할 수 없습니다.')) return;
    const AWS = require('aws-sdk');

    AWS.config.update({
      accessKeyId: 'AKIAVVKFCTNJJFZR6ABQ',
      secretAccessKey: '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',
      region: 'ap-northeast-2'
    });
    const s3 = new AWS.S3();
    const bucketName = 'dmz-metadata';
    const objectKey = selectedVideoUrl;

      const params = {
        Bucket: bucketName,
        Key: objectKey,
      };
    
      s3.deleteObject(params, function(err, data) {
        if (err) {
          console.log(err, err.stack);
        } else {
          console.log('Video deleted from S3:', data);
        }
      });
  
    try {
      const response = await fetch(`http://117.16.243.158:9000/myswing/${swingId}/${apitype}/${apiId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': token2,
          'Content-Type': 'application/json',
        }
      });

      if (response.ok) {
        showAlert('영상이 삭제되었습니다.');
        setShowPopup(false); // 팝업 닫기
        reload();
      } else {
        showAlert('영상 삭제에 실패했습니다.');
      }
    } catch (error) {
      console.error('영상 삭제 중 오류가 발생했습니다:', error);
    }
  };

  useEffect(() => {
    const fetchStudents = async() => { // 등록된 학생정보 가져오기 Approve로 파싱
      try {
      const response = await fetch(`http://117.16.243.158:9000/lesson/instructor/${coachId}`, {
        method: 'GET',
        headers: {
          'Authorization': token2,
          'Content-Type': 'application/json',
        },
      });
      if(response.status === 401)
      {
        //showAlert('로그인 후 이용해 주세요');
        //navigate('/login');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('nickname')
      }
      
      const data = await response.json();
        if (data.data && data.data.lessons) {

          const studentData = data.data.lessons.map((user) => ({
              lessonId: user.id,
              id: user.student.id,
              name: user.student.user.name,
              loginId: user.student.user.loginId,
              isApprove: user.isApprove
          }));
          setMystudent(studentData);
    }
      }catch(error){
        console.log('Error', error);
      }
    };
    fetchStudents();
  
  }, [coachId]);

  const handleAccountChange = (e) => {
    setSelectedStudentId(e.target.value);
  };

  const handleSend = async (swingId) => { // 회원 계정으로 보내기
      try {
        const response = await fetch(`http://117.16.243.158:9000/myswing/${swingId}/send/${apitype}/${apiId}`, {
          method: 'POST',
          headers: {
            'Authorization': token2,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            studentId: selectedStudentId
          })
        });
    
        if (!response.ok) {
          console.log(response);
          throw new Error('Network response was not ok');
        }
        // 요청 성공 처리
        else{
          console.log('Metadata updated successfully');
          reload();
        }
      } catch (error) {
        console.error('Error while posting metadata:', error);
      }

  };

  useEffect(() => {
    const handleResize = () => {
      if (window.matchMedia("(min-width: 900px) and (max-width: 1280px) and (orientation: landscape)").matches) {
        setVideoSize({ width: '100px', height: '200px' });
      } else {
        setVideoSize({ width: '200px', height: '300px' });
      }
    };
  
    handleResize();
  
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
  
    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);
  const renderPopup = () => {

      if (!showPopup) return null;
      return (
        <div className="popup">
          <h3>Swing Info</h3>
          {selectedVideoUrl && (
            <div style={{ float: 'right', marginRight: '10px',marginTop:'-80px' }}>
              <ReactPlayer url={selectedVideoUrl} loop={true} playing controls width={videosize.width} height={videosize.height} />
            </div>
          )}
          {metadata && 
            <div>
              <p><strong>클럽 종류: </strong> {metadata[selectedId].clubType}</p>
              <p><strong>업로드 시간: </strong> {metadata[selectedId].createdAt}</p>
              <p><strong>hand: </strong> {'우타'}</p>
              <p><strong>코멘트: </strong> {metadata[selectedId].note} <input type="text" value={editednote} onChange={(e) => setEditedNote(e.target.value)}/></p>
            {apitype === 'instructor' &&
              <div className="bookmark-container">
                <p style={{marginBottom : '-6rem'}} className="bookmark-label">
                    <strong>즐겨찾기:</strong><input type="checkbox" checked={editedIsBookmarked} onChange={(e) => setEditedIsBookmarked(e.target.checked)}/>
                </p>
                <p style={{marginBottom : '-6rem'}} className="bookmark-label">
                  <strong >공개여부:</strong><input type="checkbox" checked={editedisOpened} onChange={(e) => setEditedIsOpened(e.target.checked)}/>
                </p>
                <button style={{marginBottom : '-6rem'}} onClick={handleSave}>적용</button>
              </div>
            }
             {apitype === 'student' &&
              <div className="bookmark-container">
                <p className="bookmark-label">
                    <strong>즐겨찾기:</strong><input type="checkbox" checked={editedIsBookmarked} onChange={(e) => setEditedIsBookmarked(e.target.checked)}/>
                </p>
                <p className="bookmark-label">
                  <strong >공개여부:</strong><input type="checkbox" checked={editedisOpened} onChange={(e) => setEditedIsOpened(e.target.checked)}/>
                </p>
                <button onClick={handleSave}>적용</button>
              </div>
            }
            </div>
          }
           {apitype === 'instructor' && 
              <>
                  <select style={{fontSize : '1.5rem'}} value={selectedStudentId} onChange={handleAccountChange}>
                  <option value="없음">없음</option>
                  {mystudent.filter(student => student.isApprove).map(student => (
                    <option key={student.id} value={student.id}>{student.name}</option>
                  ))}
                </select>
                <button style={{marginTop : '1rem'}} onClick={() => handleSend(selectedId)}>보내기</button>
            </>
            }
         
          <button style={{marginTop : '1rem'}} onClick={() => handlePersonalAnalysis(index)}>개인 분석</button>
          <button style={{marginTop : '1rem'}} onClick={() => handleComparativeAnalysis(index)}>비교 분석</button>
          <button style={{marginTop : '1rem',  color: 'red'}} onClick={() => handleDelete(selectedId)}>삭제</button>
          <button style={{marginTop : '1rem'}} onClick={handleClose}>닫기</button>
        </div>
      );
    };

  const setBookmark = async() =>{ // 북마크로 설정하기
    try {
      const response = await fetch(`http://117.16.243.158:9000/bookmark/${apitype}/${apiId}`, {
        method: 'POST',
        headers: {
          'Authorization': token2,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          myswingId : selectedId
        })
      });
  
      if (!response.ok) {
        console.log(response)
        throw new Error('Network response was not ok');
      }
      // 요청 성공 처리
      console.log('Metadata updated successfully');
    } catch (error) {
      console.error('Error while posting metadata:', error);
    }
  }

  const postMetaData = async () => { // 저장 눌렀을 때 코멘트 전송
      try {
        const response = await fetch(`http://117.16.243.158:9000/myswing/${apitype}/${studentId}/note`, {
          method: 'POST',
          headers: {
            'Authorization': token2,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            note : editednote,  
            myswingId : selectedId   
          })
        });
        if (!response.ok) {
          console.log(response);
          throw new Error('Network response was not ok');
        }
        // 요청 성공 처리
        console.log('Metadata updated successfully');
      } catch (error) {
        console.error('Error while posting metadata:', error);
      }

  };
  const setOpen = async() =>{ // 공개 영상으로 전환
    try {
      const response = await fetch(`http://117.16.243.158:9000/myswing/${apitype}/${apiId}/open/${selectedId}`, {
        method: 'POST',
        headers: {
          'Authorization': token2,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          isOpen: true
        })
      });
      if (!response.ok) {
        console.log(response)
        throw new Error('');
      }else{
        console.log('공개영상으로 설정됐음.');
      }
    } catch (error) {
      console.error('공개영상 에러:', error);
    }
  }

  const setNotOpen = async() =>{ // 일반 영상으로 전환
    try {
      const response = await fetch(`http://117.16.243.158:9000/myswing/${apitype}/${apiId}/open/${selectedId}`, {
        method: 'POST',
        headers: {
          'Authorization': token2,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          isOpen: false
        })
      });
      if (!response.ok) {
        console.log(response)
        throw new Error('');
      }else{
        console.log('공개영상으로 설정됐음.');
      }
    } catch (error) {
      console.error('공개영상 에러:', error);
    }
  }

  return (
    <div className="Mylabel">
      <label>업로드 영상 [{total}개]</label>
      <div className="Mythumbnail-container">
        {currentThumbnails && currentThumbnails.map((item, index) => (
          <div className="Mythumbnail-item" key={index}>
            <img onClick={() => handleThumbnailClick(index)} src={thumbnails[index]} alt={''} style={{ width: '200px', height: '400px' }}/>
            <div className="Mythumbnail-title">
              {thumbnailname(thumbnailPath[index].videoname)}
            </div>
          </div>
        ))}
      </div>
      <div className="pagination-controls">
        <br></br>
        <span onClick={handlePrev} disabled={currentPage === 1}>&lt; 이전</span>
        <span> {currentPage} / {Math.ceil(total / itemsPerPage)}</span>
        <span onClick={handleNext} disabled={currentPage >= Math.ceil(total / itemsPerPage) -1 }>다음 &gt;</span>
      </div>
      {renderPopup()}
    </div>
  );
};
  
export default General;