import React from 'react';
import '../Styles/personal-info-agreement.css'; // Import the CSS file for styling

const PersonalInfoAgreement = () => {
  return (
    <div className="personal-info-agreement-container">
      <h1>개인정보 수집 및 이용동의</h1>
      
      <h2>항목</h2>
      <ul>
        <li>1. 일반 회원가입 :</li>
        <ul>
          <li>필수 : 아이디 이름 닉네임 일반/프로 성별 우타/좌타 비밀번호</li>
        </ul>
        <li>2. 네이버 로그인 :</li>
        <ul>
          <li>필수 : 이메일주소 암호화된 동일인 식별정보(CI)</li>
          <li>선택 : 별명 생일 연령대</li>
        </ul>
        <li>3. 카카오 로그인 :</li>
        <ul>
          <li>필수 : 이메일주소 암호화된 동일인 식별정보(CI)</li>
          <li>선택 : 별명 생일 연령대</li>
        </ul>
      </ul>

      <h2>목적</h2>
      <ul>
        <li>필수 :</li>
        <ul>
          <li>가. 회원관리 및 본인확인</li>
          <li>나. 개인화 서비스 제공</li>
          <li>다. 불만처리 및 민원처리 고지사항 전달</li>
        </ul>
        <li>선택 :</li>
        <ul>
          <li>가. 서비스의 마케팅 및 프로모션</li>
          <li>나. 성향의 분석을 통한 맞춤형 광고에 활용</li>
        </ul>
      </ul>

      <h2>보유 및 이용 기간</h2>
      <p>회사는 수집한 개인정보를 아래와 같이 보유하며 보유기간이 지난 후에는 지체 없이 파기합니다.</p>
      <ul>
        <li>필수 : 회원 탈퇴 시부터 1년까지</li>
        <li>선택 : 회원 탈퇴 시까지</li>
      </ul>
      <p>단, 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정 기간 동안 회원정보를 보관합니다.</p>

      <p>위 개인정보 수집·이용에 동의하지 않으실 경우 서비스를 이용하실 수 없습니다.</p>
    </div>
  );
};

export default PersonalInfoAgreement;
