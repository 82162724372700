import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useToken } from './TokenContext';
import '../Styles/menu.css';
import { showAlert } from '../Components/alert';
import { useSocket } from '../Components/SocketContext';
import { isMobile, isTablet } from 'react-device-detect';

const ProtectedLink = ({ to, children }) => {
    const navigate = useNavigate();
    const handleClick = (event) => {
        if (!sessionStorage.getItem('token')) {
            event.preventDefault();
            showAlert('로그인 후 이용해 주세요');
            navigate('/login');
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('nickname');
        }
    };
    return <Link to={to} onClick={handleClick}>{children}</Link>;
};

const Menu = () => {
    const { logout, studentId, coachId } = useToken();
    const navigate = useNavigate();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [uploadEnabled, setUploadEnabled] = useState(false); // 업로드 활성화 상태
    const { socket, updateRoomId, updateLoginId } = useSocket();

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (sidebarOpen && !event.target.closest('.sidebar')) {
                setSidebarOpen(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => document.removeEventListener('mousedown', handleOutsideClick);
    }, [sidebarOpen]);

    const handleRequestUpload = (request) => {
        if (socket) {
            console.log("toggle switch")
            socket.emit("KM:requestUploadVideo", { request });
        }
    };

    const toggleSidebar = () => setSidebarOpen(!sidebarOpen);

    const handleLogout = () => {
        sessionStorage.setItem('logoutflag', 1);
        logout(); // 로그아웃 처리
        updateRoomId(null);
        updateLoginId(null);
        sessionStorage.removeItem('roomId')
        sessionStorage.removeItem('loginId')
        sessionStorage.removeItem('password')
        socket.close();
        navigate('/login'); // 홈으로 이동
    };

    const uploadPageLink = sessionStorage.getItem('apitype') === 'instructor' ? "/coachupload" : "/upload";
    const solo = sessionStorage.getItem('apitype') === 'instructor' ? "/coachsolo" : "/solo";
    const compare = sessionStorage.getItem('apitype') === 'instructor' ? "/coachcompare" : "/compare";

    return (
        <>
            <nav>
                <div className="menu-logo-left">
                    <Link to="/">
                        <img src="/tempodiall.png" alt="Left Logo" />
                    </Link>
                </div>
                <ul>
                    {sessionStorage.getItem('token') ? (
                        <>
                            {(!isMobile || isTablet) && (
                                <>
                                    <li><ProtectedLink to={solo}>개인분석</ProtectedLink></li>
                                    <li><ProtectedLink to={compare}>비교분석</ProtectedLink></li>
                                    <li><ProtectedLink to="/mypage">마이페이지</ProtectedLink></li>
                                    <li><ProtectedLink to={uploadPageLink}>영상업로드</ProtectedLink></li>
                                </>
                            )}
                            <li className="nickname">{sessionStorage.getItem('nickname')}님</li>
                            <li><Link to="/" onClick={handleLogout}>로그아웃</Link></li>
                            {(!isMobile || isTablet) && socket && (
                                <div className="toggle-switch">
                                    <label className="switch">
                                        <input type="checkbox" checked={uploadEnabled} onChange={() => { setUploadEnabled(!uploadEnabled); handleRequestUpload(!uploadEnabled); }} />
                                        <span className="slider round"></span>
                                    </label>
                                </div>
                            )}
                        </>
                    ) : (
                        (!isMobile || isTablet) && (
                            <>
                                <li><Link to="/login">로그인</Link></li>
                                <li className="nickname"></li>
                            </>
                        )
                    )}
                </ul>
                {(!isMobile || isTablet) && (
                    <div className="menu-logo-right" onClick={toggleSidebar}>
                        <img src="/sidebar.png" alt="Right Logo" />
                    </div>
                )}
                {sessionStorage.getItem('token') && (!isMobile || isTablet) && (
                    <div className={sidebarOpen ? "sidebar open" : "sidebar"}>
                        {studentId && (
                            <>
                                <ProtectedLink to="/my-information">내 정보</ProtectedLink>
                                <ProtectedLink to="/coach-registration">코치 등록</ProtectedLink>
                                <ProtectedLink to="/myvideo">내 영상</ProtectedLink>
                                <ProtectedLink to="/public-video">공개 영상</ProtectedLink>
                                <ProtectedLink to="/statistics">통계 보기</ProtectedLink>
                                <ProtectedLink to="/launchpad">런치패드 설정</ProtectedLink>
                            </>
                        )}
                        {coachId && (
                            <>
                                <ProtectedLink to="/my-information">내 정보</ProtectedLink>
                                <ProtectedLink to="/mystudent">회원 관리</ProtectedLink>
                                <ProtectedLink to="/myvideo">내 영상</ProtectedLink>
                                <ProtectedLink to="/public-video">공개 영상</ProtectedLink>
                                <ProtectedLink to="/statistics">통계 보기</ProtectedLink>
                                <ProtectedLink to="/launchpad">런치패드 설정</ProtectedLink>
                            </>
                        )}
                    </div>
                )}
            </nav>
        </>
    );
    
};

export default Menu;
