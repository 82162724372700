// TokenContext.js
import { createContext, useContext, useState, useEffect } from 'react';

const TokenContext = createContext();

export function TokenProvider({ children }) {
  const [token, setToken] = useState(null);
  const [studentId, setStudentId] = useState(null);
  const [coachId, setCoachId] = useState(null);
  const [username, setUsername] = useState("");
  const [userId, setUserId] = useState(null);
  const [password, setPassword] = useState('');
  const [nickname, setNickname] = useState('');
  const [jsonall, setJsonAll] = useState([]);
  const [jsonall1, setJsonAll1] = useState([]);
  const [jsonall2, setJsonAll2] = useState([]);
  const [bookmarkall, setBookmarkAll] = useState([]);
  const [metajson, setMetaJson] = useState([]);
  const [bookmarkjson, setBookmarkjson] = useState();
  const [videoname, setVideoname] = useState('');
  const [loginId, setLoginId] = useState('');
  const [apiId, setApiId] = useState('');
  const [apitype, setApitype] = useState('');
  const [coachlist, setCoachlist] = useState([]);
  const [mycoachlist, setMycoachlist] = useState([]);
  const [watingcoachlist, setWaitingcoachlist] = useState([]);

  useEffect(() => {
    const storedToken = sessionStorage.getItem('token');
    const storedStudentId = sessionStorage.getItem('studentId');
    const storedCoachId = sessionStorage.getItem('coachId');
    const storedUsername = sessionStorage.getItem('username');
    const storedUserId = sessionStorage.getItem('userId');
    const storedPassword = sessionStorage.getItem('password');
    const storedNickname = sessionStorage.getItem('nickname');
    const storedVideoname = sessionStorage.getItem('videoname');
    const storedloginId = sessionStorage.getItem('loginId');
    const storedApiId = sessionStorage.getItem('apiId');
    const storedApitype = sessionStorage.getItem('apitype');
    const storedCoachlist = sessionStorage.getItem('coachlist');
    const storedMycoachlist = sessionStorage.getItem('mycoachlist');
    const storedWatingcoachlist = sessionStorage.getItem('watingcoachlist');
    if (storedToken) {
      setToken(storedToken);
      setStudentId(storedStudentId);
      setUsername(storedUsername);
      setUserId(storedUserId);
      setCoachId(storedCoachId);
      setPassword(storedPassword);
      setNickname(storedNickname);
      setVideoname(storedVideoname);
      setLoginId(storedloginId);
      setApiId(storedApiId);
      setApitype(storedApitype);
      setCoachlist(storedCoachlist);
      setMycoachlist(storedMycoachlist);
      setWaitingcoachlist(storedWatingcoachlist);
    }
  }, []);

  const logout = () => {
    // 로컬 스토리지에서 사용자 정보 제거
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('studentId');
    sessionStorage.removeItem('coachId');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('userId');
    sessionStorage.removeItem('password');
    sessionStorage.removeItem('nickname');
    sessionStorage.removeItem('videoname');
    sessionStorage.removeItem('loginId');
    sessionStorage.removeItem('apiId');
    sessionStorage.removeItem('apitype');
    sessionStorage.removeItem('coachlist');
    sessionStorage.removeItem('mycoachlist');
    sessionStorage.removeItem('watingcoachlist');
    //sessionStorage.clear();
    setToken(null);
    setStudentId(null);
    setUsername("");
    setUserId(null);
    setCoachId(null);
    setPassword('');
    setNickname('');
    setJsonAll([]);
    setJsonAll1([]);
    setJsonAll2([]);
    setMetaJson([]);
    setBookmarkjson(null);
    setVideoname('');
    setLoginId('');
    setApiId('');
    setApitype('');
    setCoachlist([]);
    setMycoachlist([]);
    setWaitingcoachlist([]);
  };

  return (
    <TokenContext.Provider value={{ token, setToken, studentId, setStudentId, coachId, setCoachId, username, setUsername,  userId, setUserId, jsonall, setJsonAll, 
      metajson, setMetaJson, jsonall1, setJsonAll1, jsonall2, setJsonAll2, bookmarkjson, setBookmarkjson, logout, password, setPassword, nickname, setNickname, videoname, setVideoname, 
    loginId, setLoginId , apiId, setApiId, apitype, setApitype, bookmarkall, setBookmarkAll, coachlist, setCoachlist, mycoachlist, setMycoachlist, watingcoachlist, setWaitingcoachlist}}>
      {children}
    </TokenContext.Provider>
  );
}

export function useToken() {
  return useContext(TokenContext);
}