import React, { useState, useEffect } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import axios from 'axios';
import { Chart, registerables } from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import { act } from 'react-dom/test-utils';
Chart.register(...registerables, annotationPlugin);

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);
// 중위값
function median(arr) {
  const sorted = arr.slice().sort((a, b) => a - b);
  const middle = Math.floor(sorted.length / 2);

  if (sorted.length % 2 === 0) {
    return (sorted[middle - 1] + sorted[middle]) / 2;
  }
  return sorted[middle];
}

// 평균
function mean(arr) {
  return arr.reduce((acc, val) => acc + val, 0) / arr.length;
}

// 분산
function variance(arr) {
  const meanValue = mean(arr);
  return arr.reduce((acc, val) => acc + (val - meanValue) ** 2, 0) / arr.length;
}

//표준편차
function standardDeviation(arr) {
    const varianceValue = variance(arr);
    return Math.sqrt(varianceValue);
}

function getQuantile(data, q) {
    const sorted = data.sort((a, b) => a - b);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    
    if ((sorted[base + 1] !== undefined)) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
}
// 사분위수
const calculateQuartiles = (data) => {
    const q1 = getQuantile(data, 0.25);
    const q2 = getQuantile(data, 0.5); // Median
    const q3 = getQuantile(data, 0.75);
//   const sorted = [...data].sort((a, b) => a - b);
//   console.log(data, sorted.length)
//   const q1 = sorted[Math.floor((sorted.length / 4)) - 1];
//   // 중위값 계산은 calculateMedian 함수를 사용할 수 있습니다.
//   const q2 = sorted[Math.floor((sorted.length / 2)) - 1];
//   const q3 = sorted[Math.floor((sorted.length * (3 / 4))) - 1];
  return { q1, q2, q3 };
};

const calculateMinMax = (data) => {
  return { min: Math.min(...data), max: Math.max(...data) };
};

const Statistics = () => {
    const [chartData, setChartData] = useState({
        datasets: [],
    });
    const [chartOptions, setChartOptions] = useState({});
    const [statistics, setStatistics] = useState(null);
    const [isStatistics, setIsStatistics] = useState(false);

    const fetchData = async () => {
        try {
            let response
            if (sessionStorage.getItem('apitype') === 'student'){
                response = await axios.get(`http://117.16.243.158:9000/myswing/student/${sessionStorage.getItem('studentId')}?take=10000`, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Content-Type': 'application/json',
                    }
                });
            }
            else if(sessionStorage.getItem('apitype') === 'instructor'){
                response = await axios.get(`http://117.16.243.158:9000/myswing/instructor/${sessionStorage.getItem('coachId')}?take=10000`, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
                        'Content-Type': 'application/json',
                    }
                });
            }
            const statsData = response.data.data.myswings.reverse();
            const { tempoBack, labels, topSpine, downIncline, topToImpact, upIncline, impactToFinish, fixabilitySpine, fixabilityKnee, headUpDown, headLeftRight } = statsData.reduce((acc, item) => {
                const dataJSON = JSON.parse(item.dataJSON).data;

                ///////////////////////////////// Tempo
                acc.tempoBack.push(dataJSON.tempo.back_down_tempo.back? dataJSON.tempo.back_down_tempo.back: 0);
                acc.labels.push(`${acc.tempoBack.length}`)

                ///////////////////////////////// Position
                acc.topSpine.push(dataJSON.rotation_angle.spine_angle.top ? dataJSON.rotation_angle.spine_angle.top: 0)

                ///////////////////////////////// Swing Direction Down Incline
                acc.downIncline.push(dataJSON.path.rwrist.down_swing_angle ? dataJSON.path.rwrist.down_swing_angle: 0)

                ///////////////////////////////// Swing Direction Top To Impact
                let data
                if(dataJSON.path.rwrist.area)
                    if(typeof dataJSON.path.rwrist.area === 'string')
                        data = dataJSON.path.rwrist.area
                    else
                        data = "[F]0"
                else
                    data = "[F]0"

                let prefix = data.match(/[^\d.-]+/)[0];
                let numericPart = parseFloat(data.match(/-?\d+\.?\d*/));
                if(prefix === "[R]")
                    acc.topToImpact.push(numericPart * -1);
                else
                    acc.topToImpact.push(numericPart);

                ///////////////////////////////// Swing Direction Up Incline
                acc.upIncline.push(dataJSON.path.rwrist.finish_swing_angle? dataJSON.path.rwrist.finish_swing_angle: 0);

                ///////////////////////////////// Swing Direction Impact To Finish
                if(dataJSON.path.rwrist.area_finish)
                    if(typeof dataJSON.path.rwrist.area_finish === 'string')
                        data = dataJSON.path.rwrist.area_finish
                    else
                        data = "[F]0"
                else
                    data = "[F]0"

                prefix = data.match(/[^\d.-]+/)[0];
                numericPart = parseFloat(data.match(/-?\d+\.?\d*/));
                if(prefix === "[R]")
                    acc.impactToFinish.push(numericPart * -1);
                else
                    acc.impactToFinish.push(numericPart);

                ///////////////////////////////// Fixability
                acc.fixabilitySpine.push(dataJSON.rotation_angle.spine_angle.total_minmax? dataJSON.rotation_angle.spine_angle.total_minmax:0)
                acc.fixabilityKnee.push(dataJSON.rotation_angle.knee? dataJSON.rotation_angle.knee : 0)

                ///////////////////////////////// Fixability Head Up Down
                if(dataJSON.path.ears.back_down_y)
                    if(typeof dataJSON.path.ears.back_down_y === 'string')
                        data = dataJSON.path.ears.back_down_y
                    else
                        data = "[F]0"
                else
                    data = "[F]0"

                prefix = data.match(/[^\d.-]+/)[0];
                numericPart = parseFloat(data.match(/-?\d+\.?\d*/));
                if(prefix === "[R]")
                    acc.headUpDown.push(numericPart * -1);
                else
                    acc.headUpDown.push(numericPart);
                    
                ///////////////////////////////// Fixability Head Left Right

                if(dataJSON.path.ears.back_down_x)
                    if(typeof dataJSON.path.ears.back_down_x === 'string')
                        data = dataJSON.path.ears.back_down_x
                    else
                        data = "[F]0"
                else
                    data = "[F]0"

                prefix = data.match(/[^\d.-]+/)[0];
                numericPart = parseFloat(data.match(/-?\d+\.?\d*/));
                if(prefix === "[R]")
                    acc.headLeftRight.push(numericPart * -1);
                else
                    acc.headLeftRight.push(numericPart);

                return acc
            }, { tempoBack: [], tempoDown: [], labels: [], topSpine: [], downIncline: [], topToImpact: [], upIncline: [], impactToFinish: [], fixabilitySpine: [], fixabilityKnee: [], headUpDown: [], headLeftRight: [] });

            const stats = {
                tempoBack: {
                    median: median(tempoBack),
                    mean: mean(tempoBack),
                    variance: variance(tempoBack),
                    standardDeviation: standardDeviation(tempoBack),
                    quartiles: calculateQuartiles(tempoBack),
                    minmax: calculateMinMax(tempoBack),
                },
                topSpine: {
                    median: median(topSpine),
                    mean: mean(topSpine),
                    variance: variance(topSpine),
                    standardDeviation: standardDeviation(topSpine),
                    quartiles: calculateQuartiles(topSpine),
                    minmax: calculateMinMax(topSpine),
                },
                downIncline: {
                    median: median(downIncline),
                    mean: mean(downIncline),
                    variance: variance(downIncline),
                    standardDeviation: standardDeviation(downIncline),
                    quartiles: calculateQuartiles(downIncline),
                    minmax: calculateMinMax(downIncline),
                },
                topToImpact: {
                    median: median(topToImpact),
                    mean: mean(topToImpact),
                    variance: variance(topToImpact),
                    standardDeviation: standardDeviation(topToImpact),
                    quartiles: calculateQuartiles(topToImpact),
                    minmax: calculateMinMax(topToImpact),
                },
                upIncline: {
                    median: median(upIncline),
                    mean: mean(upIncline),
                    variance: variance(upIncline),
                    standardDeviation: standardDeviation(upIncline),
                    quartiles: calculateQuartiles(upIncline),
                    minmax: calculateMinMax(upIncline),
                },
                impactToFinish: {
                    median: median(impactToFinish),
                    mean: mean(impactToFinish),
                    variance: variance(impactToFinish),
                    standardDeviation: standardDeviation(impactToFinish),
                    quartiles: calculateQuartiles(impactToFinish),
                    minmax: calculateMinMax(impactToFinish),
                },
                fixabilitySpine: {
                    median: median(fixabilitySpine),
                    mean: mean(fixabilitySpine),
                    variance: variance(fixabilitySpine),
                    standardDeviation: standardDeviation(fixabilitySpine),
                    quartiles: calculateQuartiles(fixabilitySpine),
                    minmax: calculateMinMax(fixabilitySpine),
                },
                fixabilityKnee: {
                    median: median(fixabilityKnee),
                    mean: mean(fixabilityKnee),
                    variance: variance(fixabilityKnee),
                    standardDeviation: standardDeviation(fixabilityKnee),
                    quartiles: calculateQuartiles(fixabilityKnee),
                    minmax: calculateMinMax(fixabilityKnee),
                },
                headUpDown: {
                    median: median(headUpDown),
                    mean: mean(headUpDown),
                    variance: variance(headUpDown),
                    standardDeviation: standardDeviation(headUpDown),
                    quartiles: calculateQuartiles(headUpDown),
                    minmax: calculateMinMax(headUpDown),
                },
                headLeftRight: {
                    median: median(headLeftRight),
                    mean: mean(headLeftRight),
                    variance: variance(headLeftRight),
                    standardDeviation: standardDeviation(headLeftRight),
                    quartiles: calculateQuartiles(headLeftRight),
                    minmax: calculateMinMax(headLeftRight),
                },
            };
            setStatistics(stats);
            setChartData({
                labels: labels,
                datasets: [
                    {
                        label: "Tempo(back)",
                        data: tempoBack,
                        borderColor: '#FF6633',
                        tension: 0.1,
                        fill: false,
                        pointStyle: 'circle',
                        pointRadius: 5,
                    },
                    {
                        label: "Position(Spine)",
                        data: topSpine,
                        borderColor: '#FFB399',
                        tension: 0.1,
                        fill: false,
                        pointStyle: 'circle',
                        pointRadius: 5,
                        hidden: true
                    },
                    {
                        label: "Down Incline",
                        data: downIncline, 
                        borderColor: '#FF33FF', 
                        tension: 0.1,
                        fill: false,
                        pointStyle: 'circle',
                        pointRadius: 5,
                        hidden: true
                    },
                    {
                        label: "Top To Impact",
                        data: topToImpact, 
                        borderColor: '#FFFF99',
                        tension: 0.1,
                        fill: false,
                        pointStyle: 'circle',
                        pointRadius: 5,
                        hidden: true
                    },
                    {
                        label: "UpIncline",
                        data: upIncline, 
                        borderColor: '#00B3E6',
                        tension: 0.1,
                        fill: false,
                        pointStyle: 'circle',
                        pointRadius: 5,
                        hidden: true
                    },
                    {
                        label: "Impact to Finish",
                        data: impactToFinish, 
                        borderColor: '#E6B333',
                        tension: 0.1,
                        fill: false,
                        pointStyle: 'circle',
                        pointRadius: 5,
                        hidden: true
                    },
                    {
                        label: "Fixability(Spine)",
                        data: fixabilitySpine, 
                        borderColor: '#3366E6',
                        tension: 0.1,
                        fill: false,
                        pointStyle: 'circle',
                        pointRadius: 5,
                        hidden: true
                    },
                    {
                        label: "Fixability(Knee)",
                        data: fixabilityKnee, 
                        borderColor: '#99FF99',
                        tension: 0.1,
                        fill: false,
                        pointStyle: 'circle',
                        pointRadius: 5,
                        hidden: true
                    },
                    {
                        label: "Head UpDown",
                        data: headUpDown, 
                        borderColor: '#B34D4D',
                        tension: 0.1,
                        fill: false,
                        pointStyle: 'circle',
                        pointRadius: 5,
                        hidden: true
                    },
                    {
                        label: "Head LeftRight",
                        data: headLeftRight,
                        borderColor: '#80B300', 
                        tension: 0.1,
                        fill: false,
                        pointStyle: 'circle',
                        pointRadius: 5,
                        hidden: true
                    },
                ],
            });
            setChartOptions({
                responsive: true,
                plugins: {
                    legend: {
                        position: 'top',
                        onClick: (event, legendItem, legend) => {
                            const chart = legend.chart;
                            const datasetIndex = legendItem.datasetIndex;
                            const meta = chart.getDatasetMeta(datasetIndex);

                            // 데이터 세트의 표시 상태를 토글합니다.
                            meta.hidden = !meta.hidden;
                            const annotations = chart.options.plugins.annotation.annotations;
                            Object.entries(annotations).forEach(([key, annotation]) => {
                                if (annotation.datasetIndex === datasetIndex) {
                                // 해당 주석의 표시 상태를 토글
                                    annotation.display = true;
                                }
                                else{
                                    annotation.display = false;
                                }
                            });

                            chart.data.datasets.forEach((dataset, i) => {
                                chart.getDatasetMeta(i).hidden = true;
                            });
                            chart.getDatasetMeta(datasetIndex).hidden = false;

                            chart.update();
                        },
                        onHover: (event, legendItem, legend) => {
                            event.native.target.style.cursor = 'pointer';
                        },
                        onLeave: (event, legendItem, legend) => {
                            event.native.target.style.cursor = '';
                        },
                    },
                    annotation: {
                        annotations: {
                            tempoMean: {
                                type: 'line',
                                yMin: stats.tempoBack.mean.toFixed(1),
                                yMax: stats.tempoBack.mean.toFixed(1), 
                                borderColor: '#FF6633',
                                borderWidth: 2,
                                label: {
                                    content: `Mean(${stats.tempoBack.mean.toFixed(1)})`, 
                                    display: true,
                                    position: 'start',
                                    color:'#FF6633',
                                    backgroundColor: 'transparent',
                                    yAdjust: 10
                                },
                                datasetIndex: 0,
                            },
                            tempoMedian: {
                                type: 'line',
                                yMin: stats.tempoBack.median.toFixed(1),
                                yMax: stats.tempoBack.median.toFixed(1), 
                                borderColor: '#FF6633',
                                borderWidth: 2,
                                label: {
                                    content: `Median(${stats.tempoBack.median.toFixed(1)})`, 
                                    display: true,
                                    position: 'start',
                                    color:'#FF6633',
                                    backgroundColor: 'transparent',
                                    yAdjust: 10
                                },
                                datasetIndex: 0,
                            },

                            positionSpineMean: {
                                type: 'line',
                                yMin: stats.topSpine.mean.toFixed(1),
                                yMax: stats.topSpine.mean.toFixed(1), 
                                borderColor: '#FFB399',
                                borderWidth: 2,
                                label: {
                                    content: `Mean(${stats.topSpine.mean.toFixed(1)})`, 
                                    display: true,
                                    position: 'start',
                                    color:'#FFB399',
                                    backgroundColor: 'transparent',
                                    yAdjust: 10
                                },
                                datasetIndex: 1,
                                display: false
                            },
                            positionSpineMedian: {
                                type: 'line',
                                yMin: stats.topSpine.median.toFixed(1),
                                yMax: stats.topSpine.median.toFixed(1), 
                                borderColor: '#FFB399',
                                borderWidth: 2,
                                label: {
                                    content: `Median(${stats.topSpine.median.toFixed(1)})`, 
                                    display: true,
                                    position: 'start',
                                    color:'#FFB399',
                                    backgroundColor: 'transparent',
                                    yAdjust: 10
                                },
                                datasetIndex: 1,
                                display: false
                            },

                            downInclineMean: {
                                type: 'line',
                                yMin: stats.downIncline.mean.toFixed(1),
                                yMax: stats.downIncline.mean.toFixed(1), 
                                borderColor: '#FF33FF',
                                borderWidth: 2,
                                label: {
                                    content: `Mean(${stats.downIncline.mean.toFixed(1)})`, 
                                    display: true,
                                    position: 'start',
                                    color:'#FF33FF',
                                    backgroundColor: 'transparent',
                                    yAdjust: 10
                                },
                                datasetIndex: 2,
                                display: false
                            },
                            downInclineMedian: {
                                type: 'line',
                                yMin: stats.downIncline.median.toFixed(1),
                                yMax: stats.downIncline.median.toFixed(1), 
                                borderColor: '#FF33FF',
                                borderWidth: 2,
                                label: {
                                    content: `Median(${stats.topSpine.median.toFixed(1)})`, 
                                    display: true,
                                    position: 'start',
                                    color:'#FF33FF',
                                    backgroundColor: 'transparent',
                                    yAdjust: 10
                                },
                                datasetIndex: 2,
                                display: false
                            },

                            topToImpactMean: {
                                type: 'line',
                                yMin: stats.topToImpact.mean.toFixed(1),
                                yMax: stats.topToImpact.mean.toFixed(1), 
                                borderColor: '#FFFF99',
                                borderWidth: 2,
                                label: {
                                    content: `Mean(${stats.topToImpact.mean.toFixed(1)})`, 
                                    display: true,
                                    position: 'start',
                                    color:'#FFFF99',
                                    backgroundColor: 'transparent',
                                    yAdjust: 10
                                },
                                datasetIndex: 3,
                                display: false
                            },
                            topToImpactMedian: {
                                type: 'line',
                                yMin: stats.topToImpact.median.toFixed(1),
                                yMax: stats.topToImpact.median.toFixed(1), 
                                borderColor: '#FFFF99',
                                borderWidth: 2,
                                label: {
                                    content: `Median(${stats.topToImpact.median.toFixed(1)})`, 
                                    display: true,
                                    position: 'start',
                                    color:'#FFFF99',
                                    backgroundColor: 'transparent',
                                    yAdjust: 10
                                },
                                datasetIndex: 3,
                                display: false
                            },

                            upInclineMean: {
                                type: 'line',
                                yMin: stats.upIncline.mean.toFixed(1),
                                yMax: stats.upIncline.mean.toFixed(1), 
                                borderColor: '#00B3E6',
                                borderWidth: 2,
                                label: {
                                    content: `Mean(${stats.upIncline.mean.toFixed(1)})`, 
                                    display: true,
                                    position: 'start',
                                    color:'#00B3E6',
                                    backgroundColor: 'transparent',
                                    yAdjust: 10
                                },
                                datasetIndex: 4,
                                display: false
                            },
                            upInclineMedian: {
                                type: 'line',
                                yMin: stats.upIncline.median.toFixed(1),
                                yMax: stats.upIncline.median.toFixed(1), 
                                borderColor: '#00B3E6',
                                borderWidth: 2,
                                label: {
                                    content: `Median(${stats.upIncline.median.toFixed(1)})`, 
                                    display: true,
                                    position: 'start',
                                    color:'#00B3E6',
                                    backgroundColor: 'transparent',
                                    yAdjust: 10
                                },
                                datasetIndex: 4,
                                display: false
                            },

                            impactToFinishMean: {
                                type: 'line',
                                yMin: stats.impactToFinish.mean.toFixed(1),
                                yMax: stats.impactToFinish.mean.toFixed(1), 
                                borderColor: '#E6B333',
                                borderWidth: 2,
                                label: {
                                    content: `Mean(${stats.impactToFinish.mean.toFixed(1)})`, 
                                    display: true,
                                    position: 'start',
                                    color:'#E6B333',
                                    backgroundColor: 'transparent',
                                    yAdjust: 10
                                },
                                datasetIndex: 5,
                                display: false
                            },
                            impactToFinishMedian: {
                                type: 'line',
                                yMin: stats.impactToFinish.median.toFixed(1),
                                yMax: stats.impactToFinish.median.toFixed(1), 
                                borderColor: '#E6B333',
                                borderWidth: 2,
                                label: {
                                    content: `Median(${stats.impactToFinish.median.toFixed(1)})`, 
                                    display: true,
                                    position: 'start',
                                    color:'#E6B333',
                                    backgroundColor: 'transparent',
                                    yAdjust: 10
                                },
                                datasetIndex: 5,
                                display: false
                            },

                            fixabilitySpineMean: {
                                type: 'line',
                                yMin: stats.fixabilitySpine.mean.toFixed(1),
                                yMax: stats.fixabilitySpine.mean.toFixed(1), 
                                borderColor: '#3366E6',
                                borderWidth: 2,
                                label: {
                                    content: `Mean(${stats.fixabilitySpine.mean.toFixed(1)})`, 
                                    display: true,
                                    position: 'start',
                                    color:'#3366E6',
                                    backgroundColor: 'transparent',
                                    yAdjust: 10
                                },
                                datasetIndex: 6,
                                display: false
                            },
                            fixabilitySpineMedian: {
                                type: 'line',
                                yMin: stats.fixabilitySpine.median.toFixed(1),
                                yMax: stats.fixabilitySpine.median.toFixed(1), 
                                borderColor: '#3366E6',
                                borderWidth: 2,
                                label: {
                                    content: `Median(${stats.fixabilitySpine.median.toFixed(1)})`, 
                                    display: true,
                                    position: 'start',
                                    color:'#3366E6',
                                    backgroundColor: 'transparent',
                                    yAdjust: 10
                                },
                                datasetIndex: 6,
                                display: false
                            },

                            fixabilityKneeMean: {
                                type: 'line',
                                yMin: stats.fixabilityKnee.mean.toFixed(1),
                                yMax: stats.fixabilityKnee.mean.toFixed(1), 
                                borderColor: '#99FF99',
                                borderWidth: 2,
                                label: {
                                    content: `Mean(${stats.fixabilityKnee.mean.toFixed(1)})`, 
                                    display: true,
                                    position: 'start',
                                    color:'#99FF99',
                                    backgroundColor: 'transparent',
                                    yAdjust: 10
                                },
                                datasetIndex: 7,
                                display: false
                            },
                            fixabilityKneeMedian: {
                                type: 'line',
                                yMin: stats.fixabilityKnee.median.toFixed(1),
                                yMax: stats.fixabilityKnee.median.toFixed(1), 
                                borderColor: '#99FF99',
                                borderWidth: 2,
                                label: {
                                    content: `Median(${stats.fixabilityKnee.median.toFixed(1)})`, 
                                    display: true,
                                    position: 'start',
                                    color:'#99FF99',
                                    backgroundColor: 'transparent',
                                    yAdjust: 10
                                },
                                datasetIndex: 7,
                                display: false
                            },

                            headUpDownMean: {
                                type: 'line',
                                yMin: stats.headUpDown.mean.toFixed(1),
                                yMax: stats.headUpDown.mean.toFixed(1), 
                                borderColor: '#B34D4D',
                                borderWidth: 2,
                                label: {
                                    content: `Mean(${stats.headUpDown.mean.toFixed(1)})`, 
                                    display: true,
                                    position: 'start',
                                    color:'#B34D4D',
                                    backgroundColor: 'transparent',
                                    yAdjust: 10
                                },
                                datasetIndex: 8,
                                display: false
                            },
                            headUpDownMedian: {
                                type: 'line',
                                yMin: stats.headUpDown.median.toFixed(1),
                                yMax: stats.headUpDown.median.toFixed(1), 
                                borderColor: '#B34D4D',
                                borderWidth: 2,
                                label: {
                                    content: `Median(${stats.headUpDown.median.toFixed(1)})`, 
                                    display: true,
                                    position: 'start',
                                    color:'#B34D4D',
                                    backgroundColor: 'transparent',
                                    yAdjust: 10
                                },
                                datasetIndex: 8,
                                display: false
                            },

                            headLeftRightMean: {
                                type: 'line',
                                yMin: stats.headLeftRight.mean.toFixed(1),
                                yMax: stats.headLeftRight.mean.toFixed(1), 
                                borderColor: '#80B300',
                                borderWidth: 2,
                                label: {
                                    content: `Mean(${stats.headLeftRight.mean.toFixed(1)})`, 
                                    display: true,
                                    position: 'start',
                                    color:'#80B300',
                                    backgroundColor: 'transparent',
                                    yAdjust: 10
                                },
                                datasetIndex: 9,
                                display: false
                            },
                            headLeftRightMedian: {
                                type: 'line',
                                yMin: stats.headLeftRight.median.toFixed(1),
                                yMax: stats.headLeftRight.median.toFixed(1), 
                                borderColor: '#80B300',
                                borderWidth: 2,
                                label: {
                                    content: `Median(${stats.headLeftRight.median.toFixed(1)})`, 
                                    display: true,
                                    position: 'start',
                                    color:'#80B300',
                                    backgroundColor: 'transparent',
                                    yAdjust: 10
                                },
                                datasetIndex: 9,
                                display: false
                            },
                        }
                    },
                },
                scales: {
                    y: {
                        // beginAtZero: true
                    }
                },
                
            });

        } catch (error) {
            console.error('Error fetching data: ', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        if(statistics !== null) setIsStatistics(true);
    }, [statistics]);

    return (
        <div className="statistics-page">
            <h1 style={{ color: 'white' }}>스윙 데이터 통계</h1>
            {isStatistics && 
                <table>
                <thead>
                    <tr>
                    <th colSpan="11">SWING SUMMARY</th>
                    </tr>

                    <tr>
                    <th colSpan="1" rowSpan="2">STATS</th>
                    <th colSpan="1">TEMPO</th>
                    <th colSpan="1">POSITION</th>
                    <th colSpan="4">SWING DIRECTION</th>
                    <th colSpan="4">FIXABILITY</th>
                    </tr>

                    <tr>
                    <th colSpan="1">BACK</th>
                    <th colSpan="1">SPINE<br></br>ANGLE</th>
                    <th colSpan="1">DOWN<br></br>INCLINE</th>
                    <th colSpan="1">TOP to IMPACT</th>
                    <th colSpan="1">UP<br></br>INCLINE</th>
                    <th colSpan="1">IMPACT to FINISH</th>
                    <th colSpan="1">SPINE</th>
                    <th colSpan="1">HEAD UP DOWN</th>
                    <th colSpan="1">HEAD LEFT RIGHT</th>
                    <th colSpan="1">KNEE (R)</th>
                    </tr>

                </thead>

                    <tr>
                    <td style={{color:'white'}}>MEAN</td>
                    <td style={{color:'cyan'}}>{statistics.tempoBack.mean.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.topSpine.mean.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.downIncline.mean.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.topToImpact.mean.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.upIncline.mean.toFixed(2) + '°'}</td>
                    <td style={{color:'cyan'}}>{statistics.impactToFinish.mean.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.fixabilitySpine.mean.toFixed(2) + '°'}</td>
                    <td style={{color:'cyan'}}>{statistics.headUpDown.mean.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.headLeftRight.mean.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.fixabilityKnee.mean.toFixed(2)}</td>
                    </tr>
                    <tr>
                    <td style={{color:'white'}}>MIN</td>
                    <td style={{color:'cyan'}}>{statistics.tempoBack.minmax.min.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.topSpine.minmax.min.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.downIncline.minmax.min.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.topToImpact.minmax.min.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.upIncline.minmax.min.toFixed(2) + '°'}</td>
                    <td style={{color:'cyan'}}>{statistics.impactToFinish.minmax.min.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.fixabilitySpine.minmax.min.toFixed(2) + '°'}</td>
                    <td style={{color:'cyan'}}>{statistics.headUpDown.minmax.min.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.headLeftRight.minmax.min.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.fixabilityKnee.minmax.min.toFixed(2)}</td>
                    </tr>
                    <tr>
                    <td style={{color:'white'}}>MAX</td>
                    <td style={{color:'cyan'}}>{statistics.tempoBack.minmax.max.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.topSpine.minmax.max.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.downIncline.minmax.max.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.topToImpact.minmax.max.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.upIncline.minmax.max.toFixed(2) + '°'}</td>
                    <td style={{color:'cyan'}}>{statistics.impactToFinish.minmax.max.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.fixabilitySpine.minmax.max.toFixed(2) + '°'}</td>
                    <td style={{color:'cyan'}}>{statistics.headUpDown.minmax.max.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.headLeftRight.minmax.max.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.fixabilityKnee.minmax.max.toFixed(2)}</td>
                    </tr>
                    <tr>
                    <td style={{color:'white'}}>Q25</td>
                    <td style={{color:'cyan'}}>{statistics.tempoBack.quartiles.q1.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.topSpine.quartiles.q1.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.downIncline.quartiles.q1.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.topToImpact.quartiles.q1.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.upIncline.quartiles.q1.toFixed(2) + '°'}</td>
                    <td style={{color:'cyan'}}>{statistics.impactToFinish.quartiles.q1.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.fixabilitySpine.quartiles.q1.toFixed(2) + '°'}</td>
                    <td style={{color:'cyan'}}>{statistics.headUpDown.quartiles.q1.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.headLeftRight.quartiles.q1.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.fixabilityKnee.quartiles.q1.toFixed(2)}</td>
                    </tr>
                    <tr>
                    <td style={{color:'white'}}>Q50</td>
                    <td style={{color:'cyan'}}>{statistics.tempoBack.quartiles.q2.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.topSpine.quartiles.q2.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.downIncline.quartiles.q2.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.topToImpact.quartiles.q2.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.upIncline.quartiles.q2.toFixed(2) + '°'}</td>
                    <td style={{color:'cyan'}}>{statistics.impactToFinish.quartiles.q2.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.fixabilitySpine.quartiles.q2.toFixed(2) + '°'}</td>
                    <td style={{color:'cyan'}}>{statistics.headUpDown.quartiles.q2.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.headLeftRight.quartiles.q2.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.fixabilityKnee.quartiles.q2.toFixed(2)}</td>
                    </tr>
                    <tr>
                    <td style={{color:'white'}}>Q75</td>
                    <td style={{color:'cyan'}}>{statistics.tempoBack.quartiles.q3.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.topSpine.quartiles.q3.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.downIncline.quartiles.q3.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.topToImpact.quartiles.q3.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.upIncline.quartiles.q3.toFixed(2) + '°'}</td>
                    <td style={{color:'cyan'}}>{statistics.impactToFinish.quartiles.q3.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.fixabilitySpine.quartiles.q3.toFixed(2) + '°'}</td>
                    <td style={{color:'cyan'}}>{statistics.headUpDown.quartiles.q3.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.headLeftRight.quartiles.q3.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.fixabilityKnee.quartiles.q3.toFixed(2)}</td>
                    </tr>
                    <tr>
                    <td style={{color:'white'}}>STD</td>
                    <td style={{color:'cyan'}}>{statistics.tempoBack.standardDeviation.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.topSpine.standardDeviation.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.downIncline.standardDeviation.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.topToImpact.standardDeviation.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.upIncline.standardDeviation.toFixed(2) + '°'}</td>
                    <td style={{color:'cyan'}}>{statistics.impactToFinish.standardDeviation.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.fixabilitySpine.standardDeviation.toFixed(2) + '°'}</td>
                    <td style={{color:'cyan'}}>{statistics.headUpDown.standardDeviation.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.headLeftRight.standardDeviation.toFixed(2)}</td>
                    <td style={{color:'cyan'}}>{statistics.fixabilityKnee.standardDeviation.toFixed(2)}</td>
                    </tr>
                </table>
            }
            <Line options={chartOptions} data={chartData} />

            {/* <div>
                {Object.keys(statistics).map((key) => (
                    <div key={key}>
                        <h2>{key}</h2>
                        <p style={{ color: 'white' }}>Median: {statistics[key].median}</p>
                        <p style={{ color: 'white' }}>Mean: {statistics[key].mean}</p>
                        <p style={{ color: 'white' }}>Variance: {statistics[key].variance}</p>
                        <p style={{ color: 'white' }}>StandardDeviation: {statistics[key].standardDeviation}</p>
                    </div>
                ))}
            </div> */}
        </div>
    );
}

export default Statistics;
