import React, { useState, useEffect } from 'react';

const WebcamSelector = ({ onWebcamSelect }) => {
  const [webcams, setWebcams] = useState([]);
  const [selectedWebcam, setSelectedWebcam] = useState(null);

  useEffect(() => {
    async function fetchWebcams() {
      try {
        await navigator.mediaDevices.getUserMedia({
          audio: false, 
          video: true
        });   
        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter((device) => device.kind === 'videoinput');

        setWebcams(videoDevices);
        if (videoDevices.length > 0) {
          setSelectedWebcam(videoDevices[0].deviceId);
          onWebcamSelect(videoDevices[0].deviceId);
        }
      } catch (error) {
        console.error("Error fetching webcams:", error);
      }
    }
    fetchWebcams();
  }, [onWebcamSelect]);

  return (
    <div>
      <label style = {{color : 'white'}}> Select a webcam: </label>
      <select
        onChange={(e) => {
          setSelectedWebcam(e.target.value);
          onWebcamSelect(e.target.value);
        }}
        value={selectedWebcam}
      >
        {webcams.map((webcam) => (
          <option key={webcam.deviceId} value={webcam.deviceId}>
            {webcam.label || `Webcam ${webcam.deviceId}`}
          </option>
        ))}
      </select>
    </div>
  );
};

export default WebcamSelector;
