import React from 'react';
import '../Styles/marketing.css';

const MarketingPurposeConsent = () => {
  return (
    <div className="marketing-purpose-consent-container">
      <h1>마케팅 목적의 개인정보 수집 및 이용 동의</h1>

      <table>
        <thead>
          <tr>
            <th>항목</th>
            <th>목적</th>
            <th>보유 및 이용 기간</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>서비스 이용기록 연령 및 성별</td>
            <td>인구통계학적 특성과 이용자의 관심 및 성향의 추정을 통한 맞춤형 광고에 활용</td>
            <td>동의 철회 시 맞춤형 광고 차단<br/>회원 탈퇴 시 파기</td>
          </tr>
          <tr>
            <td>아이디 이메일 휴대폰번호</td>
            <td>아이디를 이용하는 서비스의 마케팅 및 프로모션</td>
            <td>회원 탈퇴 시 파기</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MarketingPurposeConsent;
