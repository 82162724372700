import React from 'react';
import '../Styles/privacy-policy.css'; // Import the CSS file for styling

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>개인정보처리방침</h1>
      <p>템포디올㈜(이하 “회사”)는 「개인정보 보호법」에 따라 이용자의 개인정보를 보호하고 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다.</p>
      <p>개인정보 처리방침을 변경하는 경우에는 변경에 대한 사실 및 시행일 변경 전후의 내용을 홈페이지 공지사항을 통해 지속적으로 공개합니다.</p>
      <p>본 방침은 법령 및 고시 등의 변경 또는 회사의 약관 및 내부 정책에 따라 변경될 수 있으며 이를 개정하는 경우 회사는 변경사항에 대하여 서비스 화면에 게시하거나 이용자에게 고지합니다. 이용자는 개인정보의 수집 이용 제공 위탁 등과 관련한 아래 사항에 대하여 원하지 않는 경우 동의를 거부할 수 있습니다. 다만 이용자가 동의를 거부하는 경우 서비스의 전부 또는 일부를 이용할 수 없음을 알려드립니다.</p>

      <h2>1. 개인정보 수집 항목 및 목적과 보유 기간</h2>
      <p>회사는 서비스 이용에 필요한 최소한의 개인정보를 수집합니다. 이용자의 개인정보와 서비스 방문 및 서비스이용을 통해 이용자의 성향을 분석하여 맞춤형 서비스를 제공합니다.</p>
      
      <table>
        <thead>
          <tr>
            <th>분 류</th>
            <th>목 적</th>
            <th>항 목</th>
            <th>보유 및 이용기간</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td rowSpan="3">필수</td>
            <td>회원관리 및 본인확인</td>
            <td>아이디, 이름, 비밀번호</td>
            <td rowSpan="3">회원 탈퇴시부터 1년까지</td>
          </tr>
          <tr>
            <td>개인화 서비스 제공</td>
            <td>닉네임, 일반/프로, 성별, 우타/좌타, 이메일주소, 암호화된 동일인 식별정보(CI), 스윙 영상</td>
          </tr>
          <tr>
            <td>불만처리 및 민원처리, 고지사항 전달</td>
            <td>이름, 이메일주소</td>
          </tr>
          <tr>
            <td rowSpan="2">선택</td>
            <td>서비스의 마케팅 및 프로모션</td>
            <td>아이디(이메일), 휴대폰번호</td>
            <td>회원 탈퇴 시까지</td>
          </tr>
          <tr>
            <td>성향의 분석을 통한 맞춤형 광고에 활용</td>
            <td>서비스 이용기록, 생일, 연령대</td>
            <td>회원 탈퇴 시 파기</td>
          </tr>
        </tbody>
      </table>

      <p>상기 선택항목은 동의 철회 시까지 보관함.</p>
      <p>상기 보유 및 이용기간은 관련 법령에 의한 정보인 경우 아래와 같이 보관함.</p>
      <ul>
        <li>계약 또는 청약철회 등에 관한 기록 (보존이유: 전자상거래 등에서의 소비자보호에 관한 법률, 보존기간: 5년)</li>
        <li>대금결제 및 재화 등의 공급에 관한 기록 (보존이유: 전자상거래 등에서의 소비자보호에 관한 법률, 보존기간: 5년)</li>
        <li>소비자의 불만 및 분쟁처리에 관한 기록 (보존이유: 전자상거래 등에서의 소비자보호에 관한 법률, 보존기간: 3년)</li>
        <li>서비스 방문 기록 (보존이유: 통신비밀보호법, 보존기간: 3개월)</li>
        <li>세법에서 규정하는 모든 거래에 관한 장부 및 증거서류 (보존이유: 국세기본법, 보존기간: 5년)</li>
        <li>기타 관련법령에 명시된 보관기간</li>
      </ul>

      <h2>2. 개인정보의 제공과 위탁</h2>
      <p>회사는 회원의 동의가 있거나 관련법령의 규정에 의한 경우를 제외하고는 개인정보를 수집 및 이용목적으로 정한 범위 내에서 사용하며 동 범위를 초과하여 이용하거나 외부에 공개/제공하지 않습니다.</p>

      <h2>3. 개인정보의 처리위탁</h2>
      <p>가. 서비스 제공에 필수적인 업무 처리 위탁</p>
      <table>
        <thead>
          <tr>
            <th>수탁업체</th>
            <th>위탁업무</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>토스페이먼츠㈜</td>
            <td>정기결제</td>
          </tr>
          <tr>
            <td>Amazon</td>
            <td>서버 이용 및 관리</td>
          </tr>
          <tr>
            <td>네이버㈜</td>
            <td>소셜로그인</td>
          </tr>
          <tr>
            <td>카카오㈜</td>
            <td>소셜로그인</td>
          </tr>
        </tbody>
      </table>

      <h2>4. 개인정보 파기 절차 및 방법</h2>
      <p>회사는 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. 파기절차 및 방법은 다음과 같습니다.</p>
      <p>가. 파기 및 분리보관 절차 <br/>회원이 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다.</p>
      <p>나. 파기방법: <br/>종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다. 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</p>

      <h2>5. 회원 및 법정대리인의 권리와 그 행사방법</h2>
      <p>회사는 아동의 개인정보를 보호하기 위하여 법정대리인의 동의가 필요한 만 14세 미만 아동의 회원가입을 받지 않습니다.</p>

      <h2>6. 개인정보의 자동수집장치의 설치/운영 및 그 거부에 관한 사항</h2>
      <p>회사는 회원에게 개별적으로 특화된 맞춤서비스를 제공하기 위해서 회원의 정보를 수시로 저장하고 찾아내는 '쿠키(cookie)'를 사용합니다. 쿠키는 당사의 웹사이트를 운영하는데 이용되는 서버가 사용자의 브라우저에 보내는 소량의 텍스트 파일로서 사용자의 컴퓨터 하드디스크에 저장되며 사용자의 컴퓨터는 식별하지만 사용자를 개인적으로 식별하지는 않습니다.</p>
      <p>가. 쿠키 등 사용목적 <br/>회사는 다음과 같은 목적을 위해 쿠키를 사용합니다.</p>
      <ul>
        <li>이용자가 설정한 서비스 이용 환경을 유지하여 편리한 인터넷 서비스 제공</li>
        <li>이용자들의 방문 및 이용 행태 등을 분석하여 최적화된 서비스 제공</li>
      </ul>
      <p>나. 쿠키설정거부방법 <br/>회원은 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 회원은 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나 쿠키가 저장될 때마다 확인을 거치거나 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. 그러나 회사 홈페이지에 접속하여 서비스를 이용하기 위해서는 쿠키를 허용하여야 하며 이를 거부할 경우 로그인이 필요한 회사의 서비스의 이용이 어려울 수 있습니다.</p>
      <ul>
        <li>설정방법의 예</li>
        <li>Microsoft Edge의 경우 : 웹 브라우저 우측의 설정 메뉴 - 쿠키 및 사이트 권한 - 쿠키 및 사이트 데이터 관리 및 삭제</li>
        <li>Chrome의 경우: 웹 브라우저 우측의 설정 메뉴 - 개인 정보 보호 및 보안 - 서드 파티 쿠키</li>
      </ul>

      <h2>7. 개인정보 안전성 확보조치</h2>
      <p>회원의 개인정보는 기본적으로 회원의 아이디와 비밀번호에 의하여 보호되며 회사는 회원의 개인정보를 처리함에 있어 개인정보가 유출 변조 또는 훼손되지 않도록 안전성을 확보하기 위하여 다음과 같은 기술적/관리적 대책을 마련하고 있습니다.</p>
      <h3>가. 기술적 대책</h3>
      <ul>
        <li>회원 계정의 비밀번호는 암호화되어 저장되므로 본인만이 알 수 있습니다. 따라서 계정 로그인이 필요한 개인정보의 확인 및 수정은 해당 아이디와 비밀번호를 알고 있는 본인 만이 가능합니다.</li>
        <li>회사는 개인정보의 훼손에 대비하여 자료를 수시로 백업하고 있고 최신 백신프로그램을 이용하여 컴퓨터 바이러스 등에 의해 회원들의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 있습니다.</li>
        <li>회사는 네트워크상에서 개인정보 및 인증정보를 송·수신하는 경우 안전한 암호 알고리즘으로 이를 암호화하고 있습니다.</li>
        <li>회사는 해킹 등에 의한 정보의 유출을 방지하기 위해 침입차단시스템(방화벽)을 이용하여 외부로부터의 무단 접근을 통제하고 있으며 기타 시스템적인 보안성을 확보하기 위해 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.</li>
      </ul>
      <h3>나. 관리적 대책</h3>
      <ul>
        <li>회사는 회원 본인의 비밀번호 재발급 요청 등에 의해 개인정보를 다룰 때 가능한 최선의 방법으로 본인임을 확인하고 안전하게 정보가 처리될 수 있도록 최선을 다합니다.</li>
        <li>회사는 개인정보에 대한 접근권한을 개인정보보호책임자 등 개인정보관리업무를 수행하는 자 기타 업무상 개인정보의 처리가 불가피한 자로 제한하며 개인정보를 처리하는 직원에 대한 정기적인 교육을 통하여 개인정보보호 의무의 준수를 항상 강조하고 있습니다.</li>
      </ul>
      <p>위와 같은 회사의 노력 이외에 회원은 아이디와 비밀번호 등 개인정보가 인터넷 상에 노출되거나 타인에게 유출되지 않도록 주의하여야 합니다. 회원 본인의 부주의나 관리소홀로 아이디와 비밀번호 등 개인정보가 유출되었다면 이에 대해서 회사는 책임을 지지 않습니다. 따라서 회원의 아이디와 비밀번호는 반드시 본인만 사용하시고 비밀번호를 자주 변경해주시는 것이 좋으며 영문자 숫자 특수문자를 조합하여 타인이 유추하기 어려운 비밀번호를 사용하는 것이 좋습니다. 또한 서비스의 이용을 마친 후에는 항상 로그아웃 하여 웹 브라우저를 종료하는 것이 좋습니다. 특히 다른 사람과 컴퓨터를 공유하거나 공공장소에서 이용하는 경우에 개인정보보호를 위해서는 이와 같은 절차가 더욱 필요합니다.</p>

      <h2>8. 개인정보 보호책임자 및 담당자의 연락처</h2>
      <p>회사는 회원의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 개인정보 보호책임자를 지정하고 있습니다. 회사의 서비스를 이용하시며 발생하는 모든 개인정보보호 관련 민원은 아래 개인정보 보호책임자 및 담당 부서로 연락해 주시기 바랍니다. 회사는 회원의 문의사항에 대해 신속하게 충분한 답변을 드릴 것입니다.</p>
      <p>개인정보 보호책임자 및 담당자</p>
      <ul>
        <li>소 속 : 템포디올㈜</li>
        <li>책임자 : 김명진 대표</li>
        <li>전 화 : 070-7576-4722</li>
        <li>이메일 : maxkim@tempodiall.com</li>
      </ul>

      <h2>9. 개인정보관련 신고 및 분쟁조정</h2>
      <p>개인정보침해에 대한 신고 또는 상담이 필요하신 경우에는 한국인터넷진흥원(KISA) 개인정보침해신고센터로 문의하시기 바랍니다. 또한 귀하가 개인정보침해를 통한 금전적 정신적 피해를 입으신 경우에는 개인정보분쟁조정위원회에 피해구제를 신청하실 수 있습니다.</p>
      <ul className="social-media-links">
        <li>개인정보침해신고센터 (<a href="http://privacy.kisa.or.kr/" target="_blank" rel="noopener noreferrer">privacy.kisa.or.kr</a> / 국번 없이 118)</li>
        <li>개인정보분쟁조정위원회 (<a href="http://privacy.go.kr/" target="_blank" rel="noopener noreferrer">privacy.go.kr</a> / 1833-6972)</li>
        <li>대검찰청 사이버수사과 (<a href="http://spo.go.kr/" target="_blank" rel="noopener noreferrer">spo.go.kr</a> / 국번 없이 1301)</li>
        <li>경찰청 사이버수사국 (<a href="http://ecrm.police.go.kr/" target="_blank" rel="noopener noreferrer">ecrm.police.go.kr</a> / 국번 없이 182)</li>
      </ul>

      <h2>10. 고지의 의무</h2>
      <p>법령 정책 또는 보안기술 등의 변경에 따라 현 개인정보처리방침의 내용 추가 삭제 및 수정이 있을 시에는 개정되는 개인정보처리방침을 시행하기 최소 7일전에 홈페이지의 '공지사항'을 통해 고지할 것입니다.</p>

      <p>부 칙</p>
      <p>공고일자: 2024년 07월 01일</p>
      <p>시행일자: 2024년 07월 01일</p>
    </div>
  );
};

export default PrivacyPolicy;
