import userEvent from "@testing-library/user-event";
import React, { createContext, useContext, useState, useEffect } from "react";
import io from "socket.io-client";

const SocketContext = createContext();

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const [serialKey, setSerialKey] = useState(null);
  const [roomId, setRoomId] = useState(null);
  const [loginId, setLoginId] = useState(null);

  const updateRoomId = (newRoomId) => {
    setRoomId(newRoomId); // Update state
    sessionStorage.setItem('roomId', newRoomId);
  };

  const updateLoginId = (_loginId) => {
    setLoginId(_loginId); // Update state
    sessionStorage.setItem('loginId', _loginId);
  };


  useEffect(() => {
    const savedRoomId = sessionStorage.getItem('roomId');
    const savedLoginId = sessionStorage.getItem('loginId');
    const savedSerialKey = sessionStorage.getItem('serialKey');
    
    if (savedRoomId) setRoomId(savedRoomId);
    if (savedLoginId) setLoginId(savedLoginId);
    if (savedSerialKey) setSerialKey(savedSerialKey);
  
  }, []); 


//   useEffect(() => {         //// 타석에서 로그인 하는 경우
//     if(serialKey !== null && loginId !== null){
//         console.log("serialKey")
//         const socketurl = `http://117.16.243.158:9005/?roomId=${serialKey}&peerId=${loginId}&isFromWeb=${true}`
//         const newSocket = io(socketurl);
//         setSocket(newSocket);

//         sessionStorage.setItem("roomId", serialKey) 

//         newSocket.emit("KM:login", { id: loginId, pw: sessionStorage.getItem("password") });
//     }
//   }, [serialKey, loginId]);

useEffect(() => {
  
    if (serialKey && loginId) {
      const socketUrl = `http://117.16.243.158:9005/?roomId=${serialKey}&peerId=${loginId}&isFromWeb=true`;
      const newSocket = io(socketUrl);
      setSocket(newSocket);
  
      newSocket.emit("KM:login", { id: loginId, pw: sessionStorage.getItem('password') });

      return () => {
        newSocket.close();
      };
    }
  }, [serialKey, roomId, loginId]); 

  useEffect(() => {         //// qr로 로그인 하는 경우
    if(roomId !== null && loginId !== null){
        console.log("room")
        const socketurl = `http://117.16.243.158:9005/?roomId=${roomId}&peerId=${loginId}&isFromWeb=${true}`
        const newSocket = io(socketurl);
        setSocket(newSocket);

        newSocket.emit("KM:login", { id: loginId, pw: sessionStorage.getItem('password') });
    }
  }, [roomId]);

//   useEffect(() => { // upload Event
//     if(socket){
//         // socket.on("KM:upload", function (data) {
//         //     console.log(data)
//         //     window.location.reload();
//         // });
//     }
//   }, [socket])


  useEffect(() => { // 타석에서 로그인 하는 경우 serialKey 가져오기
    const fetchSocketInfo = async () => {
      try {
        const response = await fetch(`http://localhost:5001/serialKey`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });
        if(response.ok) {
            // 성공적으로 응답을 받았을 경우
            const data = await response.json(); // 응답 데이터 파싱
            if(data.serialKey)
                setSerialKey(data.serialKey);
                //sessionStorage.setItem('roomId', data.serialKey); // 이거 설정하면 hijacking걸림...
        }

      } catch (error) {
        console.log("Error fetching socket information:", error);
      }
    };

    fetchSocketInfo();
    return () => {
      if (socket) {
        socket.close();
      }
    };
  }, []);

  return (
    <SocketContext.Provider value={{socket, updateRoomId, updateLoginId}}>{children}</SocketContext.Provider>
  );
};