import React from 'react';
import { Link } from 'react-router-dom';
import '../Styles/footer.css'; // Importing the CSS file for styling

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-links">
        <Link to="/aboutkm">About KeepMotion</Link>
        <Link to="/privacypolicy">개인정보 처리방침</Link>
        <Link to="/service-terms">서비스 이용약관</Link>
        <Link to="/personal-info-agreement">개인정보 수집 및 이용동의</Link>
        <Link to="/marketing">마케팅 목적의 개인정보 수집 및 이용동의</Link>
        <Link to="/qna">QnA</Link>
        <Link to="/notice">공지사항</Link>
      </div>
      <div className="footer-sections">
        <div className="section1 footer-content">
          <p>템포디올(주) 대표이사 : 김명진</p>
          <p>경기도 성남시 대완판교로645번길12</p>
          <p>사업자 등록번호 : 277-81-01501</p>
          <p>통신판매업신고번호 : 제2020-화성동탄-1148호</p>
        </div>
        <div className="section2 footer-content">
          <a href="http://www.tempodiall.com/" target="_blank" rel="noopener noreferrer">Tempo Di All</a>
          <p>고객센터 : 070-7576-4722</p>
          <p>대표메일 : maxkim@tempodiall.com</p>
          <p>호스팅 서비스 사업자 : AWS코리아</p>
        </div>
        {/* <div className="section3 footer-content">
          <p>KEEP MOTION이 무엇인가요?</p>
          <p>KEEP MOTION은 멤버쉽에게 제공하는 AI기반의 골프스윙 모션을 분석하는 멀티 코칭 플렛폼 입니다.
          실/내외 언제 어디선든 나의 스윙 영상을 업로드하면 신체 모션 데이터를 확인할 수 있으며, 프로와 나와의 스윙을 비교 분석을 할 수 있습니다.
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default Footer;
