import React, { useEffect, useRef, useState } from "react";
import { useToken } from './TokenContext';

const Canvas1 = React.memo(({ coordinates, frameRate, onCurrentFrameChange, selectedGraph, pathChecked, currentFrame, videolength, videoSize, 
  showShoulders, showHips, showKnees, showSpines, isDrawingMode, selectedstep, jsonlength, shape}) => {
  const { jsonall } = useToken();
  const [isDrawing, setIsDrawing] = useState(false); // 그리기 상태 표시
  const [startPoint, setStartPoint] = useState(null); // 첫 번째 클릭 위치 저장
  const [selectedShape, setSelectedShape] = useState(""); // 선택된 도형을 저장하는 상태
  const canvasRef = useRef(null);
  const linecanvasRef = useRef(null);
  const playerRef = useRef(null);
  const width = parseInt(videoSize.width.replace(/[^\d]/g, ''), 10);
  const height = parseInt(videoSize.height.replace(/[^\d]/g, ''), 10);
  console.log(shape)
  let adjustframeRate = frameRate
  if(currentFrame > videolength){currentFrame -= 1;}
  const remToPixels = (rem) => {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  };
  const topOffset = remToPixels(8)
  const leftOffset = remToPixels(4);

  // const drawLineOnCanvas = (points, context) => {
  //   context.clearRect(0, 0, context.canvas.width, context.canvas.height);
  //   if (points.length === 2) {
  //     context.beginPath();
  //     context.moveTo(points[0].x, points[0].y);
  //     context.lineTo(points[1].x, points[1].y);
  //     context.strokeStyle = 'white';
  //     context.lineWidth = 3;
  //     context.stroke();
  //   }
  // }

  // const handleCanvasClick = (event) => {
  //   if (!isDrawingMode) return;

  //   const rect = linecanvasRef.current.getBoundingClientRect();
  //   const x = event.clientX - rect.left;
  //   const y = event.clientY - rect.top;

  //   setPoints(prevPoints => [...prevPoints, { x, y }]);
  //   if (points.length >= 1) {
  //     drawLineOnCanvas([...points, { x, y }], linecanvasRef.current.getContext('2d'));
  //   }
  // };

  const drawShape = (x, y, shape) => {
    const context = canvasRef.current.getContext("2d");
    context.fillStyle = "blue";

    switch (shape) {
      case "rectangle":
        context.fillRect(x - 50, y - 25, 100, 50); // 네모 그리기
        break;
      case "circle":
        context.beginPath();
        context.arc(x, y, 30, 0, 2 * Math.PI); // 원 그리기
        context.fill();
        break;
      case "triangle":
        context.beginPath();
        context.moveTo(x, y - 40); // 삼각형 꼭대기
        context.lineTo(x + 40, y + 30);
        context.lineTo(x - 40, y + 30);
        context.closePath();
        context.fill(); // 삼각형 그리기
        break;
      default:
        return;
    }
  };

  const drawLineOnCanvas = (start, end, context) => {
    context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    if (start && end) {
      context.beginPath();
      context.moveTo(start.x, start.y);
      context.lineTo(end.x, end.y);
      context.strokeStyle = 'white';
      context.lineWidth = 3;
      context.stroke();
    }
  };

  const handleCanvasClick = (event) => {
    if (!isDrawingMode || !shape) return;

    const rect = linecanvasRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    if (!isDrawing) {
      setStartPoint({ x, y });
      setIsDrawing(true);
    } else {
      setIsDrawing(false);
    }
  };

  const handleMouseMove = (event) => {
    if (!isDrawingMode || !isDrawing) return;

    const rect = linecanvasRef.current.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    const context = linecanvasRef.current.getContext('2d');
    drawLineOnCanvas(startPoint, { x, y }, context);
  };


//  useEffect(() => {
//     const canvas = linecanvasRef.current;
//     const context = canvas.getContext('2d');

//     if (!isDrawingMode && !lineDrawn) {
//       context.clearRect(0, 0, width, height);
//     }
//     drawLineOnCanvas(points, context);
//   }, [points, isDrawingMode, lineDrawn, width, height]);

useEffect(() => {
  const canvas = linecanvasRef.current;
  const context = canvas.getContext('2d');

  if (isDrawing) {
    document.addEventListener('mousemove', handleMouseMove);
  } else {
    document.removeEventListener('mousemove', handleMouseMove);
  }

  return () => {
    document.removeEventListener('mousemove', handleMouseMove);
  };
}, [isDrawing, isDrawingMode, startPoint]);

  useEffect(() => {
    if (!isDrawingMode) {
      setStartPoint(null);
      setIsDrawing(false);
      const context = linecanvasRef.current.getContext('2d');
      context.clearRect(0, 0, context.canvas.width, context.canvas.height);
    }
  }, [isDrawingMode]);


  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");

    const updateCanvas = (currentTime) => {
      let currentFrame = Math.floor(currentTime * adjustframeRate);
      onCurrentFrameChange(currentFrame); // 새로운 currentFrame을 부모 컴포넌트로 전달
      context.clearRect(0, 0, canvas.width, canvas.height);
      //currentFrame = Math.min(currentFrame, jsonlength-1);
      if (pathChecked) {
        drawPath(context, currentTime);
      }
      else{
        drawRedDots(context, currentTime);
      }
      drawLine(context, currentFrame);
      if (selectedstep === 'top') {
        drawElbowDot(context, coordinates);
      }
      if(selectedstep === 'address'){
        drawSkeleton(context, coordinates)
      }
    };

    const drawSkeleton = (ctx, coordinates) => {

      const skeletonLines = [
        { start: 'left_shoulder', end: 'right_shoulder' },
        // { start: 'left_shoulder', end: 'left_elbow' },
        // { start: 'left_elbow', end: 'left_wrist' },
        { start: 'right_shoulder', end: 'right_elbow' },
        { start: 'right_elbow', end: 'right_wrist' },
        // { start: 'left_hip', end: 'left_knee' },
        // { start: 'left_knee', end: 'left_heel' },
        { start: 'right_hip', end: 'right_knee' },
        { start: 'right_knee', end: 'right_heel' },
      ];
      const shoulderMidpoint = {
        x: (coordinates[0].data.left_shoulder.x + coordinates[0].data.right_shoulder.x) / 2,
        y: (coordinates[0].data.left_shoulder.y + coordinates[0].data.right_shoulder.y) / 2,
      };

      if(!coordinates[0].data) return;
      const {
        x: videoX,
        y: videoY,
        width: videoWidth,
        height: videoHeight,
      } = player.getBoundingClientRect();
    
      const { width: canvasWidth, height: canvasHeight } =
        canvas.getBoundingClientRect();
      const scaleX = canvasWidth / videoWidth;
      const scaleY = canvasHeight / videoHeight;

      skeletonLines.forEach(({ start, end }) => {
        const startJoint = coordinates[0].data[start];
        const CanvasX = (startJoint.x * width * scaleX + videoX) - leftOffset;
        const CanvasY = (startJoint.y * height * scaleY + videoY) - topOffset;

        const endJoint = coordinates[0].data[end];
        const endCanvasX = (endJoint.x * width * scaleX + videoX) - leftOffset;
        const endCanvasY = (endJoint.y * height * scaleY + videoY) - topOffset;
        if (startJoint && endJoint) {
          ctx.beginPath();
          ctx.moveTo((coordinates[0].data['ears'].x * width * scaleX + videoX) - leftOffset, (coordinates[0].data['ears'].y * height * scaleY + videoY) - topOffset);
          ctx.lineTo((shoulderMidpoint.x * width * scaleX + videoX) - leftOffset, (shoulderMidpoint.y * height * scaleY + videoY) - topOffset);
          ctx.moveTo(CanvasX, CanvasY);
          ctx.lineTo(endCanvasX, endCanvasY);
          ctx.strokeStyle = 'white';
          ctx.lineWidth = 2;
          ctx.stroke();
        }
      });
    };
    const drawElbowDot = (ctx) => {
    
      if (!coordinates || currentFrame < 0 || currentFrame >= coordinates.length || !coordinates[currentFrame].data.left_elbow) {
        return;
      }
      const {
        x: videoX,
        y: videoY,
        width: videoWidth,
        height: videoHeight,
      } = player.getBoundingClientRect();
    
      const { width: canvasWidth, height: canvasHeight } =
        canvas.getBoundingClientRect();
      const scaleX = canvasWidth / videoWidth;
      const scaleY = canvasHeight / videoHeight;
    
      const topFrame = jsonall.data.golf_step.top;
      if (!jsonall || !jsonall.data.golf_step.top) {
        return;
      }
      const leftElbow = coordinates[topFrame].data.left_elbow;
      const canvasX = (leftElbow.x * width * scaleX + videoX) - leftOffset;
      const canvasY = (leftElbow.y * height * scaleY + videoY) - topOffset;
    
      ctx.beginPath();
      ctx.arc(canvasX, canvasY, 5, 0, 2 * Math.PI);
      ctx.fillStyle = "yellow";
      ctx.fill();
    };

    const drawLine = (ctx, frameIndex) => {
      const frameData = coordinates[frameIndex];
      if (!frameData) return;
      const {
        x: videoX,
        y: videoY,
        width: videoWidth,
        height: videoHeight,
      } = player.getBoundingClientRect();
    
      const { width: canvasWidth, height: canvasHeight } =
        canvas.getBoundingClientRect();
      const scaleX = canvasWidth / videoWidth;
      const scaleY = canvasHeight / videoHeight;

      const shoulderMidpoint = {
        x: (frameData.data.left_shoulder.x + frameData.data.right_shoulder.x) / 2,
        y: (frameData.data.left_shoulder.y + frameData.data.right_shoulder.y) / 2,
      };
      const hipMidpoint = {
        x: (frameData.data.left_hip.x + frameData.data.right_hip.x) / 2,
        y: (frameData.data.left_hip.y + frameData.data.right_hip.y) / 2,
      };

      const leftShoulder = frameData.data.left_shoulder;
      const rightShoulder = frameData.data.right_shoulder;
      const leftHip = frameData.data.left_hip;
      const rightHip = frameData.data.right_hip;
      const leftKnee = frameData.data.left_knee;
      const rightKnee = frameData.data.right_knee;

      if(showShoulders){
        if (leftShoulder && rightShoulder) {
          ctx.beginPath();
          ctx.moveTo((leftShoulder.x * width * scaleX + videoX) - leftOffset, (leftShoulder.y * height * scaleY + videoY) - topOffset);
          ctx.lineTo((rightShoulder.x * width * scaleX + videoX) - leftOffset, (rightShoulder.y * height * scaleY + videoY) - topOffset);
          ctx.strokeStyle = 'red';
          ctx.lineWidth = 2;
          ctx.stroke();
        }
      }
      if(showHips){
        if (leftHip && rightHip) {
          ctx.beginPath();
          ctx.moveTo((leftHip.x * width * scaleX + videoX) - leftOffset, (leftHip.y * height * scaleY + videoY) - topOffset);
          ctx.lineTo((rightHip.x * width * scaleX + videoX) - leftOffset, (rightHip.y * height * scaleY + videoY) - topOffset);
          ctx.strokeStyle = 'red';
          ctx.lineWidth = 2;
          ctx.stroke();
        }
      }
      if(showKnees){
        if (leftKnee && rightKnee) {
          ctx.beginPath();
          ctx.moveTo((leftKnee.x * width * scaleX + videoX) - leftOffset, (leftKnee.y * height * scaleY + videoY) - topOffset);
          ctx.lineTo((rightKnee.x * width * scaleX + videoX) - leftOffset, (rightKnee.y * height * scaleY + videoY) - topOffset);
          ctx.strokeStyle = 'red';
          ctx.lineWidth = 2;
          ctx.stroke();
        }
      }
      if(showSpines){
          if(leftShoulder && rightShoulder && leftHip && rightHip && shoulderMidpoint && hipMidpoint){
          ctx.beginPath();
          ctx.moveTo((leftShoulder.x * width * scaleX + videoX) - leftOffset, (leftShoulder.y * height * scaleY + videoY) - topOffset);
          ctx.lineTo((rightShoulder.x * width * scaleX + videoX) - leftOffset, (rightShoulder.y * height * scaleY + videoY) - topOffset);
          ctx.strokeStyle = 'red';
          ctx.lineWidth = 2;
          ctx.stroke();

          ctx.beginPath();
          ctx.moveTo((leftHip.x * width * scaleX + videoX) - leftOffset, (leftHip.y * height * scaleY + videoY) - topOffset);
          ctx.lineTo((rightHip.x * width * scaleX + videoX) - leftOffset, (rightHip.y * height * scaleY + videoY) - topOffset);
          ctx.strokeStyle = 'red';
          ctx.lineWidth = 2;
          ctx.stroke();

          ctx.beginPath();
          ctx.moveTo((shoulderMidpoint.x * width * scaleX + videoX) - leftOffset, (shoulderMidpoint.y * height * scaleY + videoY) - topOffset);
          ctx.lineTo((hipMidpoint.x * width * scaleX + videoX) - leftOffset, (hipMidpoint.y * height * scaleY + videoY) - topOffset);
          ctx.strokeStyle = 'red';
          ctx.lineWidth = 2; // 선의 두께를 2로 설정
          ctx.stroke();
        }
      }
    };

    const drawPath = (ctx, currentTime) => {

      const player = playerRef.current;
      if (!player || isNaN(currentTime) || !isFinite(currentTime)) {
        return;
      }
      const {
        x: videoX,
        y: videoY,
        width: videoWidth,
        height: videoHeight,
      } = player.getBoundingClientRect();
    
      const { width: canvasWidth, height: canvasHeight } =
        canvas.getBoundingClientRect();
      const scaleX = canvasWidth / videoWidth;
      const scaleY = canvasHeight / videoHeight;
    
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      let paths = {};
  
        for (let i = 0; i <= currentTime * adjustframeRate; i++) {
          const selectedCoordinates = coordinates.find((point) => point.frame === i);
  
          if (selectedCoordinates) {
            selectedGraph.forEach((graphName) => {
              const xy = graphName.split(".")[0];
              const dx = selectedCoordinates.data[xy].x;
              const dy = selectedCoordinates.data[xy].y;
  
              const canvasX = (dx * width * scaleX + videoX) - leftOffset;
              const canvasY = (dy * height * scaleY + videoY) - topOffset;
  
              if (!paths[graphName]) {
                paths[graphName] = []; 
              }
              paths[graphName].push({ x: canvasX, y: canvasY });
            });
          }
        }

      selectedGraph.forEach((graphName) => {
        ctx.beginPath();
        const pathCoordinates = paths[graphName];
        if (pathCoordinates && pathCoordinates.length > 0) {
          
          ctx.moveTo(pathCoordinates[0].x, pathCoordinates[0].y);
          for (let i = 1; i < pathCoordinates.length; i++) {
            ctx.lineTo(pathCoordinates[i].x, pathCoordinates[i].y);
          }
          if (graphName.includes('left') || graphName.includes('ears')) {
            ctx.strokeStyle = "red";
          } else if (graphName.includes('right') || graphName.includes('ears')) {
            ctx.strokeStyle = "yellow";
          }
          ctx.lineWidth = 3;
          ctx.stroke();
        }
      });
      ctx.closePath();
    };

    const drawRedDots = (ctx, currentTime) => {
      const player = playerRef.current;
      if (!player || isNaN(currentTime) || !isFinite(currentTime)) {
        return;
      }

        const {
          x: videoX,
          y: videoY,
          width: videoWidth,
          height: videoHeight,
        } = player.getBoundingClientRect();

        const { width: canvasWidth, height: canvasHeight } =
          canvas.getBoundingClientRect();
        const scaleX = canvasWidth / videoWidth;
        const scaleY = canvasHeight / videoHeight;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        coordinates.forEach((point) => {
          const { frame, data } = point;
          
          selectedGraph.forEach((graphName)=>{
            var xy = graphName.split('.')[0]
            var dx = data[xy].x
            var dy = data[xy].y
            if (adjustframeRate === frame) {
                if(graphName.includes('left') || graphName.includes('ears')){
                const radius = 5;
                const canvasX = (dx * width * scaleX + videoX) - leftOffset;
                const canvasY = (dy * height * scaleY + videoY) - topOffset;
                ctx.beginPath();
                ctx.arc(canvasX, canvasY, radius, 0, 2 * Math.PI);
                ctx.fillStyle = "red";
                ctx.fill();
                }else if(graphName.includes('right') || graphName.includes('ears')){
                const radius = 5;
                const canvasX = (dx * width * scaleX + videoX) - leftOffset;
                const canvasY = (dy * height * scaleY + videoY) - topOffset;
                ctx.beginPath();
                ctx.arc(canvasX, canvasY, radius, 0, 2 * Math.PI);
                ctx.fillStyle = "yellow";
                ctx.fill();
                }
            }
          });
        });
    };


    const player = document.querySelector(".react-player video");
    playerRef.current = player;

    if (player) {
      player.addEventListener("timeupdate", (event) => {
        var currentTime = event.target.currentTime;
        if (isNaN(currentTime) || !isFinite(currentTime)) {
          return;
        }
        updateCanvas(currentTime);
      });
    }

    const animateCanvas = (timestamp) => {
      if (timestamp - previousFrameTime >= 1000 / adjustframeRate) {
        const player = playerRef.current;
        if (player && player.currentTime) {
          const currentTime = player.currentTime;
          updateCanvas(currentTime);
        }
        previousFrameTime = timestamp;
      }
      requestId = requestAnimationFrame(animateCanvas);
    }; 

    let requestId;
    let previousFrameTime = 0;
    requestId = requestAnimationFrame(animateCanvas);
   
    return () => {
      if (player) {
        player.removeEventListener("timeupdate", updateCanvas);
      }
      cancelAnimationFrame(requestId);
    };
  }, [coordinates, frameRate , currentFrame, selectedGraph, pathChecked, showShoulders, showHips, showKnees, showSpines, selectedstep]);


  return (
    <div style={{ position: "fixed",top: '8rem', left: '4rem' }}>
      <canvas
        ref={canvasRef}
        width= {width}
        height= {height}
        style={{ zIndex: '20' }}
      />
      <canvas
        ref={linecanvasRef}
        width={width}
        height={height}
        onClick={handleCanvasClick}
        style={{ position: 'fixed', top: '8rem', left: '4rem', zIndex: '100' }}
      />
    </div>
  );
});

export default React.memo(Canvas1);