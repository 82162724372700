import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useToken } from './TokenContext';

const Graph1 = ({ all, selectedGraph, currentFrame, videolength, jsonlength, selectedstep, showAdvanced, graphSize}) => {

  // all은 관절 별 좌표들만
  const { jsonall } = useToken();
  console.log(jsonall);
  if(currentFrame >= videolength){currentFrame -= 1;}
  let xdata = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
  let ydata = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
  let pathx = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
  let pathy = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0];
  var i;
  let joint = ["left_wrist", "right_wrist", "left_hip", "right_hip","left_shoulder", "right_shoulder", "left_knee", "right_knee",
  "left_heel", "right_heel", "left_toe", "right_toe","left_eye", "right_eye", "left_elbow", "right_elbow", "ears"];
  const steps = (jsonall && jsonall.data && jsonall.data.golf_step && jsonall.data.golf_step['down_half']) ? ["address", "take_away", "half", "top", "down_half", "impact", "follow_through", "finish"] 
  : ["address", "take_away", "half", "top", "impact", "follow_through", "finish"];
  const sec_steps = ["Address", "Take_away", "Half", "Top", "Impact", "Release", "Finish"];

  const stepidx = steps.indexOf(selectedstep);

  if (jsonall && jsonall.data && jsonall.data.tempo && jsonall.data.rotation_angle && jsonall.data.path && jsonall.data.path.ears && selectedstep) {
    // angle에서는 step이 top이면 half-top해야 half~top 구간 각이 나옴
    // tempo에서는 back, down은 제외하고(이미 값으로 파싱 함) :step을 포함하면 그걸로 설정하면 됨
    if (stepidx > 0) {
      var section = steps[stepidx - 1] + ':' + steps[stepidx]; // 'half:top' 같은 형식의 문자열 생성, json파싱에 사용
      var sec_tempo = jsonall.data.tempo.section_tempo[section] ? jsonall.data.tempo.section_tempo[section].toFixed(1) : '-';
      var ear_section_x = steps[stepidx - 1] + ':' + steps[stepidx] + "_x";
      var ear_section_y = steps[stepidx - 1] + ':' + steps[stepidx] + "_y";
  
      // if (jsonall.data.rotation_angle.spine_angle[section]) {
      //   var sec_spine_average = jsonall.data.rotation_angle.spine_angle[section] ? jsonall.data.rotation_angle.spine_angle[section].toFixed(1) : '-';
      // }
  
      if (jsonall.data.path.ears[ear_section_x] && jsonall.data.path.ears[ear_section_y]) { // 구간 별 head
        var sec_head_backdown_x = jsonall.data.path.ears[ear_section_x] ? jsonall.data.path.ears[ear_section_x] : '-';
        var sec_head_backdown_y = jsonall.data.path.ears[ear_section_y] ? jsonall.data.path.ears[ear_section_y] : '-';
      }
  
      // 현재 step - 이전 step
      // var sec_hip_updown_angle = jsonall.data.rotation_angle.xy_angle.hip[steps[stepidx]] && jsonall.data.rotation_angle.xy_angle.hip[steps[stepidx - 1]]
      //   ? (jsonall.data.rotation_angle.xy_angle.hip[steps[stepidx]] - jsonall.data.rotation_angle.xy_angle.hip[steps[stepidx - 1]]).toFixed(1)
      //   : '-';
      // var sec_knee_updown_angle = jsonall.data.rotation_angle.xy_angle.knee[steps[stepidx]] && jsonall.data.rotation_angle.xy_angle.knee[steps[stepidx - 1]]
      //   ? (jsonall.data.rotation_angle.xy_angle.knee[steps[stepidx]] - jsonall.data.rotation_angle.xy_angle.knee[steps[stepidx - 1]]).toFixed(1)
      //   : '-';
      // var sec_shoulder_updown_angle = jsonall.data.rotation_angle.xy_angle.shoulder[steps[stepidx]] && jsonall.data.rotation_angle.xy_angle.shoulder[steps[stepidx - 1]]
      //   ? (jsonall.data.rotation_angle.xy_angle.shoulder[steps[stepidx]] - jsonall.data.rotation_angle.xy_angle.shoulder[steps[stepidx - 1]]).toFixed(1)
      //   : '-';
  
      // var sec_hip_side_angle = jsonall.data.rotation_angle.xz_angle.hip[steps[stepidx]] && jsonall.data.rotation_angle.xz_angle.hip[steps[stepidx - 1]]
      //   ? (jsonall.data.rotation_angle.xz_angle.hip[steps[stepidx]] - jsonall.data.rotation_angle.xz_angle.hip[steps[stepidx - 1]]).toFixed(1)
      //   : '-';
      // var sec_knee_side_angle = jsonall.data.rotation_angle.xz_angle.knee[steps[stepidx]] && jsonall.data.rotation_angle.xz_angle.knee[steps[stepidx - 1]]
      //   ? (jsonall.data.rotation_angle.xz_angle.knee[steps[stepidx]] - jsonall.data.rotation_angle.xz_angle.knee[steps[stepidx - 1]]).toFixed(1)
      //   : '-';
      // var sec_shoulder_side_angle = jsonall.data.rotation_angle.xz_angle.shoulder[steps[stepidx]] && jsonall.data.rotation_angle.xz_angle.shoulder[steps[stepidx - 1]]
      //   ? (jsonall.data.rotation_angle.xz_angle.shoulder[steps[stepidx]] - jsonall.data.rotation_angle.xz_angle.shoulder[steps[stepidx - 1]]).toFixed(1)
      //   : '-';
  
      // 현재 - 이전 vertical angle
      // if (sec_hip_updown_angle > 90) sec_hip_updown_angle = (sec_hip_updown_angle - 90).toFixed(1);
      // if (sec_knee_updown_angle > 90) sec_knee_updown_angle = (sec_knee_updown_angle - 90).toFixed(1);
      // if (sec_shoulder_updown_angle > 90) sec_shoulder_updown_angle = (sec_shoulder_updown_angle - 90).toFixed(1);
    }
  
    // 이전 step별 horizontal 값
    // var hip_side_angle = jsonall.data.rotation_angle.xz_angle.hip[steps[stepidx]] ? jsonall.data.rotation_angle.xz_angle.hip[steps[stepidx]].toFixed(1) : 0;
    // var knee_side_angle = jsonall.data.rotation_angle.xz_angle.knee[steps[stepidx]] ? jsonall.data.rotation_angle.xz_angle.knee[steps[stepidx]].toFixed(1) : 0;
    // var shoulder_side_angle = jsonall.data.rotation_angle.xz_angle.shoulder[steps[stepidx]] ? jsonall.data.rotation_angle.xz_angle.shoulder[steps[stepidx]].toFixed(1) : 0;
  
    // // 이전 step별 vertical 값
    // var hip_updown_angle = jsonall.data.rotation_angle.xy_angle.hip[steps[stepidx]] ? jsonall.data.rotation_angle.xy_angle.hip[steps[stepidx]].toFixed(1) : 0;
    // var knee_updown_angle = jsonall.data.rotation_angle.xy_angle.knee[steps[stepidx]] ? jsonall.data.rotation_angle.xy_angle.knee[steps[stepidx]].toFixed(1) : 0;
    // var shoulder_updown_angle = jsonall.data.rotation_angle.xy_angle.shoulder[steps[stepidx]] ? jsonall.data.rotation_angle.xy_angle.shoulder[steps[stepidx]].toFixed(1) : 0;
  }


// 0일 때 안나오는 코드
// // 프레임 별 hip horizontal 값
// let horizontal_hip_frame = 0;
// if (currentFrame && jsonall.data.frames[currentFrame]?.point?.rotation_angle?.horizontal?.hip !== undefined) {
//   horizontal_hip_frame = typeof jsonall.data.frames[currentFrame].point.rotation_angle.horizontal.hip === 'number'
//     ? parseFloat(jsonall.data.frames[currentFrame].point.rotation_angle.horizontal.hip.toFixed(1)) || 0
//     : 0;
// }

// // 프레임 별 shoulder horizontal 값
// let horizontal_shoulder_frame = 0;
// if (currentFrame && jsonall.data.frames[currentFrame]?.point?.rotation_angle?.horizontal?.shoulder !== undefined) {
//   horizontal_shoulder_frame = typeof jsonall.data.frames[currentFrame].point.rotation_angle.horizontal.shoulder === 'number'
//     ? parseFloat(jsonall.data.frames[currentFrame].point.rotation_angle.horizontal.shoulder.toFixed(1)) || 0
//     : 0;
// }

// // 프레임 별 knee horizontal 값
// let horizontal_knee_frame = 0;
// if (currentFrame && jsonall.data.frames[currentFrame]?.point?.rotation_angle?.horizontal?.knee !== undefined) {
//   horizontal_knee_frame = typeof jsonall.data.frames[currentFrame].point.rotation_angle.horizontal.knee === 'number'
//     ? parseFloat(jsonall.data.frames[currentFrame].point.rotation_angle.horizontal.knee.toFixed(1)) || 0
//     : 0;
// }

// // 프레임 별 shoulder vertical 값
// let vertical_shoulder_frame = 0;
// if (currentFrame && jsonall.data.frames[currentFrame]?.point?.rotation_angle?.vertical?.shoulder !== undefined) {
//   vertical_shoulder_frame = typeof jsonall.data.frames[currentFrame].point.rotation_angle.vertical.shoulder === 'number'
//     ? parseFloat(jsonall.data.frames[currentFrame].point.rotation_angle.vertical.shoulder.toFixed(1)) || 0
//     : 0;
// }

// // 프레임 별 hip vertical 값
// let vertical_hip_frame = 0;
// if (currentFrame && jsonall.data.frames[currentFrame]?.point?.rotation_angle?.vertical?.hip !== undefined) {
//   vertical_hip_frame = typeof jsonall.data.frames[currentFrame].point.rotation_angle.vertical.hip === 'number'
//     ? parseFloat(jsonall.data.frames[currentFrame].point.rotation_angle.vertical.hip.toFixed(1)) || 0
//     : 0;
// }

// // 프레임 별 평균 척추각
// let sec_spine_average_frame = 0;
// if (currentFrame && jsonall.data.frames[currentFrame]?.point?.spine_angle?.value !== undefined) {
//   sec_spine_average_frame = typeof jsonall.data.frames[currentFrame].point.spine_angle.value === 'number'
//     ? parseFloat(jsonall.data.frames[currentFrame].point.spine_angle.value.toFixed(1)) || 0
//     : 0;
// }

// // 프레임 별 head
// let sec_head_backdown_x_frame = sec_head_backdown_x;
// let sec_head_backdown_y_frame = sec_head_backdown_y;
// if (currentFrame && jsonall.data.frames[currentFrame]?.point?.head) {
//   sec_head_backdown_x_frame = jsonall.data.frames[currentFrame].point.head.x !== undefined ? jsonall.data.frames[currentFrame].point.head.x : sec_head_backdown_x;
//   sec_head_backdown_y_frame = jsonall.data.frames[currentFrame].point.head.y !== undefined ? jsonall.data.frames[currentFrame].point.head.y : sec_head_backdown_y;
// }

 // 프레임 별 hip horizontal 값
let horizontal_hip_frame = 0;
if (
  jsonall &&
  jsonall.data &&
  jsonall.data.frames &&
  jsonall.data.frames[currentFrame] &&
  jsonall.data.frames[currentFrame].point &&
  jsonall.data.frames[currentFrame].point.rotation_angle &&
  jsonall.data.frames[currentFrame].point.rotation_angle.horizontal &&
  jsonall.data.frames[currentFrame].point.rotation_angle.horizontal.hip !== undefined
) {
  const hipValue = jsonall.data.frames[currentFrame].point.rotation_angle.horizontal.hip;
  horizontal_hip_frame = typeof hipValue === 'number'
    ? parseFloat(hipValue.toFixed(1)) || 0
    : 0;
}

  // 프레임 별 shoulder horizontal 값
  let horizontal_shoulder_frame = 0;
  if (
    jsonall &&
    jsonall.data &&
    jsonall.data.frames &&
    jsonall.data.frames[currentFrame] &&
    jsonall.data.frames[currentFrame].point &&
    jsonall.data.frames[currentFrame].point.rotation_angle &&
    jsonall.data.frames[currentFrame].point.rotation_angle.horizontal &&
    jsonall.data.frames[currentFrame].point.rotation_angle.horizontal.shoulder !== undefined
  ) {
    const shoulderValue = jsonall.data.frames[currentFrame].point.rotation_angle.horizontal.shoulder;
    horizontal_shoulder_frame = typeof shoulderValue === 'number'
      ? parseFloat(shoulderValue.toFixed(1)) || 0
      : 0;
  }

  // 프레임 별 knee horizontal 값
  let horizontal_knee_frame = 0;
  if (
    jsonall &&
    jsonall.data &&
    jsonall.data.frames &&
    jsonall.data.frames[currentFrame] &&
    jsonall.data.frames[currentFrame].point &&
    jsonall.data.frames[currentFrame].point.rotation_angle &&
    jsonall.data.frames[currentFrame].point.rotation_angle.horizontal &&
    jsonall.data.frames[currentFrame].point.rotation_angle.horizontal.knee !== undefined
  ) {
    const kneeValue = jsonall.data.frames[currentFrame].point.rotation_angle.horizontal.knee;
    horizontal_knee_frame = typeof kneeValue === 'number'
      ? parseFloat(kneeValue.toFixed(1)) || 0
      : 0;
  }

  // 프레임 별 shoulder vertical 값
  let vertical_shoulder_frame = 0;
  if (
    jsonall &&
    jsonall.data &&
    jsonall.data.frames &&
    jsonall.data.frames[currentFrame] &&
    jsonall.data.frames[currentFrame].point &&
    jsonall.data.frames[currentFrame].point.rotation_angle &&
    jsonall.data.frames[currentFrame].point.rotation_angle.vertical &&
    jsonall.data.frames[currentFrame].point.rotation_angle.vertical.shoulder !== undefined
  ) {
    const shoulderValue = jsonall.data.frames[currentFrame].point.rotation_angle.vertical.shoulder;
    vertical_shoulder_frame = typeof shoulderValue === 'number'
      ? parseFloat(shoulderValue.toFixed(1)) || 0
      : 0;
  }

  // 프레임 별 hip vertical 값
  let vertical_hip_frame = 0;
  if (
    jsonall &&
    jsonall.data &&
    jsonall.data.frames &&
    jsonall.data.frames[currentFrame] &&
    jsonall.data.frames[currentFrame].point &&
    jsonall.data.frames[currentFrame].point.rotation_angle &&
    jsonall.data.frames[currentFrame].point.rotation_angle.vertical &&
    jsonall.data.frames[currentFrame].point.rotation_angle.vertical.hip !== undefined
  ) {
    const hipValue = jsonall.data.frames[currentFrame].point.rotation_angle.vertical.hip;
    vertical_hip_frame = typeof hipValue === 'number'
      ? parseFloat(hipValue.toFixed(1)) || 0
      : 0;
  }

  // 프레임 별 평균 척추각
  let sec_spine_average_frame = 0;
  if (
    jsonall &&
    jsonall.data &&
    jsonall.data.frames &&
    jsonall.data.frames[currentFrame] &&
    jsonall.data.frames[currentFrame].point &&
    jsonall.data.frames[currentFrame].point.spine_angle &&
    jsonall.data.frames[currentFrame].point.spine_angle.value !== undefined
  ) {
    sec_spine_average_frame = parseFloat(jsonall.data.frames[currentFrame].point.spine_angle.value.toFixed(1)) || 0;
  }


  // 프레임 별 head
  let sec_head_backdown_x_frame = sec_head_backdown_x;
  let sec_head_backdown_y_frame = sec_head_backdown_y;
  if (
    jsonall &&
    jsonall.data &&
    jsonall.data.frames &&
    jsonall.data.frames[currentFrame] &&
    jsonall.data.frames[currentFrame].point &&
    jsonall.data.frames[currentFrame].point.head
  ) {
    sec_head_backdown_x_frame = jsonall.data.frames[currentFrame].point.head.x !== undefined
      ? jsonall.data.frames[currentFrame].point.head.x
      : sec_head_backdown_x;
    sec_head_backdown_y_frame = jsonall.data.frames[currentFrame].point.head.y !== undefined
      ? jsonall.data.frames[currentFrame].point.head.y
      : sec_head_backdown_y;
  }

  var jsonData = [];

 // fixed values
  if(jsonall.data && jsonall.data.rotation_angle.spine_angle.total && jsonall.data.rotation_angle.spine_angle.total_minmax){

    // TEMPO
    var back_tempo = jsonall.data.tempo.back_down_tempo.back.toFixed(1); // 어드레스~탑 백스윙 템포  **파싱 필요없음**
    var down_tempo = jsonall.data.tempo.back_down_tempo.down.toFixed(1); // 탑~임팩트 다운스윙 템포  **파싱 필요없음**
    // var ear_back_angle = jsonall.data.path.ears.back.toFixed(1);
    // var ear_down_angle = jsonall.data.path.ears.down.toFixed(1);

    // POSITION
    var top_positon = jsonall.data.position ? jsonall.data.position.toUpperCase() : '-';
    var top_spine = jsonall.data.rotation_angle.spine_angle.total ? jsonall.data.rotation_angle.spine_angle.total.toFixed(1) : '-';
    
    // SWINGPATH
    var downswing_angle = jsonall.data.path.rwrist["down_swing_angle"] ? jsonall.data.path.rwrist["down_swing_angle"].toFixed(1) : '-';
    let raw_up_to_down = jsonall.data.path.rwrist.area ? jsonall.data.path.rwrist.area : '';
    if(raw_up_to_down){
      if(typeof raw_up_to_down === 'string'){
        let prefix = raw_up_to_down.match(/[^\d.-]+/)[0];
        let numericPart = parseFloat(raw_up_to_down.match(/-?\d+\.?\d*/));
        let absoluteValue = Math.abs(numericPart).toFixed(2);
        var up_to_down = prefix + absoluteValue;
      } 
    }

    var upswing_angle = jsonall.data.path.rwrist["finish_swing_angle"] ? jsonall.data.path.rwrist["finish_swing_angle"].toFixed(1) : '';
    let raw_down_to_up = jsonall.data.path.rwrist.area_finish ? jsonall.data.path.rwrist.area_finish : '';
    if(raw_down_to_up){
      if(typeof raw_down_to_up === 'string'){
        let prefix = raw_down_to_up.match(/[^\d.-]+/)[0];
        let numericPart = parseFloat(raw_down_to_up.match(/-?\d+\.?\d*/));
        let absoluteValue = Math.abs(numericPart).toFixed(2);
        var down_to_up = prefix + absoluteValue;
      }
    }
 
    //var total_average = jsonall.data.rotation_angle.spine_angle.total.toFixed(1); // 전체 평균 척추각
    // FLEXABILITY에 들어가는 값
    var total_min_max = jsonall.data.rotation_angle.spine_angle.total_minmax?jsonall.data.rotation_angle.spine_angle.total_minmax.toFixed(1) : '-' // 전체 척추각의 max-min
    var head_backdown_y = jsonall.data.path.ears.back_down_y ? jsonall.data.path.ears.back_down_y : '-';
    var head_backdown_x = jsonall.data.path.ears.back_down_x ? jsonall.data.path.ears.back_down_x :'-';
    var right_knee = jsonall.data.rotation_angle.knee ? jsonall.data.rotation_angle.knee.toFixed(1) : '-';
    if(jsonall.data.rotation_angle.knee === 0)
    {
      right_knee = 0;
    }

    //var address_spine= jsonall.data.rotation_angle.spine_angle.address.toFixed(1); // address의 척추각
  }
  
  // 플레이어 슬라이드랑  custom슬라이드랑 시작, 끝 맞춰야 함 (currentTime으로 계산해서 오차 발생)
  for(i=0; i<=Math.min(currentFrame, jsonlength); i++){
    jsonData.push(all[i])
  }
  
  for(i=0; i<joint.length; i++){
    if(selectedGraph.includes(joint[i]+".x") || selectedGraph.includes(joint[i]+".y")){
      if(selectedGraph.includes(joint[i]+".x")){
        xdata[joint.indexOf(joint[i])] = joint[i]+".x"
        pathx[joint.indexOf(joint[i])] = joint[i] + '.x';
      }
      if (selectedGraph.includes(joint[i]+".y")){
        ydata[joint.indexOf(joint[i])] = joint[i]+".y"
        pathy[joint.indexOf(joint[i])] = joint[i] + '.y';
      }
    }
  }

  return (
    <>
      <>
        <div className='video-player'>
          <div className='backangle-display'>
              {all[currentFrame] && currentFrame <= jsonlength && (
                <p>CurrentFrame: {currentFrame}</p>
              )}
          </div>
        </div>
      </>

      <>
      {!showAdvanced && (
        <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th colSpan="11">SWING SUMMARY</th>
                </tr>

                <tr>
                  <th colSpan="2">TEMPO</th>
                  <th colSpan="2">POSITION</th>
                  <th colSpan="4">SWING DIRECTION</th>
                  <th colSpan="3">FIXABILITY</th>
                </tr>

                <tr>
                  <th colSpan="1">BACK</th>
                  <th colSpan="1">DOWN</th>
                  <th colSpan="1">TOP</th>
                  <th colSpan="1">SPINE<br></br>ANGLE</th>
                  <th colSpan="1">DOWN<br></br>INCLINE</th>
                  <th colSpan="1">TOP to IMPACT</th>
                  <th colSpan="1">UP<br></br>INCLINE</th>
                  <th colSpan="1">IMPACT to FINISH</th>
                  <th colSpan="1">SPINE</th>
                  <th colSpan="1">HEAD</th>
                  <th colSpan="1">KNEE (R)</th>
                </tr>
              </thead>

              <tr>
                <td style={{color:'cyan'}}>{back_tempo !== undefined && back_tempo !== null ? back_tempo : '-'}</td>
                <td style={{color:'cyan'}}>{down_tempo !== undefined && down_tempo !== null ? down_tempo : '-'}</td>
                <td style={{color:'cyan'}}>{top_positon !== undefined && top_positon !== null ? top_positon : '-'}</td>
                <td style={{color:'cyan'}}>{top_spine !== undefined && top_spine !== null ? top_spine + '°' : '-'}</td>
                <td style={{color:'cyan'}}>{downswing_angle !== undefined && downswing_angle !== null ? downswing_angle + '°' : '-'}</td>
                <td style={{color:'cyan'}}>{up_to_down !== undefined && up_to_down !== null ? up_to_down : '-'}</td>
                <td style={{color:'cyan'}}>{upswing_angle !== undefined && upswing_angle !== null ? upswing_angle + '°' : '-'}</td>
                <td style={{color:'cyan'}}>{down_to_up !== undefined && down_to_up !== null ? down_to_up : '-'}</td>
                <td style={{color:'cyan'}}>{total_min_max !== undefined && total_min_max !== null ? total_min_max : '-'}</td>
                <td style={{color:'cyan'}}>
                  {head_backdown_y !== undefined && head_backdown_y !== null ? head_backdown_y : '-'}
                  <br />
                  {head_backdown_x !== undefined && head_backdown_x !== null ? head_backdown_x : '-'}
                </td>
                <td style={{color:'cyan'}}>{right_knee !== undefined && right_knee !== null ? right_knee : '-'}</td>
              </tr>

            </table>
            <label>...</label>
            <table>
              <thead>
                <tr>
                  <th colSpan="8">SWING DETAILS (PER STEP)</th>
                </tr>

                <tr>
                  <th style = {{color:'orange'}}colSpan="8">{section ? section.toUpperCase() : 'ADDRESS'}</th>
                </tr>

                {/* <tr>
                  <th colSpan="1">Tempo</th>
                  <th colSpan="6">Rotation angle</th>
                  <th colSpan="1">Spine angle</th>
                  <th colSpan="1">Head path</th>
                </tr> */}

                <tr>
                  <th rowSpan="2">TEMPO</th>
                  <th colSpan="3">H. ROTATION ANGLE</th>
                  <th colSpan="2">V. ROTATION ANGLE</th>
                  <th rowSpan="2">SPINE<br></br>ANGLE</th>
                  <th rowSpan="2">HEAD</th>
                </tr>
                <tr>
                  <th>SHOULDER</th>
                  <th>HIP</th>
                  <th>KNEE</th>
                  <th>SHOULDER</th>
                  <th>HIP</th>
                </tr>
              </thead>

                {/* <tr>
                  <td>{sec_tempo?sec_tempo:"-"}</td>
                  <td>{shoulder_updown_angle? shoulder_updown_angle+'°':"-"}<br></br>{sec_shoulder_updown_angle < 0 ? "▽" : "Δ"}{VerShoulder?VerShoulder+'°' : "-"}</td>
                  <td>{shoulder_side_angle?shoulder_side_angle+'°':"-"}<br></br>{sec_shoulder_side_angle < 0 ? "▽" : "Δ"}{HorShoulder?HorShoulder+'°' : "-"}</td>
                  <td>{hip_updown_angle?hip_updown_angle+'°':"-"}<br></br> {sec_hip_updown_angle < 0 ? "▽" : "Δ"}{VerHip?VerHip+'°' : "-"}</td>
                  <td>{hip_side_angle?hip_side_angle+'°':"-"}<br></br>{sec_hip_side_angle < 0 ? "▽" : "Δ"}{HorHip?HorHip+'°' : "-"}</td>
                  <td>{knee_updown_angle?knee_updown_angle+'°':"-"}<br></br> {sec_knee_updown_angle < 0 ? "▽" : "Δ"}{VerKnee?VerKnee+'°' : "-"}</td>
                  <td>{knee_side_angle?knee_side_angle+'°':"-"}<br></br>{sec_knee_side_angle < 0 ? "▽" : "Δ"}{HorKnee?HorKnee+'°' : "-"}</td>
                  <td>{sec_spine_average?sec_spine_average+'°':address_spine+'°'}<br></br>{sec_spine_min_max?sec_spine_min_max+'°':"-"}</td>
                  <td>Δ{ear_path_angle?ear_path_angle:"-"}</td>
                </tr> */}

                <tr>
                  <td>{sec_tempo !== undefined && sec_tempo !== null ? sec_tempo : "-"}</td>
                  <td>{horizontal_shoulder_frame !== undefined && horizontal_shoulder_frame !== null ? horizontal_shoulder_frame + '°' : "-"}</td>
                  <td>{horizontal_hip_frame !== undefined && horizontal_hip_frame !== null ? horizontal_hip_frame + '°' : "-"}</td>
                  <td>{horizontal_knee_frame !== undefined && horizontal_knee_frame !== null ? horizontal_knee_frame + '°' : "-"}</td>
                  <td>{vertical_shoulder_frame !== undefined && vertical_shoulder_frame !== null ? vertical_shoulder_frame + '°' : "-"}</td>
                  <td>{vertical_hip_frame !== undefined && vertical_hip_frame !== null ? vertical_hip_frame + '°' : "-"}</td>
                  <td>{sec_spine_average_frame !== undefined && sec_spine_average_frame !== null ? sec_spine_average_frame + '°' : '-'}</td>
                  <td>
                    {sec_head_backdown_y_frame !== undefined && sec_head_backdown_y_frame !== null ? sec_head_backdown_y_frame : '-'}
                    <br />
                    {sec_head_backdown_x_frame !== undefined && sec_head_backdown_x_frame !== null ? sec_head_backdown_x_frame : '-'}
                  </td>
                </tr>

            </table>  
          </div>
          )}
      </>

      <>
      {showAdvanced && (
        <div className="graph-container">
              <>
                  <LineChart width={graphSize.width} height={graphSize.height} data={jsonData}>
                  <XAxis domain={[0, jsonlength]} />
                  <YAxis />
                  <CartesianGrid strokeDasharray='1 1' />
                  <Tooltip content={() => null}/>
                  <Legend />
                  {xdata[0] && <Line type='monotone' dataKey={xdata[0]}  name={joint[0]+".x"} stroke='red' activeDot={{ r: 0 }} dot={false} />}
                  {ydata[0] && <Line type='monotone' dataKey={ydata[0]}  name={joint[0]+".y"} stroke='orange' activeDot={{ r: 0 }} dot={false}/>} 
                  {xdata[1] && <Line type='monotone' dataKey={xdata[1]}  name={joint[1]+".x"} stroke='green' activeDot={{ r: 0 }} dot={false}/>} 
                  {ydata[1] && <Line type='monotone' dataKey={ydata[1]}  name={joint[1]+".y"} stroke='blue' activeDot={{ r: 0 }} dot={false}/>}

                  {xdata[2] && <Line type='monotone' dataKey={xdata[2]}  name={joint[2]+".x"} stroke='navy' activeDot={{ r: 0 }} dot={false}/>}
                  {ydata[2] && <Line type='monotone' dataKey={ydata[2]}  name={joint[2]+".y"} stroke='purple' activeDot={{ r: 0 }} dot={false}/>} 
                  {xdata[3] && <Line type='monotone' dataKey={xdata[3]}  name={joint[3]+".x"} stroke='cyan' activeDot={{ r: 0 }} dot={false}/>} 
                  {ydata[3] && <Line type='monotone' dataKey={ydata[3]}  name={joint[3]+".y"} stroke='magenta' activeDot={{ r: 0 }} dot={false}/>}

                  {xdata[4] && <Line type='monotone' dataKey={xdata[4]}  name={joint[4]+".x"} stroke='black' activeDot={{ r: 0 }} dot={false}/>}
                  {ydata[4] && <Line type='monotone' dataKey={ydata[4]}  name={joint[4]+".y"} stroke='silver' activeDot={{ r: 0 }} dot={false}/>} 
                  {xdata[5] && <Line type='monotone' dataKey={xdata[5]}  name={joint[5]+".x"} stroke='gray' activeDot={{ r: 0 }} dot={false}/>} 
                  {ydata[5] && <Line type='monotone' dataKey={ydata[5]}  name={joint[5]+".y"} stroke='white' activeDot={{ r: 0 }} dot={false}/>}

                  {xdata[6] && <Line type='monotone' dataKey={xdata[6]}  name={joint[6]+".x"} stroke='maroon' activeDot={{ r: 0 }} dot={false}/>}
                  {ydata[6] && <Line type='monotone' dataKey={ydata[6]}  name={joint[6]+".y"} stroke='fuchsia' activeDot={{ r: 0 }} dot={false}/>} 
                  {xdata[7] && <Line type='monotone' dataKey={xdata[7]}  name={joint[7]+".x"} stroke='lime' activeDot={{ r: 0 }} dot={false}/>} 
                  {ydata[7] && <Line type='monotone' dataKey={ydata[7]}  name={joint[7]+".y"} stroke='olive' activeDot={{ r: 0 }} dot={false}/>}

                  {xdata[8] && <Line type='monotone' dataKey={xdata[8]}  name={joint[8]+".x"} stroke='teal' activeDot={{ r: 0 }} dot={false}/>}
                  {ydata[8] && <Line type='monotone' dataKey={ydata[8]}  name={joint[8]+".y"} stroke='aliceblue' activeDot={{ r: 0 }} dot={false}/>} 
                  {xdata[9] && <Line type='monotone' dataKey={xdata[9]}  name={joint[9]+".x"} stroke='antiquewhite' activeDot={{ r: 0 }} dot={false}/>} 
                  {ydata[9] && <Line type='monotone' dataKey={ydata[9]}  name={joint[9]+".y"} stroke='aquamarine' activeDot={{ r: 0 }} dot={false}/>}

                  {xdata[10] && <Line type='monotone' dataKey={xdata[10]}  name={joint[10]+".x"} stroke='azure' activeDot={{ r: 0 }} dot={false}/>}
                  {ydata[10] && <Line type='monotone' dataKey={ydata[10]}  name={joint[10]+".y"} stroke='beige' activeDot={{ r: 0 }} dot={false}/>} 
                  {xdata[11] && <Line type='monotone' dataKey={xdata[11]}  name={joint[11]+".x"} stroke='bisque' activeDot={{ r: 0 }} dot={false}/>} 
                  {ydata[11] && <Line type='monotone' dataKey={ydata[11]}  name={joint[11]+".y"} stroke='blanchedalmond' activeDot={{ r: 0 }} dot={false}/>}

                  {xdata[12] && <Line type='monotone' dataKey={xdata[12]}  name={joint[12]+".x"} stroke='blueviolet' activeDot={{ r: 0 }} dot={false}/>}
                  {ydata[12] && <Line type='monotone' dataKey={ydata[12]}  name={joint[12]+".y"} stroke='brown' activeDot={{ r: 0 }} dot={false}/>} 
                  {xdata[13] && <Line type='monotone' dataKey={xdata[13]}  name={joint[13]+".x"} stroke='coral' activeDot={{ r: 0 }} dot={false}/>} 
                  {ydata[13] && <Line type='monotone' dataKey={ydata[13]}  name={joint[13]+".y"} stroke='cornsilk' activeDot={{ r: 0 }} dot={false}/>}

                  {xdata[14] && <Line type='monotone' dataKey={xdata[14]}  name={joint[14]+".x"} stroke='darkcyan' activeDot={{ r: 0 }} dot={false}/>}
                  {ydata[14] && <Line type='monotone' dataKey={ydata[14]}  name={joint[14]+".y"} stroke='darkgray' activeDot={{ r: 0 }} dot={false}/>} 
                  {xdata[15] && <Line type='monotone' dataKey={xdata[15]}  name={joint[15]+".x"} stroke='darkkhaki' activeDot={{ r: 0 }} dot={false}/>} 
                  {ydata[15] && <Line type='monotone' dataKey={ydata[15]}  name={joint[15]+".y"} stroke='chocolate' activeDot={{ r: 0 }} dot={false}/>}   

                  {xdata[16] && <Line type='monotone' dataKey={xdata[16]}  name={joint[16]+".x"} stroke='blue' activeDot={{ r: 0 }} dot={false}/>} 
                  {ydata[16] && <Line type='monotone' dataKey={ydata[16]}  name={joint[16]+".y"} stroke='magenta' activeDot={{ r: 0 }} dot={false}/>}     
                  </LineChart>
              </>
        </div>
         )}
      </>
  </>
);
};

export default React.memo(Graph1);