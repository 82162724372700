import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Footer from './Components/footer';
import Menu from './Components/menu';
import { TokenProvider } from './Components/TokenContext';
import { isMobile, isTablet } from 'react-device-detect';
import ReactPlayer from 'react-player';

import StartPage from './Pages/startpage';
import LoginPage from './Pages/login';
import SignupPage from './Pages/signup';
import RecordPage from './Pages/record';
import UploadPage from './Pages/upload';
import CoachUploadPage from './Pages/coach_upload';
import MyPage from './Pages/mypage';
import Solo from './Pages/solo';
import Compare from './Pages/compare';
import CoachSolo from './Pages/coachsolo';
import CoachCompare from './Pages/coachcompare';
import BeforeSignup from './Pages/beforesignup';
import ProSignupPage from './Pages/prosignup';
import Myvideo from './Pages/my-video';
import Publicvideo from './Pages/public-video';
import MyInfo from './Pages/my-information';
import CoachRegistration from './Pages/coach-registration';
import MyStudent from './Pages/mystudent';
import Statistics from './Pages/statistics';
import Launchpad from './Pages/launchpad';
import { SocketProvider } from './Components/SocketContext'; 
import PrivacyPolicy from './Pages/privacy-policy'
import ServiceTerms from './Pages/service-terms';
import PersonalInfoAgreement from './Pages/personal-info-agreement';
import MarketingPurposeConsent from './Pages/marketing';
import QnA from './Pages/QnA';

function App() {
  const [loginId, setLoginId] = useState(null);

  const handleSuccessfulLogin = (loginId) => {
    setLoginId(loginId); // Set the URL obtained after successful login
    sessionStorage.getItem('loginId', loginId)
  };

  // useEffect(() => {
  //   if(isMobile && !isTablet) {
  //     alert('Keep Motion 시범 서비스 기간에는 모바일 버전은 지원되지 않습니다. PC나 태블릿을 사용해주세요. 감사합니다.');
  //   }
  // }, []);

  return (
    <SocketProvider>
    <TokenProvider>
      <Router>
        {isMobile && !isTablet ? ( 
            // <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            //   <ReactPlayer url="/homevideo.mp4" playing loop/>
            // </div>
              <div>
                 <Menu/>
                  <Routes>
                    <Route path="/" element={<StartPage />} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/upload" element={<UploadPage />} />
                    <Route path="/coachupload" element={<CoachUploadPage />} />
                  </Routes>
              </div>
              ) : (
            <>
              <Menu/>
                <Routes>
                  {/* StartPage */}
                  <Route path="/" element={<StartPage />} />

                  {/* LoginPage */}
                  <Route path="/login" element={<LoginPage />} />

                  {/* SignupPage */}
                  <Route path="/signup" element={<SignupPage />} />

                  {/* RecordPage */}
                  <Route path="/record" element={<RecordPage />} />

                  {/* UploadPage */}
                  <Route path="/upload" element={<UploadPage />} />

                  {/* CoachUploadPage */}
                  <Route path="/coachupload" element={<CoachUploadPage />} />

                  {/* MyPage */}
                  <Route path="/mypage" element={<MyPage />} />

                  {/* Solo */}
                  <Route path="/solo" element={<Solo />} />

                  {/* Compare */}
                  <Route path="/compare" element={<Compare />} />

                  {/* CoachSolo */}
                  <Route path="/coachsolo" element={<CoachSolo />} />

                  {/* CoachCompare */}
                  <Route path="/coachcompare" element={<CoachCompare />} />

                  {/* BeforeSignup */}
                  <Route path="/beforesignup" element={<BeforeSignup />} />

                  {/* ProSignupPage */}
                  <Route path="/prosignup" element={<ProSignupPage />} />

                  {/* Myvideo */}
                  <Route path="/myvideo" element={<Myvideo />} />

                  {/* Publicvideo */}
                  <Route path="/public-video" element={<Publicvideo />} />

                  {/* MyInfo */}
                  <Route path="/my-information" element={<MyInfo />} />

                  {/* CoachRegistration */}
                  <Route path="/coach-registration" element={<CoachRegistration />} />

                  {/* MyStudent */}
                  <Route path="/mystudent" element={<MyStudent />} />

                  {/* Statistics */}
                  <Route path="/statistics" element={<Statistics />} />
                  
                  {/* Launchpad */}
                  <Route path="/launchpad" element={<Launchpad />} />
                  
                  {/* PrivacyPolicy */}
                  <Route path="/privacypolicy" element={<PrivacyPolicy />} />

                  {/* ServiceTerms */}
                  <Route path="/service-terms" element={<ServiceTerms />} />

                  {/* PersonalInfoAgreement */}
                  <Route path="/personal-info-agreement" element={<PersonalInfoAgreement />} />

                  {/* MarketingPurposeConsent */}
                  <Route path="/marketing" element={<MarketingPurposeConsent />} />

                  {/* QnA */}
                  <Route path="/qna" element={<QnA />} />

                </Routes>
              <Footer />
        </>
        )}
      </Router>
    </TokenProvider>
    </SocketProvider>

  );
}

export default App;