import React, { useState, useRef } from 'react';
import Webcam from 'react-webcam';
import RecordRTC, { WebAssemblyRecorder } from 'recordrtc';
import WebcamSelector from '../Components/WebcamSelector';
import AWS from 'aws-sdk';
import { useToken } from '../Components/TokenContext';
import { useNavigate } from 'react-router-dom';
import { showAlert } from '../Components/alert';

const RecordPage = () => {
  const navigate = useNavigate();
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const videoRef = useRef(null); // 추가된 부분
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [selectedWebcam, setSelectedWebcam] = useState(null);
  const [isRecording, setIsRecording] = useState(false);
  const [message, setMessage] = useState('');
  const [uploadmsg, setUploadmsg] = useState('');
  const { token, studentId, userId } = useToken();

  const startRecording = () => {
    const webcam = webcamRef.current;
    if (!webcam || !webcam.stream) {
      console.error('Webcam is not ready or stream is not available.');
      return;
    }
    mediaRecorderRef.current = new RecordRTC(webcam.stream, {
      type: 'video',
      mimeType: "video/webm;codecs=h264"
    });
    mediaRecorderRef.current.startRecording();
    setIsRecording(true);
    setMessage('녹화 중입니다.');
  };

  const stopRecording = () => {
    mediaRecorderRef.current.stopRecording(() => {
      const blob = mediaRecorderRef.current.getBlob();
      setRecordedChunks([blob]);
      setIsRecording(false);
      setMessage('녹화가 종료 되었습니다.');
      videoRef.current.src = URL.createObjectURL(blob);
    });
  };

  const uploadToS3 = async () => {

    AWS.config.update({
        accessKeyId: 'AKIAVVKFCTNJJFZR6ABQ',
        secretAccessKey: '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',
        region: 'ap-northeast-2'
      });
    
      const s3 = new AWS.S3();
      const bucketName = 'dmz-metadata';

    if (recordedChunks.length === 0) {
      showAlert('업로드할 영상이 없습니다.');
      return;
    }

    const blob = new Blob(recordedChunks, { type: 'video/mp4' });
    const videoName = `video_${Date.now()}.mp4`;
    const params = {
        Bucket: bucketName,
        Key: `lss/${videoName}`,
        Body: blob,
        ContentType: 'video/mp4'
    };

    setUploadmsg('업로드 중입니다...');
    
    try {
        const token2 = 'Bearer ' + token
        const s3Response = await s3.upload(params).promise();
        console.log('업로드 성공:', s3Response);
        setUploadmsg('업로드 완료.');
  
        const response = await fetch('http://117.16.243.158:9000/myswing/upload/student/' +studentId, {
            method: 'POST',
            headers: {
              'Authorization': token2,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              videoPath: params.Key,
              studentId: studentId, 
              userId: userId
            })
    
          });
          const data = await response.json();
          if (data.success) {
            setUploadmsg('변환이 완료 되었습니다.');
          } else {
            setUploadmsg('변환에 실패 했습니다.');
          }
      } catch (err) {
          console.error('업로드 오류:', err);
          setUploadmsg('업로드 실패.');
      }
    };

  return (
    <div>
      <WebcamSelector onWebcamSelect={setSelectedWebcam} />
      {selectedWebcam && <Webcam ref={webcamRef} videoConstraints={{ width: 540, height: 960, deviceId: selectedWebcam, frameRate: { ideal: 30, max: 60 } }}  />}
      <div>
        <button onClick={startRecording} disabled={isRecording}>
          녹화 시작
        </button>
        <button onClick={stopRecording} disabled={!isRecording}>
          녹화 종료
        </button>
        <button onClick={uploadToS3}>영상 업로드</button>
      </div>
      {message && <p style={{ fontSize: '24px', color:'white'}}>{message}</p>}
      {uploadmsg && <p style={{ fontSize: '24px', color:'white' }}>{uploadmsg}</p>}
      <video ref={videoRef} controls width="400" style={{ display: recordedChunks.length > 0 ? 'block' : 'none' }}></video>
    </div>
  );
};

export default RecordPage;
