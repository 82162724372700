import React from 'react';
import "../Styles/mypage.css"
import General from '../Components/general';

const Myvideo = () => {


  return (
    <>
      <General />
    </>
  );
};

export default Myvideo;