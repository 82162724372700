import React, {useState, useRef} from 'react';
import PanZoom from 'react-easy-panzoom';
import { useLocation } from 'react-router-dom';
import { useToken } from './TokenContext';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import '../Styles/compare.css'

const Graph2 = ({ all1, all2, c, d, selectedGraph, currentFrame1, currentFrame2, activeStep1, activeStep2
  , videolength1, videolength2, isFlipped1, isFlipped2, graphSize, selectedstep, updateVisibleTables, jsonlength1, jsonlength2}) => {
    
    const { jsonall1, jsonall2 } = useToken();

    const location = useLocation();

  const joint = [
    "left_wrist", "right_wrist", "left_hip", "right_hip", "left_shoulder", "right_shoulder", "left_knee", "right_knee",
    "left_heel", "right_heel", "left_toe", "right_toe", "left_eye", "right_eye", "left_elbow", "right_elbow", "ears"];

    const steps1 = (jsonall1 && jsonall1.data && jsonall1.data.golf_step && jsonall1.data.golf_step['down_half']) 
        ? ["address", "take_away", "half", "top", "down_half", "impact", "follow_through", "finish"]
        : ["address", "take_away", "half", "top", "impact", "follow_through", "finish"];
    
    const steps2 = (jsonall2 && jsonall2.data && jsonall2.data.golf_step && jsonall2.data.golf_step['down_half']) 
        ? ["address", "take_away", "half", "top", "down_half", "impact", "follow_through", "finish"]
        : ["address", "take_away", "half", "top", "impact", "follow_through", "finish"];
    
    const sec_steps = ["Address", "Take_away", "Half", "Top", "Impact", "Release", "Finish"];

  const stepidx1 = steps1.indexOf(selectedstep);
  const stepidx2 = steps2.indexOf(selectedstep);

  const [xMin, setXMin] = useState(0.25);
  const [xMax, setXMax] = useState(0.5);
  const [yMin, setYMin] = useState(-0.7);
  const [yMax, setYMax] = useState(-0.35);
  const [visibleGraphs, setVisibleGraphs] = useState('both');
  const [visibleTables, setVisibleTables] = useState('visual');
  const [showshoulder, setShowShoulder] = useState(false);
  const [showHip, setShowHip] = useState(false);
  const [showKnee, setShowKnee] = useState(false);
  const [showSpineAngle, setShowSpineAngle] = useState(false);

  const panZoomRef = useRef();
  const pathData = [];

  const changeVisibleTable = (newTable) => {
    updateVisibleTables(newTable); // Update parent's state
};

  if(currentFrame1 >= videolength1) currentFrame1 -= 1
  if(currentFrame2 >= videolength2) currentFrame2 -= 1
  const rotateFlipData = (data, isFlipped) => {
    // 데이터를 180도 회전하고, 필요한 경우 좌우 반전합니다.
    return data.map((dataPoint) => ({
      x: isFlipped ? 1.4 -dataPoint.x : dataPoint.x, // 필요한 경우 x 좌표 반전
      y: -dataPoint.y, // y 좌표 반전 (180도 회전)
    }));
  };

  for (let i = 0; i < joint.length; i++) {
    const jointName = joint[i];
    
    if (selectedGraph.includes(jointName)) {
      const data1 = [];
      const data2 = [];

      const xOffset = all1[0][jointName].x - all2[0][jointName].x;
      const yOffset = all1[0][jointName].y - all2[0][jointName].y;
    
      for (let frame = 0; frame <= Math.min(currentFrame1, jsonlength1-1); frame++) {
        const dataPoint1 = all1[frame][jointName];
        data1.push({ x: dataPoint1.x + 0.2, y: dataPoint1.y });
        
      }
      for (let frame = 0; frame <= Math.min(currentFrame2, jsonlength2-1); frame++) {
        const dataPoint2 = all2[frame][jointName];
        data2.push({ x: dataPoint2.x + 0.2 + xOffset, y: dataPoint2.y + yOffset});
      }

    pathData.push({name: `${jointName} - 1`, data: rotateFlipData(data1, isFlipped1)});
    pathData.push({name: `${jointName} - 2`, data: rotateFlipData(data2, isFlipped2)});
  }
}

  const [pan, setPan] = useState({ x: 0, y: 0 });

  const handlePan = (e) => {
    if (!panZoomRef.current) return;
    const { x, y } = e; // 이벤트에서 x, y를 가져옴
    
    setPan({ x, y }); // pan 상태를 설정
  };

  const handlePanOrZoom = (e) => {
    if (!panZoomRef.current) return;
    
    const { x, y, scale } = e; // onPan, onZoom 이벤트의 인자를 이용
    
    // x, y는 pan의 변화량이므로, 기존의 min, max 값을 기반으로 새로운 min, max 값을 계산합니다.
    setXMin(xMin + x / scale); // pan의 변화량을 scale로 나누어줍니다.
    setXMax(xMax + x / scale);
    setYMin(yMin + y / scale);
    setYMax(yMax + y / scale);
};

const calculateHighlightedIndices = (activeStep, stepdata, steps) => {
  const currentIndex = steps.indexOf(activeStep);
  if(currentIndex === -1) return [];

  const startIndex = currentIndex === 0 ? 0 : currentIndex - 1;
  const startFrame = steps[startIndex] === 'address' ? 0 : stepdata[steps[startIndex]];
  const endFrame = stepdata[activeStep];
  
  return [startFrame, endFrame];
}

const highlightedIndices1 = calculateHighlightedIndices(activeStep1, c, steps1);
const highlightedIndices2 = calculateHighlightedIndices(activeStep2, d, steps2);

const toggleShoulder = () => {
  setShowShoulder(!showshoulder);
}
const toggleHip = () => {
  setShowHip(!showHip);
}
const toggleKnee = () => {
  setShowKnee(!showKnee);
}
const toggleSpineAngle = () => {
  setShowSpineAngle(!showSpineAngle);
}
const getSpineLinesData1 = (data, currentFrame, isFlipped1) => {
  // 데이터의 유효성 검사
  if (!data[currentFrame]) {
    return { shoulders: [], hips: [], midLine: [] };
  }

  let leftShoulder = data[currentFrame]["left_shoulder"];
  let rightShoulder = data[currentFrame]["right_shoulder"];
  let leftHip = data[currentFrame]["left_hip"];
  let rightHip = data[currentFrame]["right_hip"];
  let leftKnee = data[currentFrame]["left_knee"];
  let rightKnee = data[currentFrame]["right_knee"];


  if(isFlipped1){
    leftShoulder = { x: 1 - leftShoulder.x, y: leftShoulder.y };
    rightShoulder = { x: 1 - rightShoulder.x, y: rightShoulder.y };
    leftHip = { x: 1 - leftHip.x, y: leftHip.y };
    rightHip = { x: 1 - rightHip.x, y: rightHip.y };
    leftKnee = { x: 1 - leftKnee.x, y: leftKnee.y };
    rightKnee = {x: 1 - rightKnee.x, y: rightKnee.y};
  }
  else{
    leftShoulder = { x: leftShoulder.x, y: leftShoulder.y };
    rightShoulder = { x: rightShoulder.x, y: rightShoulder.y };
    leftHip = { x: leftHip.x, y: leftHip.y };
    rightHip = { x: rightHip.x, y: rightHip.y };
    leftKnee = { x:leftKnee.x, y: leftKnee.y}
    rightKnee = { x: rightKnee.x, y: rightKnee.y };
  }
  // 어깨와 골반의 중점을 계산
  const midShoulder = {
    x: (leftShoulder.x + rightShoulder.x) / 2,
    y: (leftShoulder.y + rightShoulder.y) / 2
  };
  
  const midHip = {
    x: (leftHip.x + rightHip.x) / 2,
    y: (leftHip.y + rightHip.y) / 2
  };

  // 회전 및 스케일링 적용
  const rotateAndScale = (point) => ({
    x: point.x + 0.2,
    y: -point.y
  });

  return { 
    shoulders: [rotateAndScale(leftShoulder), rotateAndScale(rightShoulder)],
    hips: [rotateAndScale(leftHip), rotateAndScale(rightHip)],
    knees: [rotateAndScale(leftKnee), rotateAndScale(rightKnee)],
    midLine: [rotateAndScale(midShoulder), rotateAndScale(midHip)]
  };
}

const getSpineLinesData2 = (data, currentFrame, isFlipped2) => {
  // 데이터의 유효성 검사
  if (!data[currentFrame]) {
    return { shoulders: [], hips: [], midLine: [] };
  }
  const xLSOffset = all1?.[0]?.["left_shoulder"]?.x - all2?.[0]?.["left_shoulder"]?.x || 0;
  const yLSOffset = all1?.[0]?.["left_shoulder"]?.y - all2?.[0]?.["left_shoulder"]?.y || 0;
  
  const xRSOffset = all1?.[0]?.["right_shoulder"]?.x - all2?.[0]?.["right_shoulder"]?.x || 0;
  const yRSOffset = all1?.[0]?.["right_shoulder"]?.y - all2?.[0]?.["right_shoulder"]?.y || 0;
  
  const xLHOffset = all1?.[0]?.["left_hip"]?.x - all2?.[0]?.["left_hip"]?.x || 0;
  const yLHOffset = all1?.[0]?.["left_hip"]?.y - all2?.[0]?.["left_hip"]?.y || 0;
  
  const xRHOffset = all1?.[0]?.["right_hip"]?.x - all2?.[0]?.["right_hip"]?.x || 0;
  const yRHOffset = all1?.[0]?.["right_hip"]?.y - all2?.[0]?.["right_hip"]?.y || 0;

  const xLKOffset = all1?.[0]?.["left_Knee"]?.x - all2?.[0]?.["left_Knee"]?.x || 0;
  const yLKOffset = all1?.[0]?.["left_Knee"]?.y - all2?.[0]?.["left_Knee"]?.y || 0;

  const xRKOffset = all1?.[0]?.["right_Knee"]?.x - all2?.[0]?.["right_Knee"]?.x || 0;
  const yRKOffset = all1?.[0]?.["right_Knee"]?.x - all2?.[0]?.["right_Knee"]?.x || 0;

  let leftShoulder = data[currentFrame]["left_shoulder"];
  let rightShoulder = data[currentFrame]["right_shoulder"];
  let leftHip = data[currentFrame]["left_hip"];
  let rightHip = data[currentFrame]["right_hip"];
  let leftKnee = data[currentFrame]["left_knee"];
  let rightKnee = data[currentFrame]["right_knee"];

  if(isFlipped2){
    leftShoulder = { x: 1 - leftShoulder.x - xLSOffset, y: leftShoulder.y + yLSOffset };
    rightShoulder = { x: 1 - rightShoulder.x - xRSOffset, y: rightShoulder.y + yRSOffset };
    leftHip = { x: 1 - leftHip.x - xLHOffset, y: leftHip.y + yLHOffset };
    rightHip = { x: 1 - rightHip.x - xRHOffset, y: rightHip.y + yRHOffset };
    leftKnee = {x: 1 - leftKnee.x - xLKOffset, y: leftKnee.y + yLKOffset};
    rightKnee = {x: 1 - rightKnee.x - xRKOffset, y: rightKnee.y + yRKOffset}
  }
  else{
    leftShoulder = { x: leftShoulder.x + xLSOffset, y: leftShoulder.y + yLSOffset };
    rightShoulder = { x: rightShoulder.x + xRSOffset, y: rightShoulder.y + yRSOffset };
    leftHip = { x: leftHip.x + xLHOffset, y: leftHip.y + yLHOffset };
    rightHip = { x: rightHip.x + xRHOffset, y: rightHip.y + yRHOffset };
    leftKnee = { x: leftKnee.x + xLKOffset, y: leftKnee.y + yLKOffset}
    rightKnee = { x: rightKnee.x + xRKOffset, y: rightKnee.y + yRKOffset}
  }
  // 어깨와 골반의 중점을 계산
  const midShoulder = {
    x: (leftShoulder.x + rightShoulder.x) / 2,
    y: (leftShoulder.y + rightShoulder.y) / 2
  };
  
  const midHip = {
    x: (leftHip.x + rightHip.x) / 2,
    y: (leftHip.y + rightHip.y) / 2
  };

  // 회전 및 스케일링 적용
  const rotateAndScale = (point) => ({
    x: point.x + 0.2,
    y: -point.y
  });

  return { 
    shoulders: [rotateAndScale(leftShoulder), rotateAndScale(rightShoulder)],
    hips: [rotateAndScale(leftHip), rotateAndScale(rightHip)],
    knees: [rotateAndScale(leftKnee), rotateAndScale(rightKnee)],
    midLine: [rotateAndScale(midShoulder), rotateAndScale(midHip)]
  };
}

const spineData1 = getSpineLinesData1(all1, currentFrame1-1, isFlipped1); // 피니시 때 사라지는 거 방지
const spineData2 = getSpineLinesData2(all2, currentFrame2-1, isFlipped2); // 피니시 때 사라지는 거 방지

// 좌측 영상
if(jsonall1 && jsonall1.data && jsonall1.data.tempo && jsonall1.data.rotation_angle && jsonall1.data.path && jsonall1.data.path.ears && selectedstep){

  // angle에서는 step이 top이면 half-top해야 half~top 구간 각이 나옴
  // tempo에서는 back, down은 제외하고(이미 값으로 파싱 함) :step을 포함하면 그걸로 설정하면 됨
  if(stepidx1 > 0){
    var section1 = steps1[stepidx1 - 1] + ':' + steps1[stepidx1]; // 'half:top' 같은 형식의 문자열 생성, json파싱에 사용
    //var section2 = sec_steps[stepidx1 - 1] + '\n~' + sec_steps[stepidx1]; // 'half:top' 같은 형식의 문자열 생성, 테이블에 사용
    var sec_tempo1 = jsonall1.data.tempo.section_tempo[section1]?.toFixed(1) || '-';
    var ear_section_x1 = steps1[stepidx1 - 1] + ':' + steps1[stepidx1]+"_x";
    var ear_section_y1 = steps1[stepidx1 - 1] + ':' + steps1[stepidx1]+"_y";
    //var ear_path_angle = jsonall1.data.path.ears[section1]?.toFixed(1) || '-';

    if(jsonall1.data.rotation_angle.spine_angle[section1]){
      var sec_spine_average1 = jsonall1.data.rotation_angle.spine_angle[section1] ? jsonall1.data.rotation_angle.spine_angle[section1].toFixed(1) : '-'; // 구간별 평균 척추각
      var sec_spine_min_max1 = jsonall1.data.rotation_angle.spine_angle[section1+'_minmax'] ? jsonall1.data.rotation_angle.spine_angle[section1+'_minmax'].toFixed(1) : '-'; // 구간별 척추각의 min-max
    }
    if(jsonall1.data.path.ears[ear_section_x1] && jsonall1.data.path.ears[ear_section_y1]){
      var sec_head_backdown_x1 = jsonall1.data.path.ears[ear_section_x1] ? jsonall1.data.path.ears[ear_section_x1] : '-'
      var sec_head_backdown_y1 = jsonall1.data.path.ears[ear_section_y1] ? jsonall1.data.path.ears[ear_section_y1] : '-'
    }

    // // 현재 step - 이전 step
    // var sec_hip_updown_angle1 = jsonall1.data.rotation_angle.xy_angle.hip[steps1[stepidx1]] && jsonall1.data.rotation_angle.xy_angle.hip[steps1[stepidx1 - 1]]
    //     ? (jsonall1.data.rotation_angle.xy_angle.hip[steps1[stepidx1]] - jsonall1.data.rotation_angle.xy_angle.hip[steps1[stepidx1 - 1]]).toFixed(1)
    //     : '-';
    //   var sec_knee_updown_angle1 = jsonall1.data.rotation_angle.xy_angle.knee[steps1[stepidx1]] && jsonall1.data.rotation_angle.xy_angle.knee[steps1[stepidx1 - 1]]
    //     ? (jsonall1.data.rotation_angle.xy_angle.knee[steps1[stepidx1]] - jsonall1.data.rotation_angle.xy_angle.knee[steps1[stepidx1 - 1]]).toFixed(1)
    //     : '-';
    //   var sec_shoulder_updown_angle1 = jsonall1.data.rotation_angle.xy_angle.shoulder[steps1[stepidx1]] && jsonall1.data.rotation_angle.xy_angle.shoulder[steps1[stepidx1 - 1]]
    //     ? (jsonall1.data.rotation_angle.xy_angle.shoulder[steps1[stepidx1]] - jsonall1.data.rotation_angle.xy_angle.shoulder[steps1[stepidx1 - 1]]).toFixed(1)
    //     : '-';

    // var sec_hip_side_angle1 = (jsonall1.data.rotation_angle.xz_angle.hip[steps1[stepidx1]] - jsonall1.data.rotation_angle.xz_angle.hip[steps1[stepidx1-1]]).toFixed(1)
    // var sec_knee_side_angle1 = (jsonall1.data.rotation_angle.xz_angle.knee[steps1[stepidx1]] - jsonall1.data.rotation_angle.xz_angle.knee[steps1[stepidx1-1]]).toFixed(1)
    // var sec_shoulder_side_angle1 = (jsonall1.data.rotation_angle.xz_angle.shoulder[steps1[stepidx1]] -jsonall1.data.rotation_angle.xz_angle.shoulder[steps1[stepidx1-1]]).toFixed(1);

    // // 차이 값 절대값으로 ( 화살표로 나타내기 위해 )
    // var VerShoulder = Math.abs(sec_shoulder_updown_angle);
    // var VerHip = Math.abs(sec_hip_updown_angle);
    // var VerKnee = Math.abs(sec_knee_updown_angle);

    // var HorShoulder = Math.abs(sec_shoulder_side_angle);
    // var HorHip = Math.abs(sec_hip_side_angle);
    // var HorKnee = Math.abs(sec_knee_side_angle);
  }
  
    // var hip_side_angle1 = jsonall1.data.rotation_angle.xz_angle.hip[steps1[stepidx1]] ? jsonall1.data.rotation_angle.xz_angle.hip[steps1[stepidx1]].toFixed(1) : 0;
    // var knee_side_angle1 = jsonall1.data.rotation_angle.xz_angle.knee[steps1[stepidx1]] ? jsonall1.data.rotation_angle.xz_angle.knee[steps1[stepidx1]].toFixed(1) : 0;
    // var shoulder_side_angle1 = jsonall1.data.rotation_angle.xz_angle.shoulder[steps1[stepidx1]] ? jsonall1.data.rotation_angle.xz_angle.shoulder[steps1[stepidx1]].toFixed(1) : 0;
  
    // var hip_updown_angle1 = jsonall1.data.rotation_angle.xy_angle.hip[steps1[stepidx1]] ? jsonall1.data.rotation_angle.xy_angle.hip[steps1[stepidx1]].toFixed(1) : 0;
    // var knee_updown_angle1 = jsonall1.data.rotation_angle.xy_angle.knee[steps1[stepidx1]] ? jsonall1.data.rotation_angle.xy_angle.knee[steps1[stepidx1]].toFixed(1) : 0;
    // var shoulder_updown_angle1 = jsonall1.data.rotation_angle.xy_angle.shoulder[steps1[stepidx1]] ? jsonall1.data.rotation_angle.xy_angle.shoulder[steps1[stepidx1]].toFixed(1) : 0;
  
    
    // // 현재 - 이전 vertical angle
    // if(sec_hip_updown_angle1 > 90) sec_hip_updown_angle1 = (sec_hip_updown_angle1 - 90).toFixed(1);
    // if(sec_knee_updown_angle1 > 90) sec_knee_updown_angle1 = (sec_knee_updown_angle1 - 90).toFixed(1);
    // if(sec_shoulder_updown_angle1 > 90) sec_shoulder_updown_angle1 = (sec_shoulder_updown_angle1 - 90).toFixed(1);

}
  // 프레임 별 hip horizontal 값
  let horizontal_hip_frame1 = 0;
  if (jsonall1 && jsonall1.data && jsonall1.data.frames && jsonall1.data.frames[currentFrame1] && 
      jsonall1.data.frames[currentFrame1].point && 
      jsonall1.data.frames[currentFrame1].point.rotation_angle && 
      jsonall1.data.frames[currentFrame1].point.rotation_angle.horizontal && 
      jsonall1.data.frames[currentFrame1].point.rotation_angle.horizontal.hip !== undefined) {
    
    const hipValue = jsonall1.data.frames[currentFrame1].point.rotation_angle.horizontal.hip;
    
    horizontal_hip_frame1 = typeof hipValue === 'number'
      ? parseFloat(hipValue.toFixed(1)) || 0
      : 0;
  }

  // 프레임 별 shoulder horizontal 값
  let horizontal_shoulder_frame1 = 0;
  if (
    jsonall1 &&
    jsonall1.data &&
    jsonall1.data.frames &&
    jsonall1.data.frames[currentFrame1] &&
    jsonall1.data.frames[currentFrame1].point &&
    jsonall1.data.frames[currentFrame1].point.rotation_angle &&
    jsonall1.data.frames[currentFrame1].point.rotation_angle.horizontal &&
    jsonall1.data.frames[currentFrame1].point.rotation_angle.horizontal.shoulder !== undefined
  ) {
    const shoulderValue = jsonall1.data.frames[currentFrame1].point.rotation_angle.horizontal.shoulder;
    horizontal_shoulder_frame1 = typeof shoulderValue === 'number'
      ? parseFloat(shoulderValue.toFixed(1)) || 0
      : 0;
  }

  // 프레임 별 knee horizontal 값
  let horizontal_knee_frame1 = 0;
  if (
    jsonall1 &&
    jsonall1.data &&
    jsonall1.data.frames &&
    jsonall1.data.frames[currentFrame1] &&
    jsonall1.data.frames[currentFrame1].point &&
    jsonall1.data.frames[currentFrame1].point.rotation_angle &&
    jsonall1.data.frames[currentFrame1].point.rotation_angle.horizontal &&
    jsonall1.data.frames[currentFrame1].point.rotation_angle.horizontal.knee !== undefined
  ) {
    const kneeValue = jsonall1.data.frames[currentFrame1].point.rotation_angle.horizontal.knee;
    horizontal_knee_frame1 = typeof kneeValue === 'number'
      ? parseFloat(kneeValue.toFixed(1)) || 0
      : 0;
  }

  // 프레임 별 shoulder vertical 값
  let vertical_shoulder_frame1 = 0;
  if (
    jsonall1 &&
    jsonall1.data &&
    jsonall1.data.frames &&
    jsonall1.data.frames[currentFrame1] &&
    jsonall1.data.frames[currentFrame1].point &&
    jsonall1.data.frames[currentFrame1].point.rotation_angle &&
    jsonall1.data.frames[currentFrame1].point.rotation_angle.vertical &&
    jsonall1.data.frames[currentFrame1].point.rotation_angle.vertical.shoulder !== undefined
  ) {
    const shoulderValue = jsonall1.data.frames[currentFrame1].point.rotation_angle.vertical.shoulder;
    vertical_shoulder_frame1 = typeof shoulderValue === 'number'
      ? parseFloat(shoulderValue.toFixed(1)) || 0
      : 0;
  }

  // 프레임 별 hip vertical 값
  let vertical_hip_frame1 = 0;
  if (
    jsonall1 &&
    jsonall1.data &&
    jsonall1.data.frames &&
    jsonall1.data.frames[currentFrame1] &&
    jsonall1.data.frames[currentFrame1].point &&
    jsonall1.data.frames[currentFrame1].point.rotation_angle &&
    jsonall1.data.frames[currentFrame1].point.rotation_angle.vertical &&
    jsonall1.data.frames[currentFrame1].point.rotation_angle.vertical.hip !== undefined
  ) {
    const hipValue = jsonall1.data.frames[currentFrame1].point.rotation_angle.vertical.hip;
    vertical_hip_frame1 = typeof hipValue === 'number'
      ? parseFloat(hipValue.toFixed(1)) || 0
      : 0;
  }

  // 프레임 별 평균 척추각
  let sec_spine_average_frame1;
  if (
    jsonall1 &&
    jsonall1.data &&
    jsonall1.data.frames &&
    jsonall1.data.frames[currentFrame1] &&
    jsonall1.data.frames[currentFrame1].point &&
    jsonall1.data.frames[currentFrame1].point.spine_angle &&
    jsonall1.data.frames[currentFrame1].point.spine_angle.value !== undefined
  ) {
    sec_spine_average_frame1 = jsonall1.data.frames[currentFrame1].point.spine_angle.value.toFixed(1);
  }

  // 프레임 별 head
  let sec_head_backdown_x_frame1 = sec_head_backdown_x1;
  let sec_head_backdown_y_frame1 = sec_head_backdown_y1;
  if (
    jsonall1 &&
    jsonall1.data &&
    jsonall1.data.frames &&
    jsonall1.data.frames[currentFrame1] &&
    jsonall1.data.frames[currentFrame1].point &&
    jsonall1.data.frames[currentFrame1].point.head
  ) {
    sec_head_backdown_x_frame1 = jsonall1.data.frames[currentFrame1].point.head.x !== undefined
      ? jsonall1.data.frames[currentFrame1].point.head.x
      : sec_head_backdown_x1;
    sec_head_backdown_y_frame1 = jsonall1.data.frames[currentFrame1].point.head.y !== undefined
      ? jsonall1.data.frames[currentFrame1].point.head.y
      : sec_head_backdown_y1;
  }


// fixed values
if(jsonall1.data && jsonall1.data.rotation_angle.spine_angle.total && jsonall1.data.rotation_angle.spine_angle.total_minmax){

  // TEMPO
  var back_tempo1 = jsonall1.data.tempo.back_down_tempo.back.toFixed(1); // 어드레스~탑 백스윙 템포  **파싱 필요없음**
  var down_tempo1 = jsonall1.data.tempo.back_down_tempo.down.toFixed(1); // 탑~임팩트 다운스윙 템포  **파싱 필요없음**
  // var ear_back_angle = jsonall1.data.path.ears.back.toFixed(1);
  // var ear_down_angle = jsonall1.data.path.ears.down.toFixed(1);

  // POSITION
  var top_positon1 = jsonall1.data.position ? jsonall1.data.position.toUpperCase(): '';
  var top_spine1 = jsonall1.data.rotation_angle.spine_angle.total ? jsonall1.data.rotation_angle.spine_angle.total.toFixed(1) : '';
  
  // SWINGPATH
  var downswing_angle1 = jsonall1.data.path.rwrist["down_swing_angle"] ? jsonall1.data.path.rwrist["down_swing_angle"].toFixed(1) : '';
  var upswing_angle1 = jsonall1.data.path.rwrist["finish_swing_angle"] ? jsonall1.data.path.rwrist["finish_swing_angle"].toFixed(1) : '';
  let raw_up_to_down1 = jsonall1.data.path.rwrist.area ? jsonall1.data.path.rwrist.area : '';
  if(typeof raw_up_to_down1 === 'string'){
    let prefix1 = raw_up_to_down1.match(/[^\d.-]+/)[0];
    let numericPart1 = parseFloat(raw_up_to_down1.match(/-?\d+\.?\d*/));
    let absoluteValue1 = Math.abs(numericPart1).toFixed(2);
    var up_to_down1 = prefix1 + absoluteValue1;
  }
  let raw_down_to_up1 = jsonall1.data.path.rwrist.area_finish ? jsonall1.data.path.rwrist.area_finish : '';
    if(raw_down_to_up1){
      if(typeof raw_down_to_up1 === 'string'){
        let prefix = raw_down_to_up1.match(/[^\d.-]+/)[0];
        let numericPart = parseFloat(raw_down_to_up1.match(/-?\d+\.?\d*/));
        let absoluteValue = Math.abs(numericPart).toFixed(2);
        var down_to_up1 = prefix + absoluteValue;
      }
    }

  //var total_average = jsonall1.data.rotation_angle.spine_angle.total.toFixed(1); // 전체 평균 척추각
  // FLEXABILITY에 들어가는 값
  var total_min_max1 = jsonall1.data.rotation_angle.spine_angle.total_minmax.toFixed(1); // 전체 척추각의 max-min
  var head_backdown_y1 = jsonall1.data.path.ears.back_down_y;
  var head_backdown_x1 = jsonall1.data.path.ears.back_down_x;
  var right_knee1 = jsonall1.data.rotation_angle.knee ? jsonall1.data.rotation_angle.knee.toFixed(1) : '-';
    if(jsonall1.data.rotation_angle.knee === 0)
    {
      right_knee1 = 0;
    }
  var address_spine1= jsonall1.data.rotation_angle.spine_angle.address.toFixed(1); // address의 척추각
}



// 우측 영상
if(jsonall2 && jsonall2.data && jsonall2.data.tempo && jsonall2.data.rotation_angle && jsonall2.data.path && jsonall2.data.path.ears && selectedstep){

  // angle에서는 step이 top이면 half-top해야 half~top 구간 각이 나옴
  // tempo에서는 back, down은 제외하고(이미 값으로 파싱 함) :step을 포함하면 그걸로 설정하면 됨
  if(stepidx2 > 0){
    var section2 = steps2[stepidx2 - 1] + ':' + steps2[stepidx2]; // 'half:top' 같은 형식의 문자열 생성, json파싱에 사용
    //var section2 = sec_steps[stepidx2 - 1] + '\n~' + sec_steps[stepidx2]; // 'half:top' 같은 형식의 문자열 생성, 테이블에 사용
    var sec_tempo2 = jsonall2.data.tempo.section_tempo[section2]?.toFixed(1) || '-';
    var ear_section_x2 = steps2[stepidx2 - 1] + ':' + steps2[stepidx2]+"_x";
    var ear_section_y2 = steps2[stepidx2 - 1] + ':' + steps2[stepidx2]+"_y";
    //var ear_path_angle = jsonall2.data.path.ears[section2]?.toFixed(1) || '-';

    if(jsonall2.data.rotation_angle.spine_angle[section2]){
      var sec_spine_average2 = jsonall2.data.rotation_angle.spine_angle[section2] ? jsonall2.data.rotation_angle.spine_angle[section2].toFixed(1) : '-'; // 구간별 평균 척추각
      var sec_spine_min_max2 = jsonall2.data.rotation_angle.spine_angle[section2+'_minmax'] ? jsonall2.data.rotation_angle.spine_angle[section2+'_minmax'].toFixed(1) : '-'; // 구간별 척추각의 min-max
    }
    if(jsonall2.data.path.ears[ear_section_x2] && jsonall2.data.path.ears[ear_section_y2]){
      var sec_head_backdown_x2 = jsonall2.data.path.ears[ear_section_x2] ? jsonall2.data.path.ears[ear_section_x2] : '-'
      var sec_head_backdown_y2 = jsonall2.data.path.ears[ear_section_y2] ? jsonall2.data.path.ears[ear_section_y2] : '-'
    }

    // // 현재 step - 이전 step
    // var sec_hip_updown_angle2 = jsonall2.data.rotation_angle.xy_angle.hip[steps2[stepidx2]] && jsonall2.data.rotation_angle.xy_angle.hip[steps2[stepidx2 - 1]]
    // ? (jsonall2.data.rotation_angle.xy_angle.hip[steps2[stepidx2]] - jsonall2.data.rotation_angle.xy_angle.hip[steps2[stepidx2 - 1]]).toFixed(1)
    // : '-';
    // var sec_knee_updown_angle2 = jsonall2.data.rotation_angle.xy_angle.knee[steps2[stepidx2]] && jsonall2.data.rotation_angle.xy_angle.knee[steps2[stepidx2 - 1]]
    //   ? (jsonall2.data.rotation_angle.xy_angle.knee[steps2[stepidx2]] - jsonall2.data.rotation_angle.xy_angle.knee[steps2[stepidx2 - 1]]).toFixed(1)
    //   : '-';
    // var sec_shoulder_updown_angle2 = jsonall2.data.rotation_angle.xy_angle.shoulder[steps2[stepidx2]] && jsonall2.data.rotation_angle.xy_angle.shoulder[steps2[stepidx2 - 1]]
    //   ? (jsonall2.data.rotation_angle.xy_angle.shoulder[steps2[stepidx2]] - jsonall2.data.rotation_angle.xy_angle.shoulder[steps2[stepidx2 - 1]]).toFixed(1)
    //   : '-';

    // var sec_hip_side_angle2 = (jsonall2.data.rotation_angle.xz_angle.hip[steps2[stepidx2]] - jsonall2.data.rotation_angle.xz_angle.hip[steps2[stepidx2-1]]).toFixed(1)
    // var sec_knee_side_angle2 = (jsonall2.data.rotation_angle.xz_angle.knee[steps2[stepidx2]] - jsonall2.data.rotation_angle.xz_angle.knee[steps2[stepidx2-1]]).toFixed(1)
    // var sec_shoulder_side_angle2 = (jsonall2.data.rotation_angle.xz_angle.shoulder[steps2[stepidx2]] -jsonall2.data.rotation_angle.xz_angle.shoulder[steps2[stepidx2-1]]).toFixed(1);

    // // 차이 값 절대값으로 ( 화살표로 나타내기 위해 )
    // var VerShoulder = Math.abs(sec_shoulder_updown_angle);
    // var VerHip = Math.abs(sec_hip_updown_angle);
    // var VerKnee = Math.abs(sec_knee_updown_angle);

    // var HorShoulder = Math.abs(sec_shoulder_side_angle);
    // var HorHip = Math.abs(sec_hip_side_angle);
    // var HorKnee = Math.abs(sec_knee_side_angle);
  }
  
  // var hip_side_angle2 = jsonall2.data.rotation_angle.xz_angle.hip[steps2[stepidx2]] ? jsonall2.data.rotation_angle.xz_angle.hip[steps2[stepidx2]].toFixed(1) : 0;
  // var knee_side_angle2 = jsonall2.data.rotation_angle.xz_angle.knee[steps2[stepidx2]] ? jsonall2.data.rotation_angle.xz_angle.knee[steps2[stepidx2]].toFixed(1) : 0;
  // var shoulder_side_angle2 = jsonall2.data.rotation_angle.xz_angle.shoulder[steps2[stepidx2]] ? jsonall2.data.rotation_angle.xz_angle.shoulder[steps2[stepidx2]].toFixed(1) : 0;

  // var hip_updown_angle2 = jsonall2.data.rotation_angle.xy_angle.hip[steps2[stepidx2]] ? jsonall2.data.rotation_angle.xy_angle.hip[steps2[stepidx2]].toFixed(1) : 0;
  // var knee_updown_angle2 = jsonall2.data.rotation_angle.xy_angle.knee[steps2[stepidx2]] ? jsonall2.data.rotation_angle.xy_angle.knee[steps2[stepidx2]].toFixed(1) : 0;
  // var shoulder_updown_angle2 = jsonall2.data.rotation_angle.xy_angle.shoulder[steps2[stepidx2]] ? jsonall2.data.rotation_angle.xy_angle.shoulder[steps2[stepidx2]].toFixed(1) : 0;

    
  //   // 현재 - 이전 vertical angle
  //   if(sec_hip_updown_angle2 > 90) sec_hip_updown_angle2 = (sec_hip_updown_angle2 - 90).toFixed(1);
  //   if(sec_knee_updown_angle2 > 90) sec_knee_updown_angle2 = (sec_knee_updown_angle2 - 90).toFixed(1);
  //   if(sec_shoulder_updown_angle2 > 90) sec_shoulder_updown_angle2 = (sec_shoulder_updown_angle2 - 90).toFixed(1);

}

  // 프레임 별 hip horizontal 값
  let horizontal_hip_frame2 = 0;
  if (jsonall2 && jsonall2.data && jsonall2.data.frames && jsonall2.data.frames[currentFrame2] && 
      jsonall2.data.frames[currentFrame2].point && 
      jsonall2.data.frames[currentFrame2].point.rotation_angle && 
      jsonall2.data.frames[currentFrame2].point.rotation_angle.horizontal && 
      jsonall2.data.frames[currentFrame2].point.rotation_angle.horizontal.hip !== undefined) {
    
    const hipValue = jsonall2.data.frames[currentFrame2].point.rotation_angle.horizontal.hip;
    
    horizontal_hip_frame2 = typeof hipValue === 'number'
      ? parseFloat(hipValue.toFixed(1)) || 0
      : 0;
  }

  // 프레임 별 shoulder horizontal 값
  let horizontal_shoulder_frame2 = 0;
  if (
    jsonall2 &&
    jsonall2.data &&
    jsonall2.data.frames &&
    jsonall2.data.frames[currentFrame2] &&
    jsonall2.data.frames[currentFrame2].point &&
    jsonall2.data.frames[currentFrame2].point.rotation_angle &&
    jsonall2.data.frames[currentFrame2].point.rotation_angle.horizontal &&
    jsonall2.data.frames[currentFrame2].point.rotation_angle.horizontal.shoulder !== undefined
  ) {
    const shoulderValue = jsonall2.data.frames[currentFrame2].point.rotation_angle.horizontal.shoulder;
    horizontal_shoulder_frame2 = typeof shoulderValue === 'number'
      ? parseFloat(shoulderValue.toFixed(1)) || 0
      : 0;
  }

  // 프레임 별 knee horizontal 값
  let horizontal_knee_frame2 = 0;
  if (
    jsonall2 &&
    jsonall2.data &&
    jsonall2.data.frames &&
    jsonall2.data.frames[currentFrame2] &&
    jsonall2.data.frames[currentFrame2].point &&
    jsonall2.data.frames[currentFrame2].point.rotation_angle &&
    jsonall2.data.frames[currentFrame2].point.rotation_angle.horizontal &&
    jsonall2.data.frames[currentFrame2].point.rotation_angle.horizontal.knee !== undefined
  ) {
    const kneeValue = jsonall2.data.frames[currentFrame2].point.rotation_angle.horizontal.knee;
    horizontal_knee_frame2 = typeof kneeValue === 'number'
      ? parseFloat(kneeValue.toFixed(1)) || 0
      : 0;
  }

  // 프레임 별 shoulder vertical 값
  let vertical_shoulder_frame2 = 0;
  if (
    jsonall2 &&
    jsonall2.data &&
    jsonall2.data.frames &&
    jsonall2.data.frames[currentFrame2] &&
    jsonall2.data.frames[currentFrame2].point &&
    jsonall2.data.frames[currentFrame2].point.rotation_angle &&
    jsonall2.data.frames[currentFrame2].point.rotation_angle.vertical &&
    jsonall2.data.frames[currentFrame2].point.rotation_angle.vertical.shoulder !== undefined
  ) {
    const shoulderValue = jsonall2.data.frames[currentFrame2].point.rotation_angle.vertical.shoulder;
    vertical_shoulder_frame2 = typeof shoulderValue === 'number'
      ? parseFloat(shoulderValue.toFixed(1)) || 0
      : 0;
  }

  // 프레임 별 hip vertical 값
  let vertical_hip_frame2 = 0;
  if (
    jsonall2 &&
    jsonall2.data &&
    jsonall2.data.frames &&
    jsonall2.data.frames[currentFrame2] &&
    jsonall2.data.frames[currentFrame2].point &&
    jsonall2.data.frames[currentFrame2].point.rotation_angle &&
    jsonall2.data.frames[currentFrame2].point.rotation_angle.vertical &&
    jsonall2.data.frames[currentFrame2].point.rotation_angle.vertical.hip !== undefined
  ) {
    const hipValue = jsonall2.data.frames[currentFrame2].point.rotation_angle.vertical.hip;
    vertical_hip_frame2 = typeof hipValue === 'number'
      ? parseFloat(hipValue.toFixed(1)) || 0
      : 0;
  }

  // 프레임 별 평균 척추각
  let sec_spine_average_frame2;
  if (
    jsonall2 &&
    jsonall2.data &&
    jsonall2.data.frames &&
    jsonall2.data.frames[currentFrame2] &&
    jsonall2.data.frames[currentFrame2].point &&
    jsonall2.data.frames[currentFrame2].point.spine_angle &&
    jsonall2.data.frames[currentFrame2].point.spine_angle.value !== undefined
  ) {
    sec_spine_average_frame2 = jsonall2.data.frames[currentFrame2].point.spine_angle.value.toFixed(1);
  }

  // 프레임 별 head
  let sec_head_backdown_x_frame2 = sec_head_backdown_x2;
  let sec_head_backdown_y_frame2 = sec_head_backdown_y2;
  if (
    jsonall2 &&
    jsonall2.data &&
    jsonall2.data.frames &&
    jsonall2.data.frames[currentFrame2] &&
    jsonall2.data.frames[currentFrame2].point &&
    jsonall2.data.frames[currentFrame2].point.head
  ) {
    sec_head_backdown_x_frame2 = jsonall2.data.frames[currentFrame2].point.head.x !== undefined
      ? jsonall2.data.frames[currentFrame2].point.head.x
      : sec_head_backdown_x2;
    sec_head_backdown_y_frame2 = jsonall2.data.frames[currentFrame2].point.head.y !== undefined
      ? jsonall2.data.frames[currentFrame2].point.head.y
      : sec_head_backdown_y2;
  }

// fixed values
if(jsonall2.data && jsonall2.data.rotation_angle.spine_angle.total && jsonall2.data.rotation_angle.spine_angle.total_minmax){

  // TEMPO
  var back_tempo2 = jsonall2.data.tempo.back_down_tempo.back.toFixed(1); // 어드레스~탑 백스윙 템포  **파싱 필요없음**
  var down_tempo2 = jsonall2.data.tempo.back_down_tempo.down.toFixed(1); // 탑~임팩트 다운스윙 템포  **파싱 필요없음**
  // var ear_back_angle = jsonall2.data.path.ears.back.toFixed(1);
  // var ear_down_angle = jsonall2.data.path.ears.down.toFixed(1);

  // POSITION
  var top_positon2 = jsonall2.data.position ? jsonall2.data.position.toUpperCase() : '';
  var top_spine2 = jsonall2.data.rotation_angle.spine_angle.total ? jsonall2.data.rotation_angle.spine_angle.total.toFixed(1) : '';
  
  // SWINGPATH
  var downswing_angle2 = jsonall2.data.path.rwrist["down_swing_angle"] ? jsonall2.data.path.rwrist["down_swing_angle"].toFixed(1) : '';
  var upswing_angle2 = jsonall2.data.path.rwrist["finish_swing_angle"] ? jsonall2.data.path.rwrist["finish_swing_angle"].toFixed(1) : '';
  let raw_up_to_down2 = jsonall2.data.path.rwrist.area ? jsonall2.data.path.rwrist.area : '';
  if(typeof raw_up_to_down2 === 'string'){
    let prefix2 = raw_up_to_down2.match(/[^\d.-]+/)[0];
    let numericPart2 = parseFloat(raw_up_to_down2.match(/-?\d+\.?\d*/));
    let absoluteValue2 = Math.abs(numericPart2).toFixed(2);
    var up_to_down2 = prefix2 + absoluteValue2;
  }
  let raw_down_to_up2 = jsonall2.data.path.rwrist.area_finish ? jsonall2.data.path.rwrist.area_finish : '';
    if(raw_down_to_up2){
      if(typeof raw_down_to_up2 === 'string'){
        let prefix = raw_down_to_up2.match(/[^\d.-]+/)[0];
        let numericPart = parseFloat(raw_down_to_up2.match(/-?\d+\.?\d*/));
        let absoluteValue = Math.abs(numericPart).toFixed(2);
        var down_to_up2 = prefix + absoluteValue;
      }
    }

  //var total_average = jsonall2.data.rotation_angle.spine_angle.total.toFixed(1); // 전체 평균 척추각
  // FLEXABILITY에 들어가는 값
  var total_min_max2 = jsonall2.data.rotation_angle.spine_angle.total_minmax.toFixed(1); // 전체 척추각의 max-min
  var head_backdown_y2 = jsonall2.data.path.ears.back_down_y ? jsonall2.data.path.ears.back_down_y : '';
  var head_backdown_x2 = jsonall2.data.path.ears.back_down_x ? jsonall2.data.path.ears.back_down_x : '';
  var right_knee2 = jsonall2.data.rotation_angle.knee ? jsonall2.data.rotation_angle.knee.toFixed(1) : '-';
    if(jsonall2.data.rotation_angle.knee === 0)
    {
      right_knee2 = 0;
    }
  var address_spine2= jsonall2.data.rotation_angle.spine_angle.address.toFixed(1); // address의 척추각
}
  return (
    <>
      <div className='Cbackangle-display1'>
        {all1[currentFrame1] && currentFrame1 <= jsonlength1 && ( <p style={{ color: 'black' }}> 
        CurrentFrame: {currentFrame1} </p>)} {/*, Back_Angle: {all1[currentFrame1].back_angle.value.toFixed(2)} */}
      </div>

      <div className='Cbackangle-display2'>
        {all2[currentFrame2] && currentFrame2 < jsonlength2 && ( <p style={{ color: 'black' }}> 
        CurrentFrame: {currentFrame2}</p> )}
      </div>

    <div className='graph-container'>
      {visibleTables === 'visual' && (
      <div className = 'Cchooseone-button'>
        <button style ={{backgroundColor:"red"}} onClick={() => setVisibleGraphs('left')}>LEFT</button>
        <button style ={{backgroundColor:"yellow"}} onClick={() => setVisibleGraphs('right')}>RIGHT</button>
        <button style ={{border:'4px solid white'}} onClick={() => setVisibleGraphs('both')}>BOTH</button>
      </div>
      )}

      <div className = 'Cchoosetable-button'>
        <button onClick={() => {setVisibleTables('visual'); changeVisibleTable('visual');}}>VISUAL</button>
        <button onClick={() => {setVisibleTables('summary'); changeVisibleTable('summary');}}>SUMMARY</button>
        <button onClick={() => {setVisibleTables('perstep'); changeVisibleTable('perstep');}}>PER_STEP</button>
      </div>

      {visibleTables === 'visual' && (
        <div className='Cbackangle-button'>
          {/* 어깨 보기 */}
          <div className="switch-container">
            <label className="switch" style={{ color: 'white', marginTop: '1.25rem', marginLeft: '2rem' }}>
              <input
                type="checkbox"
                checked={showshoulder}
                onChange={toggleShoulder}
              />
              <span className="slider"></span>
            </label>
            <span style={{ marginTop: '1rem' }} className="switch-label">어깨</span>
          </div>

          {/* 골반 보기 */}
          <div className="switch-container">
            <label className="switch" style={{ color: 'white', marginTop: '1.25rem', marginLeft: '2rem' }}>
              <input
                type="checkbox"
                checked={showHip}
                onChange={toggleHip}
              />
              <span className="slider"></span>
            </label>
            <span style={{ marginTop: '1rem' }} className="switch-label">골반</span>
          </div>

          {/* 무릎 보기 */}
          <div className="switch-container">
            <label className="switch" style={{ color: 'white', marginTop: '1.25rem', marginLeft: '2rem' }}>
              <input
                type="checkbox"
                checked={showKnee}
                onChange={toggleKnee}
              />
              <span className="slider"></span>
            </label>
            <span style={{ marginTop: '1rem' }} className="switch-label">무릎</span>
          </div>

          {/* 척추각 보기 */}
          <div className="switch-container">
            <label className="switch" style={{ color: 'white', marginTop: '1.25rem', marginLeft: '2rem' }}>
              <input
                type="checkbox"
                checked={showSpineAngle}
                onChange={toggleSpineAngle}
              />
              <span className="slider"></span>
            </label>
            <span style={{ marginTop: '1rem' }} className="switch-label">척추각</span>
          </div>
        </div>
      )}

      {visibleTables === 'visual' && (
      <div style={{ overflow: 'hidden', position: 'relative' }}>
        <PanZoom ref={panZoomRef} onPan={handlePan} onZoom={handlePanOrZoom} style={{ display: 'inline-block', transformOrigin: '0 0' }}>
        <LineChart width={graphSize.width} height={graphSize.height}>
          <XAxis type='number' dataKey='x' domain={[xMin, 1]} />
          <YAxis type='number' dataKey='y' domain={[yMin, yMax]} />
          <CartesianGrid strokeDasharray='3 3' />
          <Tooltip content={() => null} />
          <Legend />

          {pathData.map((data, index) => {
              if ((visibleGraphs === 'left' && index % 2 !== 0) || (visibleGraphs === 'right' && index % 2 === 0)) {
                return null; // 렌더링하지 않음
              }
              return (
                <>
                  <Line
                    key={`${index}-normal`}
                    type='linear'
                    data={data.data}
                    dataKey='y'
                    name=''
                    stroke={index % 2 === 0 ? 'red' : 'yellow'}
                    activeDot={false}
                    dot={false}
                    strokeWidth={1}
                  />
                  <Line
                    key={`${index}-highlighted`}
                    type='linear'
                    data={data.data.slice(highlightedIndices1[0], highlightedIndices1[1])}
                    dataKey='y'
                    name=''
                    stroke={index % 2 === 0 ? 'red' : 'yellow'}
                    activeDot={false}
                    dot={false}
                    strokeWidth={3}
                  />
                  <Line
                    key={`${index}-highlighted2`}
                    type='linear'
                    data={data.data.slice(highlightedIndices2[0], highlightedIndices2[1])}
                    dataKey='y'
                    name=''
                    stroke={index % 2 === 0 ? 'red' : 'yellow'}
                    activeDot={false}
                    dot={false}
                    strokeWidth={3}
                  />
                </>
              );
            })}
            {(showSpineAngle || showshoulder) && (visibleGraphs === 'left' || visibleGraphs === 'both') && spineData1.shoulders.length === 2 && (
                <Line
                  type="linear"
                  data={spineData1.shoulders}
                  dataKey="y"
                  stroke="red"
                  dot={false}
                  activeDot={false}
                  strokeWidth={2}
                />
              )}
              {(showSpineAngle || showHip) && (visibleGraphs === 'left' || visibleGraphs === 'both') && spineData1.hips.length === 2 && (
                <Line
                  type="linear"
                  data={spineData1.hips}
                  dataKey="y"
                  stroke="red"
                  dot={false}
                  activeDot={false}
                  strokeWidth={2}
                />
              )}
              {showSpineAngle && (visibleGraphs === 'left' || visibleGraphs === 'both') && spineData1.midLine.length === 2 && (
                <Line
                  type="linear"
                  data={spineData1.midLine}
                  dataKey="y"
                  stroke="red"
                  dot={false}
                  activeDot={false}
                  strokeWidth={3}
                />
              )}
              {showKnee && (visibleGraphs === 'left' || visibleGraphs === 'both') && (
                <Line
                  type="linear"
                  data={spineData1.knees}
                  dataKey="y"
                  stroke="red"
                  dot={false}
                  activeDot={false}
                  strokeWidth={3}
                />
              )}
              {(showSpineAngle || showshoulder) && (visibleGraphs === 'right' || visibleGraphs === 'both') && spineData2.shoulders.length === 2 && (
                <Line
                  type="linear"
                  data={spineData2.shoulders}
                  dataKey="y"
                  stroke="yellow"
                  dot={false}
                  activeDot={false}
                  strokeWidth={2}
                />
              )}
              {(showSpineAngle || showHip) && (visibleGraphs === 'right' || visibleGraphs === 'both') && spineData2.hips.length === 2 && (
                <Line
                  type="linear"
                  data={spineData2.hips}
                  dataKey="y"
                  stroke="yellow"
                  dot={false}
                  activeDot={false}
                  strokeWidth={2}
                />
              )}
              {showSpineAngle && (visibleGraphs === 'right' || visibleGraphs === 'both') && spineData2.midLine.length === 2 && (
                <Line
                  type="linear"
                  data={spineData2.midLine}
                  dataKey="y"
                  stroke="yellow"
                  dot={false}
                  activeDot={false}
                  strokeWidth={3}
                />
              )}
              {showKnee && (visibleGraphs === 'right' || visibleGraphs === 'both') && (
                <Line
                  type="linear"
                  data={spineData2.knees}
                  dataKey="y"
                  stroke="yellow"
                  dot={false}
                  activeDot={false}
                  strokeWidth={3}
                />
              )}

        </LineChart>
      </PanZoom>
    </div>
      )}
      </div>
      {visibleTables === 'summary' && (
        <div className = 'Csummary-container'>
        <table className = "table-summary">
        <thread>
          <tr>
            <td style={{color : 'red'}}>LEFT</td>
            <td colSpan="3" style={{backgroundColor: 'gray', color : 'white'}}>ITEM</td>
            <td style={{color : 'yellow'}}>RIGHT</td>
          </tr>
          <tr>
            <td style={{color : 'cyan'}}>{back_tempo1 !== undefined && back_tempo1 !== null ? back_tempo1 : '-'}:1</td>
            <td colSpan="3" style={{backgroundColor: 'gray', color : 'white'}}>TEMPO</td>
            <td style={{color : 'cyan'}}>{back_tempo2 !== undefined && back_tempo2 !== null ? back_tempo2 : '-'}:1</td>
          </tr>
          <tr>
            <td style={{color : 'cyan'}}>{top_positon1 !== undefined && top_positon1 !== null ? top_positon1 : '-'}</td>
            <td colSpan="3" style={{backgroundColor: 'gray', color : 'white'}}>TOP</td>
            <td style={{color : 'cyan'}}>{top_positon2 !== undefined && top_positon2 !== null ? top_positon2 : '-'}</td>
          </tr>
          <tr>
            <td style={{color : 'cyan'}}>{top_spine1 !== undefined && top_spine1 !== null ? top_spine1 + '°' : '-'}</td>
            <td colSpan="3" style={{backgroundColor: 'gray', color : 'white'}}>SPINE ANGLE</td>
            <td style={{color : 'cyan'}}>{top_spine2 !== undefined && top_spine2 !== null ? top_spine2 + '°' : '-'}</td>
          </tr>
          <tr>
            <td style={{color : 'cyan'}}>{downswing_angle1 !== undefined && downswing_angle1 !== null ? downswing_angle1 + '°' : '-'}</td>
            <td colSpan="3" style={{backgroundColor: 'gray', color : 'white'}}>DOWN INCLINE</td>
            <td style={{color : 'cyan'}}>{downswing_angle2 !== undefined && downswing_angle2 !== null ? downswing_angle2 + '°' : '-'}</td>
          </tr>
          <tr>
            <td style={{color : 'cyan'}}>{up_to_down1 !== undefined && up_to_down1 !== null ? up_to_down1 : '-'}</td>
            <td colSpan="3" style={{backgroundColor: 'gray', color : 'white'}}>TOP to IMPACT</td>
            <td style={{color : 'cyan'}}>{up_to_down2 !== undefined && up_to_down2 !== null ? up_to_down2 : '-'}</td>
          </tr>
          <tr>
            <td style={{color : 'cyan'}}>{upswing_angle1 !== undefined && upswing_angle1 !== null ? upswing_angle1 + '°' : '-'}</td>
            <td colSpan="3" style={{backgroundColor: 'gray', color : 'white'}}>UP INCLINE</td>
            <td style={{color : 'cyan'}}>{upswing_angle2 !== undefined && upswing_angle2 !== null ? upswing_angle2 + '°' : '-'}</td>
          </tr>
          <tr>
            <td style={{color : 'cyan'}}>{down_to_up1 !== undefined && down_to_up1 !== null ? down_to_up1 : '-'}</td>
            <td colSpan="3" style={{backgroundColor: 'gray', color : 'white'}}>IMPACT to FINISH</td>
            <td style={{color : 'cyan'}}>{down_to_up2 !== undefined && down_to_up2 !== null ? down_to_up2 : '-'}</td>
          </tr>
          <tr>
            <td style={{color : 'cyan'}}>{total_min_max1 !== undefined && total_min_max1 !== null ? total_min_max1 : '-'}</td>
            <td colSpan="3" style={{backgroundColor: 'gray', color : 'white'}}>SPINE FIX</td>
            <td style={{color : 'cyan'}}>{total_min_max2 !== undefined && total_min_max2 !== null ? total_min_max2 : '-'}</td>
          </tr>
          <tr>
            <td style={{color : 'cyan'}}>{head_backdown_y1 !== undefined && head_backdown_y1 !== null ? head_backdown_y1 : '-'}</td>
            <td colSpan="3" rowSpan="2" style={{backgroundColor: 'gray', color : 'white'}}>HEAD</td>
            <td style={{color : 'cyan'}}>{head_backdown_y2 !== undefined && head_backdown_y2 !== null ? head_backdown_y2 : '-'}</td>
          </tr>
          <tr>
            <td style={{color : 'cyan'}}>{head_backdown_x1 !== undefined && head_backdown_x1 !== null ? head_backdown_x1 : '-'}</td>
            <td style={{color : 'cyan'}}>{head_backdown_x2 !== undefined && head_backdown_x2 !== null ? head_backdown_x2 : '-'}</td>
          </tr>
        </thread>

          </table>
        </div>
      )}

      {visibleTables === 'perstep' && (
      <div className = 'Cperstep-container'>
        <table className = "table-perstep">
          <thread>
            <tr>
              <td style={{color : 'red'}}>LEFT</td>
              <td colSpan="3" style={{backgroundColor: 'gray', color : 'white'}}>ITEM</td>
              <td style={{color : 'yellow'}}>RIGHT</td>
            </tr>
            <tr>
              <td>{sec_tempo1 !== undefined && sec_tempo1 !== null ? sec_tempo1 : '-'} : 1</td>
              <td colSpan="3" style={{backgroundColor: 'gray', color : 'white'}}>TEMPO</td>
              <td>{sec_tempo2 !== undefined && sec_tempo2 !== null ? sec_tempo2 : '-'} : 1</td>
            </tr>
            <tr>
              <td>{horizontal_shoulder_frame1 !== undefined && horizontal_shoulder_frame1 !== null ? horizontal_shoulder_frame1 + '°' : '-'}</td>
              <td style={{backgroundColor: 'gray', color : 'white'}}>SHOULDER</td>
              <td rowspan="3" style={{backgroundColor: 'gray', color : 'white'}}>H. ANGLE</td>
              <td style={{backgroundColor: 'gray', color : 'white'}}>SHOULDER</td>
              <td>{horizontal_shoulder_frame2 !== undefined && horizontal_shoulder_frame2 !== null ? horizontal_shoulder_frame2 + '°' : '-'}</td>
            </tr>
            <tr>
              <td>{horizontal_hip_frame1 !== undefined && horizontal_hip_frame1 !== null ? horizontal_hip_frame1 + '°' : '-'}</td>
              <td style={{backgroundColor: 'gray', color : 'white'}}>HIP</td>
              <td style={{backgroundColor: 'gray', color : 'white'}}>HIP</td>
              <td>{horizontal_hip_frame2 !== undefined && horizontal_hip_frame2 !== null ? horizontal_hip_frame2 + '°' : '-'}</td>
            </tr>
            <tr>
              <td>{horizontal_knee_frame1 !== undefined && horizontal_knee_frame1 !== null ? horizontal_knee_frame1 + '°' : '-'}</td>
              <td style={{backgroundColor: 'gray', color : 'white'}}>KNEE</td>
              <td style={{backgroundColor: 'gray', color : 'white'}}>KNEE</td>
              <td>{horizontal_knee_frame2 !== undefined && horizontal_knee_frame2 !== null ? horizontal_knee_frame2 + '°' : '-'}</td>
            </tr>
            <tr>
              <td>{vertical_shoulder_frame1 !== undefined && vertical_shoulder_frame1 !== null ? vertical_shoulder_frame1 + '°' : '-'}</td>
              <td style={{backgroundColor: 'gray', color : 'white'}}>SHOULDER</td>
              <td rowspan="2" style={{backgroundColor: 'gray', color : 'white'}}>V ANGLE</td>
              <td style={{backgroundColor: 'gray', color : 'white'}}>SHOULDER</td>
              <td>{vertical_shoulder_frame2 !== undefined && vertical_shoulder_frame2 !== null ? vertical_shoulder_frame2 + '°' : '-'}</td>
            </tr>
            <tr>
              <td>{vertical_hip_frame1 !== undefined && vertical_hip_frame1 !== null ? vertical_hip_frame1 + '°' : '-'}</td>
              <td style={{backgroundColor: 'gray', color : 'white'}}>HIP</td>
              <td style={{backgroundColor: 'gray', color : 'white'}}>HIP</td>
              <td>{vertical_hip_frame2 !== undefined && vertical_hip_frame2 !== null ? vertical_hip_frame2 + '°' : '-'}</td>
            </tr>
            <tr>
              <td>{sec_spine_average_frame1 !== undefined && sec_spine_average_frame1 !== null ? sec_spine_average_frame1 + '°' : '-'}</td>
              <td colSpan="3" style={{backgroundColor: 'gray', color : 'white'}}>SPINE ANGLE</td>
              <td>{sec_spine_average_frame2 !== undefined && sec_spine_average_frame2 !== null ? sec_spine_average_frame2 + '°' : '-'}</td>
            </tr>
            <tr>
              <td>{sec_spine_min_max1 !== undefined && sec_spine_min_max1 !== null ? sec_spine_min_max1 : '-'}</td>
              <td colSpan="3" style={{backgroundColor: 'gray', color : 'white'}}>SPINE FIX (MAX-MIN)</td>
              <td>{sec_spine_min_max2 !== undefined && sec_spine_min_max2 !== null ? sec_spine_min_max2 : '-'}</td>
            </tr>
            <tr>
              <td>{sec_head_backdown_y_frame1 !== undefined && sec_head_backdown_y_frame1 !== null ? sec_head_backdown_y_frame1 : '-'}</td>
              <td colSpan="3" rowSpan="2" style={{backgroundColor: 'gray', color : 'white'}}>HEAD FIX</td>
              <td>{sec_head_backdown_y_frame2 !== undefined && sec_head_backdown_y_frame2 !== null ? sec_head_backdown_y_frame2 : '-'}</td>
            </tr>
            <tr>
              <td>{sec_head_backdown_x_frame1 !== undefined && sec_head_backdown_x_frame1 !== null ? sec_head_backdown_x_frame1 : '-'}</td>
              <td>{sec_head_backdown_x_frame2 !== undefined && sec_head_backdown_x_frame2 !== null ? sec_head_backdown_x_frame2 : '-'}</td>
            </tr>
          </thread>

          </table>
        </div>
      )}
    </>
  );
};

export default React.memo(Graph2);
