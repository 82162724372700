import React, { useState, useEffect } from 'react';
import { useToken } from '../Components/TokenContext';
import '../Styles/mystudent.css'
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player/lazy';
import Modal from 'react-modal';
import AWS from 'aws-sdk';
import { isMobile, isTablet } from 'react-device-detect';
//import { showAlert } from '../Components/alert';

const MyStudent = () => {
    const navigate = useNavigate();
    const [students, setStudents] = useState([]) // 전체 회원 리스트
    const [mystudent, setMystudent] = useState([]); // 내 회원(승인된 회원만)
    const [waitingstudent, setWaitingstudent] = useState([]);
    const [selectedStudentId, setSelectedStudentId] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    Modal.setAppElement('#root')
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredStudents, setFilteredStudents] = useState([]);
 
    const coachId = sessionStorage.getItem('coachId');
    const [isStudentsListVisible, setIsStudentsListVisible] = useState(false);
    const toggleStudentsList = () => {
      setIsStudentsListVisible(!isStudentsListVisible);
  };
    // 회원 데이터(영상) 받아오는 변수들
    const [thumbnailPath, setThumbnailPath] = useState([]); // 회원영상 썸네일 전체의 경로 저장
    const [generaldata, setGeneraldata] = useState({}); // 여기서 회원영상 모든 데이터 추출 가능
    const [metadata, setMetadata] = useState({}); // 메타데이터 id별로 파싱한 데이터 저장
    const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
    const [thumbnails, setThumbnailUrls] = useState([]); // 마이페이지에 일반영상 썸네일 띄우기 위한 src path
    const [index, setIndex] = useState(null); // 회원영상 인덱스
    const [showPopup, setShowPopup] = useState(false); // 팝업
    const [selectedId, setSelectedId] = useState(null); // 썸네일 클릭 시 해당 썸네일의 studentSwings[i].id
    const [videosize, setVideoSize] = useState({width: '0px', height:'0px'});


    const [cursor, setCursor] = useState([0]);
    const [total, setTotal] = useState(-1);
    const [jsonarr, setJsonarr] = useState([]); // 일반영상 id 파싱을 위한 take만큼의 json 배열
    const [videoId, setVideoId] = useState(null); // 영상의 고유 id : dataJSON 가져올 때 url에 사용

    const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 번호 (1부터 시작)

    var itemsPerPage = 6;
    if(window.matchMedia("(min-width: 1180px) and (max-width: 1400px) and (orientation: landscape)").matches){
      itemsPerPage = 4;
    }

    const take = isTablet ? 4 : 6;
    const token2 = 'Bearer ' + sessionStorage.getItem('token')
    const apiId = sessionStorage.getItem('apiId');
    const apitype = sessionStorage.getItem('apitype');
    let i;

    const transformSwingsData = (generaldata) => {
      const transformedData = {};
      generaldata.data.studentSwings.forEach((swing) => {
        
        transformedData[swing.id] = {
          clubType: swing.clubType,
          createdAt: swing.createdAt,
          isOpen: swing.isOpen,
          note: swing.note,
        };
      });
      return transformedData;
    };

    const showAlert = (message) => {
      const overlay = document.createElement('div');
      overlay.className = 'custom-alert-overlay';
  
      const alertBox = document.createElement('div');
      alertBox.className = 'custom-alert-box';
  
      const messageParagraph = document.createElement('p');
      messageParagraph.textContent = message;
  
      const closeButton = document.createElement('button');
      closeButton.textContent = '닫기';
      closeButton.onclick = () => {overlay.remove(); reload();};
  
      alertBox.appendChild(messageParagraph);
      alertBox.appendChild(closeButton);
      overlay.appendChild(alertBox);
  
      document.body.appendChild(overlay);
  };

  useEffect(() => { // 학생영상 데이터 받기

    const fetchData = async() => {
      try {
      const response = await fetch( `http://117.16.243.158:9000/myswing/instructor/${coachId}/student?studentId=${selectedStudentId}&take=${take}&cursor=${cursor[currentPage-1]}`,{
        method: 'GET',
        headers: {
          'Authorization': token2,
          'Content-Type': 'application/json',
        },
      });
        if(response.status === 401)
        {
          showAlert('로그인 후 이용해 주세요');
          navigate('/login');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('nickname')
        }
      const json = await response.json();
      setJsonarr(json);
      console.log(json)
      var thumbnailpath = [];
  
      for(i = 0; i< json.data.studentSwings.length; i++)
      {
        var thumbnail = {path : json.data.studentSwings[i].thumbnail, isOpen: json.data.studentSwings[i].isOpen, videoname: json.data.studentSwings[i].videoPath}
        thumbnailpath.push(thumbnail)
  
      }
      setThumbnailPath(thumbnailpath)
      setGeneraldata(json)
      const transformedData = transformSwingsData(json);
      setMetadata(transformedData);
  
      cursor[currentPage] = json.data.cursor
      setCursor([...cursor]);
      if(total === -1){
        setTotal(json.data.total);
      }

      }
      catch(error){
        console.log('Error', error);
      }
      
    };
    fetchData();
  
  },[selectedStudentId, currentPage]);

  sessionStorage.setItem('studentcursor', JSON.stringify(cursor));
 
  useEffect(() => { // 일반영상 썸네일받기

    AWS.config.update({
      accessKeyId: 'AKIAVVKFCTNJJFZR6ABQ',
      secretAccessKey: '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',
      region: 'ap-northeast-2'
    });
  
    const s3 = new AWS.S3();
    const bucketName = 'dmz-metadata'; 
    const objectKey = selectedVideoUrl;
  
    const params = {
      Bucket: bucketName,
      Key: objectKey
    };
  
  s3.getObject(params, (error, data) => {
      if (error) {
        console.error("Error downloading video from S3:", error);
      } else {
        // Assuming the data is a Blob
        const blob = new Blob([data.Body], { type: 'video/mp4' });
        const videoUrl = URL.createObjectURL(blob);
        setSelectedVideoUrl(videoUrl);
      }
    });
  
    const fetchThumbnails = async () => {
      try {
        const thumbnailPromises = thumbnailPath.map((item) => {
          const params = {
            Bucket: bucketName,
            Key: item.path,
          };
          return s3.getObject(params).promise().then(data => {
            return URL.createObjectURL(new Blob([data.Body], { type: 'image/jpeg' }));
          });
        });
  
        const thumbnails = await Promise.all(thumbnailPromises);
        setThumbnailUrls(thumbnails);
      } catch (error) {
        console.error('Error fetching thumbnails:', error);
      }
    };
  
    if (thumbnailPath.length > 0) {
      fetchThumbnails();
    }
  }, [thumbnailPath, selectedVideoUrl]);

  useEffect(() => {
    // 검색어에 따라 회원을 필터링합니다.
    const results = students.filter(student =>
        student.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredStudents(results);
}, [searchTerm, students]);

    const thumbnailname = (path) => {
      const filename = path.substring(path.lastIndexOf('/') + 1);
      const dateTimePart = filename.split('.')[0];
      return dateTimePart;
    };
  
    const handleThumbnailClick = (index) => {
      setIndex(index);
      const videoUrl = generaldata.data.studentSwings[index].videoPath;
      setSelectedVideoUrl(videoUrl);
      setSelectedId(generaldata.data.studentSwings[index].id);
      setShowPopup(true);
      setVideoId(jsonarr.data.studentSwings[index].id)
    };
    sessionStorage.setItem('studentvideoId', videoId);

    const handleClose = () => {
    setShowPopup(false);
  };

  const reload = () => {
    window.location.reload();
  }
 
    const handlePersonalAnalysis = (index) => { // 개인분석에 videopath전달
      const selectedVideo = generaldata.data.studentSwings[index];
      navigate('/coachsolo', { state: { studentindex: index, videopath: selectedVideo.videoPath, id: selectedStudentId} });
    };
  
    // 비교 분석 페이지로 이동하는 함수
    const handleComparativeAnalysis = (index) => { // 비교분석에 videopath전달
      const selectedVideo = generaldata.data.studentSwings[index];
      navigate("/coachcompare", { state: { studentindex: index, videopath: selectedVideo.videoPath, id: selectedStudentId } });
    };

    useEffect(() => {
      const handleResize = () => {
        if (window.matchMedia("(min-width: 900px) and (max-width: 1280px) and (orientation: landscape)").matches) {
          setVideoSize({ width: '100px', height: '200px' });
        } else {
          setVideoSize({ width: '200px', height: '300px' });
        }
      };
    
      handleResize();
    
      window.addEventListener('resize', handleResize);

      window.addEventListener('orientationchange', handleResize);
    
      return () => {
        window.removeEventListener('resize', handleResize);
        window.removeEventListener('orientationchange', handleResize);
      };
    }, []);

    const renderPopup = () => {

        if (!showPopup) return null;
        return (
          <div className="popup">
            <h3>Swing Info</h3>
            {selectedVideoUrl && (
              <div style={{ float: 'right', marginRight: '10px',marginTop:'-80px' }}>
                <ReactPlayer url={selectedVideoUrl} loop={true} playing controls width={videosize.width} height={videosize.height} />
              </div>
            )}
            {metadata && (
              <div>
                <p><strong>클럽 종류: </strong> {metadata[selectedId].clubType}</p>
                <p><strong>업로드 시간: </strong> {metadata[selectedId].createdAt}</p>
                <p><strong>hand: </strong> {'우타'}</p>
                <p><strong>코멘트: </strong> {metadata[selectedId].note}</p>

              </div>
            )}
            {/* <button onClick={() => handlePersonalAnalysis(index)}>개인 분석</button>
            <button onClick={() => handleComparativeAnalysis(index)}>비교 분석</button> */}
            <button onClick={handleClose}>닫기</button>
          </div>
        );
      };

  useEffect(() => {
      const fetchStudents = async() => { // 등록된 학생정보 가져오기 Approve로 파싱
        try {
        const response = await fetch(`http://117.16.243.158:9000/lesson/instructor/${coachId}`, {
          method: 'GET',
          headers: {
            'Authorization': token2,
            'Content-Type': 'application/json',
          },
        });
        if(response.status === 401)
        {
          //showAlert('로그인 후 이용해 주세요');
          //navigate('/login');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('nickname')
        }
        
        const data = await response.json();
          if (data.data && data.data.lessons) {

            const studentData = data.data.lessons.map((user) => ({
                lessonId: user.id,
                id: user.student.id,
                name: user.student.user.name,
                loginId: user.student.user.loginId,
                isApprove: user.isApprove
            }));
            setMystudent(studentData);
      }
        }catch(error){
          console.log('Error', error);
        }
      };
      fetchStudents();
    
    }, [coachId]);

    useEffect(() => {
      const fetchwaitingStudents = async() => { // 대기중인 회원 받아오기
        try {
        const response = await fetch(`http://117.16.243.158:9000/lesson/instructor/${coachId}/pending`, {
          method: 'GET',
          headers: {
            'Authorization': token2,
            'Content-Type': 'application/json',
          },
        });
        if(response.status === 401)
        {
          //showAlert('로그인 후 이용해 주세요');
          //navigate('/login');
          sessionStorage.removeItem('token');
          sessionStorage.removeItem('nickname')
        }
        
        const data = await response.json();
          if (data.data && data.data.lessons) {

            const studentData = data.data.lessons.map((user) => ({
                lessonId: user.id,
                id: user.student.id,
                name: user.student.user.name,
                loginId: user.student.user.loginId,
                isApprove: user.isApprove,
                requestedBy: user.requestedBy
            }));
            setWaitingstudent(studentData);
      }
        }catch(error){
          console.log('Error', error);
        }
      };
      fetchwaitingStudents();
    
    }, [coachId]);

  const handleStudentClick = (studentId) => {
    setSelectedStudentId(studentId);
  };
  sessionStorage.setItem('studentId', selectedStudentId)

useEffect(() => {
    const fetchCoaches = async() => { // 전체 회원 리스트 받아오기
        try {
        const response = await fetch(`http://117.16.243.158:9000/student/info?take=${10000}`, { // 회원 전체 수 받아와서 바꾸기
        method: 'GET',
        headers: {
            'Authorization': token2,
            'Content-Type': 'application/json',
        },
        });
        if(response.status === 401)
        {
        //showAlert('로그인 후 이용해 주세요');
        //navigate('/login');
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('nickname')
        }
        
        const data = await response.json();
        if (data.data && data.data.students) {

        const studentsData = data.data.students.map((student) => ({
            id: student.id,
            name: student.user.name
        }));
        setStudents(studentsData);
    }
        }catch(error){
        console.log('Error', error);
        }
    };
    fetchCoaches();
    }, []);

  const approveStudent = async (lessonId) => { // 학생 승인하기
    try {
        const response = await fetch(`http://117.16.243.158:9000/lesson/${lessonId}/instructor/${coachId}/approve`, {
            method: 'PUT',
            headers: {
                'Authorization': token2,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                isApprove: true
            }),
        });

        if (response.ok) {
            showAlert('승인되었습니다.');
        } else {
            showAlert('승인에 실패하였습니다.');
        }
    } catch (error) {
        console.error('Error during the API call:', error);
        showAlert('Error approving student');
    }
};
const rejectStudent = async (lessonId) => { // 학생 거절하기
  try {
      const response = await fetch(`http://117.16.243.158:9000/lesson/${lessonId}/instructor/${coachId}/approve`, {
          method: 'PUT',
          headers: {
              'Authorization': token2,
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              isApprove: false
          }),
      });

      if (response.ok) {
          showAlert('승인이 거절되었습니다.');
      } else {
          showAlert('거절에 실패하였습니다.');
      }
  } catch (error) {
      console.error('Error during the API call:', error);
      showAlert('Error approving student');
  }
};

const deleteStudent = async (lessonId) => { // 학생 삭제하기
  try {
      const response = await fetch(`http://117.16.243.158:9000/lesson/${lessonId}/instructor/${coachId}`, {
          method: 'DELETE',
          headers: {
              'Authorization': token2,
              'Content-Type': 'application/json',
          },
      });

      if (response.ok) {
          showAlert('삭제가 완료되었습니다.');
      } else {
          showAlert('삭제에 실패하였습니다.');
      }
  } catch (error) {
      console.error('Error during the API call:', error);
      showAlert('Error approving student');
  }
};

const handleApply = async (id) => { // 학생에게 승인요청 보내기
  try {
      const response = await fetch(`http://117.16.243.158:9000/lesson/instructor/${coachId}`, {
          method: 'POST',
          headers: {
              'Authorization': token2,
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ 
              studentId: id
          }),
      });

      if (response.ok) {
          showAlert('신청이 완료되었습니다.');

      } else {
          showAlert('신청에 실패하였습니다.');
      }
  } catch (error) {
      console.error('Error during coach registration:', error);
  }
};

  const handlePrev = () => {
    if(currentPage > 1){ setCurrentPage(currentPage - 1); // 0 미만으로 가지 않도록
  }
  };

  const handleNext = () => {
    if(currentPage < total/take){
      setCurrentPage(currentPage + 1); // 최대 페이지를 초과하지 않도록
    }
  };
  sessionStorage.setItem('studentpage', currentPage);

    return (
    <>
      <div>
        <div className='title'>
          <p>내 회원</p>
        </div>
          <ul className="member-list">
          {mystudent.length > 0 ? (
              mystudent.filter(student => student.isApprove === true).map((filteredStudent) => (
                <li key={filteredStudent.id} className="member-item" onClick={() => handleStudentClick(filteredStudent.id)}>
                    <p>{filteredStudent.name}</p>
                    <button onClick={() => deleteStudent(filteredStudent.lessonId)}>삭제</button>
                </li>
            ))
              ) : (
                  <li className="member-item no-members">등록된 회원이 없습니다.</li>
                  
              )}
          </ul>
        </div>
      <>
        <div className="Mylabel2">
            {
              mystudent.length > 0 && selectedStudentId &&
              <label>
                {mystudent.find(s => s.id === selectedStudentId) ? mystudent.find(s => s.id === selectedStudentId).name : ''} 회원님의 영상은 {total}개 입니다.
              </label>
            }
              <div className="Mythumbnail-container">
                {thumbnailPath && thumbnailPath.map((item, index) => (
                  <div className="Mythumbnail-item" key={index}>
                    <img onClick={() => handleThumbnailClick(index)} src={thumbnails[index]} alt={''} style={{ width: '200px', height: '400px' }}/>
                    <div className="Mythumbnail-title">
                      {thumbnailname(thumbnailPath[index].videoname)}
                    </div>
                  </div>
                ))}
              </div>
              <div className="pagination-controls">
              <br></br>
                <span onClick={handlePrev} style={{ cursor: 'pointer', color: currentPage === 1}}>&lt; 이전</span>
                <span> {currentPage} / {Math.ceil(total / itemsPerPage)}</span>
                <span onClick={handleNext} style={{ cursor: 'pointer', color: currentPage >= Math.ceil(total / itemsPerPage) - 1 }}>다음 &gt;</span>
              </div>
            {renderPopup()}
        </div>
      </>
        <div className='title'>
            <p>신청 중인 회원</p>
          </div>
          <ul className="member-list">
            {waitingstudent.some(s => (s.isApprove === null && s.requestedBy === false)) ? (
                waitingstudent.filter(s => (s.isApprove === null && s.requestedBy === false)).map((filteredStudent) => (
                  <li key={filteredStudent.id} className="member-item" onClick={() => handleStudentClick(filteredStudent.id)}>
                    <p>{filteredStudent.name}</p>
                  </li>
                ))
              ) : (
                <li className="member-item no-members">신청 중인 회원이 없습니다.</li>
              )
            }
          </ul>

        <div className='title'>
          <p>대기 중인 회원</p>
        </div>
        <ul className="member-list">
          { waitingstudent.some(s => (s.isApprove === null && s.requestedBy === true)) ? (
              waitingstudent.filter(s => (s.isApprove === null && s.requestedBy === true)).map((filteredStudent) => (
                <li key={filteredStudent.id} className="member-item" onClick={() => handleStudentClick(filteredStudent.id)}>
                  <p>{filteredStudent.name}</p>
                  <button onClick={() => approveStudent(filteredStudent.lessonId)}>승인</button>
                  <button onClick={() => rejectStudent(filteredStudent.lessonId)}>거절</button>
                </li>
              ))
            ) : (
              <li className="member-item no-members">승인대기 중인 회원이 없습니다.</li>
            )
          }
        </ul>
        <>
          <div className='title'>
          <button onClick={() => setIsModalOpen(true)}>회원 검색</button><br></br>
            {/* <button 
                  onClick={toggleStudentsList} >
                  {isStudentsListVisible ? '닫기' : '회원 리스트 보기'}
                </button> <br></br> */}
            </div>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={() => setIsModalOpen(false)}
                contentLabel="회원 리스트"
                style={{
                  overlay: {
                    zIndex: 10, // Set a high value for zIndex here
                  },
                    content: {
                        top: '45%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        transform: 'translate(-50%, -50%)',
                        width: '80%',
                        height: '80%',
                    }
                }}
            >
                <h2>회원 리스트</h2>
                <button onClick={() => setIsModalOpen(false)}>닫기</button>
                <input
                  type="text"
                  placeholder="회원 이름 검색"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="search-input"
                />
                <ul className="coach-list2">
                    {searchTerm && filteredStudents.map((student) => (
                        <li key={student.id} className="coach-item">
                            <h3>{student.name}</h3>
                            <button onClick={() => handleApply(student.id)}>신청</button>
                        </li>
                    ))}
                </ul>
            </Modal>
            {/* {isStudentsListVisible && (
                <ul className="coach-list">
                    {students.map((student) => (
                        <li key={student.id} className="coach-item">
                            <h3>{student.name}</h3>
                            <button onClick = {() => handleApply(student.id)}>신청</button>
                        </li>
                    ))}
                </ul>
            )} */}
          </>
    </>
  );
};
export default MyStudent;