import React, { useState, useEffect } from 'react';
import axios from 'axios';

const QnA = () => {
  const [questions, setQuestions] = useState([]);
  const [newQuestion, setNewQuestion] = useState({ title: '', content: '' });
  const [editingId, setEditingId] = useState(null);

  useEffect(() => {
    fetchQuestions();
  }, []);

  const fetchQuestions = async () => {
    try {
      const response = await axios.get('/api/questions');
      setQuestions(response.data);
    } catch (error) {
      console.error('Error fetching questions:', error);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewQuestion({ ...newQuestion, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (editingId) {
        await axios.put(`/api/questions/${editingId}`, newQuestion);
      } else {
        await axios.post('/api/questions', newQuestion);
      }
      setNewQuestion({ title: '', content: '' });
      setEditingId(null);
      fetchQuestions();
    } catch (error) {
      console.error('Error submitting question:', error);
    }
  };

  const handleEdit = (question) => {
    setNewQuestion({ title: question.title, content: question.content });
    setEditingId(question.id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/questions/${id}`);
      fetchQuestions();
    } catch (error) {
      console.error('Error deleting question:', error);
    }
  };

  return (
    <div>
      <h1>QnA 관리</h1>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="title"
          value={newQuestion.title}
          onChange={handleInputChange}
          placeholder="질문 제목"
          required
        />
        <textarea
          name="content"
          value={newQuestion.content}
          onChange={handleInputChange}
          placeholder="질문 내용"
          required
        />
        <button type="submit">{editingId ? '수정' : '추가'}</button>
      </form>
      <ul>
        {questions.map((question) => (
          <li key={question.id}>
            <h3>{question.title}</h3>
            <p>{question.content}</p>
            <button onClick={() => handleEdit(question)}>수정</button>
            <button onClick={() => handleDelete(question.id)}>삭제</button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default QnA;