import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useToken } from '../Components/TokenContext';
import "../Styles/signuppage.css";
import { showAlert } from '../Components/alert';

function SignupPage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: "",
    password: "",
    confirmPassword: "",
    name: "",
    nickname: "",
    gender: null,
    hand: "우타",
    type: "일반",
    promotioncode: "",
  });

  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(true);

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    return regex.test(password);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    if (name === "gender") {
        setFormData(prevData => ({
            ...prevData,
            [name]: value === 'true',
        }));
    } else {
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    }

    if (name === "password" || name === "confirmPassword") {
      if (name === "confirmPassword" && value !== formData.password) {
        setPasswordMismatch(true);
        setIsPasswordValid(validatePassword(value));
      } else {
        setPasswordMismatch(false);
      }
    }
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSignup = async () => {

    const code = "tempodiall";

    if (passwordMismatch ||!formData.id ||!formData.password ||!formData.confirmPassword ||!formData.type ||!formData.name || !formData.nickname || !formData.gender || !formData.hand
    ) {
      showAlert("기입하지 않은 내용이 있습니다.");
      return;
    }

    if (formData.promotioncode !== code) {
      showAlert("유효하지 않은 프로모션 코드입니다.");
      return;
    }

    try {
      if (formData.type === "프로") {
        let gender;
        if(formData.gender === "male"){
          gender = true;
        } else if(formData.gender === "female"){
          gender = false;
        }
        const response = await fetch("http://117.16.243.158:9000/signup/instructor", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
                loginId: formData.id,
                password: formData.password,
                name: formData.name,
                nickname: formData.nickname,
                gender: gender,
                type: true,
                hand: formData.hand
            }),
          }
        );
        const responseData = await response.json();

        if (responseData.status==="success") {
          // 회원가입 성공 처리
          navigate("/login");
          showAlert("회원가입 되었습니다.");
        } else if(responseData.status==="Error"){
          // 회원가입 실패 처리
          showAlert("중복된 아이디 입니다.");
        }
      } 
      else if (formData.type === "일반") {
        let gender;
        if(formData.gender === "male"){
          gender = true;
        }
        else if(formData.gender === "female"){
          gender = false;
        }
        const response = await fetch("http://117.16.243.158:9000/signup/student", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            loginId: formData.id,
            password: formData.password,
            name: formData.name,
            nickname: formData.nickname,
            gender: gender,
            type: false,
            hand: formData.hand
        }),
        });
        const responseData = await response.json();

        if (responseData.status==="success") {
            // 회원가입 성공 처리
            navigate("/login");
            showAlert("회원가입 되었습니다.");
            
          } else if(responseData.status==="Error"){
            // 회원가입 실패 처리
            showAlert("중복된 아이디 입니다.");
          }
      }
    } catch (error) {
      console.error("Error occurred during signup: ", error);
    }
  };

  return (
    <div className="container">
      <h3>회원가입</h3>
      <form>
        <input
          type="text"
          name="id"
          placeholder="ID"
          value={formData.id}
          onChange={handleInputChange}
        />
        <input
          type="password"
          name="password"
          placeholder="비밀번호"
          value={formData.password}
          onChange={handleInputChange}
        />
        <p className={isPasswordValid ? "success-text" : "error-text"} style={{color:'white'}}>
          문자, 숫자, 특수문자의 조합으로 8자 이상으로 입력하세요.
        </p>
        <input
          type="password"
          name="confirmPassword"
          placeholder="비밀번호 확인"
          value={formData.confirmPassword}
          onChange={handleInputChange}
        />

        {formData.password === formData.confirmPassword &&
          formData.password !== "" && (
            <p className="success-text">비밀번호가 일치합니다.</p>
          )}

        {formData.password !== formData.confirmPassword && (
          <p className="error-text">비밀번호가 다릅니다.</p>
        )}

        <input
          type="text"
          name="name"
          placeholder="이름"
          value={formData.name}
          onChange={handleInputChange}
        />
        <input
          type="text"
          name="nickname"
          placeholder="닉네임"
          value={formData.nickname}
          onChange={handleInputChange}
        />
        <select name="type" value={formData.type} onChange={handleInputChange}>
          <option value="일반">일반</option>
          <option value="프로">프로</option>
        </select>
        <select name="gender" value={formData.gender} onChange={handleInputChange}>
          <option value="">성별</option>
          <option value="male">남성</option>
          <option value="female">여성</option>
        </select>
        <select name="hand" value={formData.hand} onChange={handleInputChange}>
          <option value="우타">우타</option>
          <option value="좌타">좌타</option>
        </select>

        <input
          type="text"
          name="promotioncode"
          placeholder="프로모션 코드"
          value={formData.promotioncode}
          onChange={handleInputChange}
        />

        <button type="button" onClick={handleSignup}>
          가입하기
        </button>
      </form>
      <p className="signup-text"> Already have an account? <Link to="/login">LOGIN</Link></p>
    </div>
  );
}

export default SignupPage;
